import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  Navigate,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie';
import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "./constants.js";

import Home from './component/app.home';
import Login from './component/app.login';
import Asset from './component/app.asset';
import AssetDetail from './component/app.asset_detail';
import Project from './component/app.project';
import ProjectPeople from './component/app.project_people';
import ProjectDetail from './component/app.project_detail';
import ProjectDetailSub from './component/app.project_detail_sub';
import Tickets from './component/app.tickets';
import TicketDetail from './component/app.ticket_detail';
import Report from './component/app.report';
import ReportDetail from './component/app.report_detail';
import postEvent from './component/module.ping.js';
import Loader from "./static/svg/loader.svg";
import listIcon from "./static/svg/list.svg";
import gridIcon from "./static/svg/grid.svg";
// import LeftArrow from "./static/svg/leftarrow.svg";
import './static/css/app.css';

if (IS_LIVE) {
  var base_url = LIVE_HOSTS.base_url
} else {
  var base_url = LOCAL_HOSTS.base_url
}

const routes = [
  { path: '/', name: 'Home', Component: Home },
  { path: '/projects', name: 'Projects', Component: Project },
  { path: '/support', name: 'Support', Component: Tickets },
  { path: '/assets', name: 'Assets', Component: Asset },
  { path: '/reports', name: 'Reports', Component: Report}
]

function App() {
  // let [searchParams, setSearchParams] = useSearchParams();
  // let layout = searchParams.get("layout");
  const [searchParams, setSearchParams] = useSearchParams();
  const [format, setFormat] = useState('grid');
  const [isDark, setIsDark] = useState(false);
  const [username, setUsername] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isUser, setIsUser] = useState(true);
  const [teamUser, setTeamUser] = useState(null);
  const [showSettings, setShowSettings] = useState(false);
  const [token, setToken] = useState(null);
  const [passToken, setPassToken] = useState(null);
  const [teamUsers, setTeamUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [labels, setLabels] = useState([]);
  const [categories, setCategories] = useState([]);

  function handleShowSettings() {
    if (showSettings) {
      setShowSettings(false) 
    } else {
      setShowSettings(true) 
    }
  }

  function handleFormat(layout) {
    
    if (format === 'grid') {
      setFormat('list');
      // setSearchParams({ layout: 'list' });
    } else {
      setFormat('grid');
      // setSearchParams({ layout: 'grid' });
    }
    setShowSettings(false);
  }

  function handleDarkMode() {
    let darkModeVal;
    if (isDark) {
      darkModeVal = false;
    } else {
      darkModeVal = true;
    }
    setIsDark(darkModeVal);
    setShowSettings(false);

    let formData = {};
    formData['id'] = userId;
    formData['django_user'] = userId;
    formData['dark_mode'] = darkModeVal;

    try {
      console.log('try prefs', userId, isDark)
      const url = base_url + "/api/user-setting/" + userId + '/';
      axios({
          method: "post",
          url: url,
          data: formData,
          headers: {
            Authorization: 'Token ' + token
          },
      }).then((response) => {
        console.log('response, isDark', response)
    
      });
    } catch (error) {
        console.error(error);
        console.log('error prefs', userId, isDark)
    }

  }

  function handleLogOut() {
    var cookie = Cookies.get('radial_token');
    if (cookie) {
      axios({
        method: 'post',
        baseURL: base_url,
        url: '/token/logout/',
        data: {
          'username': username,
          'token': cookie
        },
        headers: {
            Authorization: 'Token ' + cookie
        }

        })
        .then(response => {
          console.log('response', response, response.data['username'])
          Cookies.remove('radial_token');
          setUsername(null);
          setIsUser(false);
          setToken(null);
          setPassToken(null);
        })
        .catch(function (error) {
          if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          } else if (error.request) {
          console.log(error.request);
          } else {
          console.log('Error', error.message);
          }
          console.log(error.config);
      });
    }
  }

  useEffect(() => {

    postEvent();
    
    var cookie = Cookies.get('radial_token');
    if (cookie) {
      
      axios({
        method: 'get',
        baseURL: base_url,
        url: '/auth/users/me/',
        headers: {
            Authorization: 'Token ' + cookie
        }
        })
        .then(response => {
          console.log('response', response, response.data['username']);
          if (response.data['username']) {
            setUsername(response.data['username']);
            setUserId(response.data['id']);
            setIsUser(true);
            setToken(cookie);
            setPassToken(cookie);
          } else {
            setUsername(null);
            setUserId(null);
            setIsUser(false);
          }
          if (response) {
            axios({
              method: 'get',
              baseURL: base_url,
              url: '/api/team-user/' + response.data['id'],
              headers: {
                Authorization: 'Token ' + cookie
              },
              })
              .then(response => {
                // console.log('team user', response.data)
                setTeamUser(response.data);
              })
              .catch(function (error) {
                if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('Error', error.message);
                }
                console.log(error.config);
                setTeamUser(null);
            });
          }
          
          
        })
        .catch(function (error) {
          if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          } else if (error.request) {
          console.log(error.request);
          } else {
          console.log('Error', error.message);
          }
          console.log(error.config);
          setUsername(null);
          setIsUser(false);
      });



      // let params = (new URL(document.location)).searchParams;
      // let mode = params.get('mode');
      // if (mode === 'dark') {
      //   setIsDark(true);
      // } else {
      //   setIsDark(false);
      // }

    } else {
      setIsUser(false);
    }
   
  }, []);

  useEffect(() => {
    if (userId && token) {
     
      // let formData = {};
      // formData['id'] = userId;
      // formData['django_user'] = userId;
      // formData['dark_mode'] = darkModeVal;

      try {
        // console.log('try prefs', userId, isDark)
        const url = base_url + "/api/user-setting/" + userId + "/";
        axios({
            method: "get",
            url: url,
            headers: {
              Authorization: 'Token ' + token
            },
        }).then((response) => {
          console.log('user-settings', response, response.data['dark_mode'])
          setIsDark(response.data['dark_mode']);
        });
      } catch (error) {
          console.error(error);
          console.log('error prefs', userId, isDark)
      }


    }
  
  }, [userId, token])


  return (


      <div className={"app" + ((isDark)?(" dark"):(" light"))}>
        <header className="app-header">
          <div className="column flexRow">
            <nav>
              {routes.filter(route => (route.name)).map((route, i) => (
                <NavLink
                  key={route.path}
                  className={({ isActive }) =>
                    isActive ? "menuLink on" : "menuLink"
                  }
                  id={'nav' + i}
                  to={route.path}
                  >
                  {route.name}
                </NavLink>
              ))}
            </nav>
            
            <div id="showSettings">
              <span className="item username">{username}</span>           
              <div className="userBtn" onClick={()=>handleShowSettings()}>
                  <svg id="Icon_feather-settings" data-name="Icon feather-settings" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                  <path id="Path_105" data-name="Path 105" d="M22.5,18A4.5,4.5,0,1,1,18,13.5,4.5,4.5,0,0,1,22.5,18Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                  <path id="Path_106" data-name="Path 106" d="M29.1,22.5a2.475,2.475,0,0,0,.495,2.73l.09.09a3,3,0,1,1-4.245,4.245l-.09-.09a2.5,2.5,0,0,0-4.23,1.77V31.5a3,3,0,1,1-6,0v-.135A2.475,2.475,0,0,0,13.5,29.1a2.475,2.475,0,0,0-2.73.495l-.09.09A3,3,0,1,1,6.435,25.44l.09-.09a2.5,2.5,0,0,0-1.77-4.23H4.5a3,3,0,1,1,0-6h.135A2.475,2.475,0,0,0,6.9,13.5a2.475,2.475,0,0,0-.5-2.73l-.09-.09A3,3,0,1,1,10.56,6.435l.09.09a2.475,2.475,0,0,0,2.73.495h.12A2.475,2.475,0,0,0,15,4.755V4.5a3,3,0,0,1,6,0v.135a2.5,2.5,0,0,0,4.23,1.77l.09-.09a3,3,0,1,1,4.245,4.245l-.09.09a2.475,2.475,0,0,0-.5,2.73v.12A2.475,2.475,0,0,0,31.245,15H31.5a3,3,0,0,1,0,6h-.135A2.475,2.475,0,0,0,29.1,22.5Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                </svg>
            
              </div>
          </div>
              {(showSettings) ? (
                <div className="userBtns">
                
                  <div className="userBtn" onClick={() => handleDarkMode()}>{(isDark)?(
                    <svg xmlns="http://www.w3.org/2000/svg" width="29.944" height="29.944" viewBox="0 0 29.944 29.944">
                      <path id="Icon_feather-moon" data-name="Icon feather-moon" d="M31.5,19.185A13.5,13.5,0,1,1,16.815,4.5,10.5,10.5,0,0,0,31.5,19.185Z" transform="translate(-3.056 -3)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                    </svg>
                  ):(
                    <svg id="Icon_feather-sun" data-name="Icon feather-sun" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                      <path id="Path_115" data-name="Path 115" d="M25.5,18A7.5,7.5,0,1,1,18,10.5,7.5,7.5,0,0,1,25.5,18Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_116" data-name="Path 116" d="M18,1.5v3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_117" data-name="Path 117" d="M18,31.5v3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_118" data-name="Path 118" d="M6.33,6.33,8.46,8.46" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_119" data-name="Path 119" d="M27.54,27.54l2.13,2.13" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_120" data-name="Path 120" d="M1.5,18h3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_121" data-name="Path 121" d="M31.5,18h3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_122" data-name="Path 122" d="M6.33,29.67l2.13-2.13" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_123" data-name="Path 123" d="M27.54,8.46l2.13-2.13" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                    </svg>

                  )}</div>
                  <div className="userBtn" onClick={() => handleFormat()}>
                    {(format === 'list')?(
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <g id="Icon_feather-grid" data-name="Icon feather-grid" transform="translate(-3 -3)">
                          <path id="Path_105" data-name="Path 105" d="M4.5,4.5H15V15H4.5Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_106" data-name="Path 106" d="M21,4.5H31.5V15H21Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_107" data-name="Path 107" d="M21,21H31.5V31.5H21Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_108" data-name="Path 108" d="M4.5,21H15V31.5H4.5Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                        </g>
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21">
                        <g id="Icon_feather-list" data-name="Icon feather-list" transform="translate(-3 -7.5)">
                          <path id="Path_109" data-name="Path 109" d="M12,9H31.5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_110" data-name="Path 110" d="M12,18H31.5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_111" data-name="Path 111" d="M12,27H31.5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_112" data-name="Path 112" d="M4.5,9h0" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_113" data-name="Path 113" d="M4.5,18h0" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_114" data-name="Path 114" d="M4.5,27h0" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                        </g>
                      </svg>
                    )}
                  </div>
                  {(username)?(<div className="userBtn" onClick={() => handleLogOut()}>Log Out</div>):(<NavLink to={'/login'} className="userBtn">Log In</NavLink>)}
                  </div>
                ) : null}
           
          </div>
        </header>

          <section id="content" className={format}>
            <Routes>
              <Route exact index path="/" element={(isUser)?(<Home token={token} format={format} teamUsers={teamUsers} setTeamUsers={setTeamUsers} statuses={statuses} setStatuses={setStatuses} />):(<Navigate to="/login" />)} />
              <Route path="/login" element={<Login  />} />
              <Route path="projects">
                <Route index={true} element={(isUser)?(<Project token={token} format={format} teamUsers={teamUsers} setTeamUsers={setTeamUsers} statuses={statuses} setStatuses={setStatuses} />):(<Navigate to="/login" />)} />
                <Route path=":id" element={(isUser)?(<ProjectDetail token={token} teamUsers={teamUsers} setTeamUsers={setTeamUsers} statuses={statuses} setStatuses={setStatuses} labels={labels} setLabels={setLabels} setCategories={setCategories} categories={categories} dark={isDark} userId={userId} />):(<Navigate to="/login" />)} />
                <Route path="people" element={(isUser)?(<ProjectPeople token={token} teamUsers={teamUsers} setTeamUsers={setTeamUsers} statuses={statuses} setStatuses={setStatuses} labels={labels} setLabels={setLabels} setCategories={setCategories} categories={categories} dark={isDark} />):(<Navigate to="/login" />)} />
          
              </Route>
              <Route path="support">
                <Route index={true} element={(isUser)?(<Tickets userId={userId} token={token} format={format} dark={isDark}  />):(<Navigate to="/login" />)} />
                <Route path=":id" element={(isUser)?(<TicketDetail userId={userId} token={token} format={format} dark={isDark} />):(<Navigate to="/login" />)} />
                <Route path="people" element={(isUser)?(<ProjectPeople token={token} teamUsers={teamUsers} setTeamUsers={setTeamUsers} statuses={statuses} setStatuses={setStatuses} labels={labels} setLabels={setLabels} setCategories={setCategories} categories={categories} dark={isDark} />):(<Navigate to="/login" />)} />
          
              </Route>
              
              <Route path="/assets/:id" element={(isUser)?(<AssetDetail token={token} />):(<Navigate to="/login" />)} />
              <Route path="/assets" element={(isUser)?(<Asset token={token} format={format} />):(<Navigate to="/login" />)} />
              <Route path="/reports/:id" element={(isUser)?(<ReportDetail token={token} />):(<Navigate to="/login" />)} />
              <Route path="/reports" element={(isUser)?(<Report token={token} format={format} />):(<Navigate to="/login" />)} />
            </Routes>
          </section>
          
          <nav className="m">

              {routes.filter(route => (route.name)).map((route, i) => (
                <NavLink
                  key={route.path}
                  className={({ isActive }) =>
                    isActive ? "menuLink on" : "menuLink"
                  }
                  id={'nav' + i}
                  to={route.path}
                  >
                  {route.name}
                </NavLink>
              ))}

            
            <div className="showSettings">
             
              <div className="userBtn" onClick={()=>handleShowSettings()}>
                  <svg id="Icon_feather-settings" data-name="Icon feather-settings" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                  <path id="Path_105" data-name="Path 105" d="M22.5,18A4.5,4.5,0,1,1,18,13.5,4.5,4.5,0,0,1,22.5,18Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                  <path id="Path_106" data-name="Path 106" d="M29.1,22.5a2.475,2.475,0,0,0,.495,2.73l.09.09a3,3,0,1,1-4.245,4.245l-.09-.09a2.5,2.5,0,0,0-4.23,1.77V31.5a3,3,0,1,1-6,0v-.135A2.475,2.475,0,0,0,13.5,29.1a2.475,2.475,0,0,0-2.73.495l-.09.09A3,3,0,1,1,6.435,25.44l.09-.09a2.5,2.5,0,0,0-1.77-4.23H4.5a3,3,0,1,1,0-6h.135A2.475,2.475,0,0,0,6.9,13.5a2.475,2.475,0,0,0-.5-2.73l-.09-.09A3,3,0,1,1,10.56,6.435l.09.09a2.475,2.475,0,0,0,2.73.495h.12A2.475,2.475,0,0,0,15,4.755V4.5a3,3,0,0,1,6,0v.135a2.5,2.5,0,0,0,4.23,1.77l.09-.09a3,3,0,1,1,4.245,4.245l-.09.09a2.475,2.475,0,0,0-.5,2.73v.12A2.475,2.475,0,0,0,31.245,15H31.5a3,3,0,0,1,0,6h-.135A2.475,2.475,0,0,0,29.1,22.5Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                </svg>
            
              </div>
              {(showSettings) ? (
                <div className="userBtns">
                
                  <div className="userBtn" onClick={() => handleDarkMode()}>{(isDark)?(
                    <svg xmlns="http://www.w3.org/2000/svg" width="29.944" height="29.944" viewBox="0 0 29.944 29.944">
                      <path id="Icon_feather-moon" data-name="Icon feather-moon" d="M31.5,19.185A13.5,13.5,0,1,1,16.815,4.5,10.5,10.5,0,0,0,31.5,19.185Z" transform="translate(-3.056 -3)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                    </svg>
                  ):(
                    <svg id="Icon_feather-sun" data-name="Icon feather-sun" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                      <path id="Path_115" data-name="Path 115" d="M25.5,18A7.5,7.5,0,1,1,18,10.5,7.5,7.5,0,0,1,25.5,18Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_116" data-name="Path 116" d="M18,1.5v3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_117" data-name="Path 117" d="M18,31.5v3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_118" data-name="Path 118" d="M6.33,6.33,8.46,8.46" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_119" data-name="Path 119" d="M27.54,27.54l2.13,2.13" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_120" data-name="Path 120" d="M1.5,18h3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_121" data-name="Path 121" d="M31.5,18h3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_122" data-name="Path 122" d="M6.33,29.67l2.13-2.13" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                      <path id="Path_123" data-name="Path 123" d="M27.54,8.46l2.13-2.13" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                    </svg>

                  )}</div>
                  <div className="userBtn" onClick={() => handleFormat()}>
                    {(format === 'list')?(
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <g id="Icon_feather-grid" data-name="Icon feather-grid" transform="translate(-3 -3)">
                          <path id="Path_105" data-name="Path 105" d="M4.5,4.5H15V15H4.5Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_106" data-name="Path 106" d="M21,4.5H31.5V15H21Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_107" data-name="Path 107" d="M21,21H31.5V31.5H21Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_108" data-name="Path 108" d="M4.5,21H15V31.5H4.5Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                        </g>
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21">
                        <g id="Icon_feather-list" data-name="Icon feather-list" transform="translate(-3 -7.5)">
                          <path id="Path_109" data-name="Path 109" d="M12,9H31.5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_110" data-name="Path 110" d="M12,18H31.5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_111" data-name="Path 111" d="M12,27H31.5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_112" data-name="Path 112" d="M4.5,9h0" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_113" data-name="Path 113" d="M4.5,18h0" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                          <path id="Path_114" data-name="Path 114" d="M4.5,27h0" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                        </g>
                      </svg>
                    )}
                  </div>
                  {(username)?(<div className="userBtn" onClick={() => handleLogOut()}>Log Out</div>):(<NavLink to={'/login'} className="userBtn">Log In</NavLink>)}
                  </div>
                ) : null}
           
          </div>
        </nav>
          
      </div>
    

    );

}

export default App;

// <div id="tools">
// <h3>Tools</h3>
// </div>

// <Route path="/projects/:id/:subId" element={(isUser)?(<ProjectDetailSub token={token} teamUsers={teamUsers} setTeamUsers={setTeamUsers} />):(<Navigate to="/login" />)} />