import React, { useState, useEffect, Fragment } from "react";
import { CSSTransition } from 'react-transition-group';
import {
  Routes,
  Route,
  useParams,
  createSearchParams,
  useSearchParams,
  Navigate,
  useNavigate
} from "react-router-dom";
import axios from "axios";
import gsap from "gsap";
// import {
//   ResponsiveContainer,
//   PieChart, Pie, Cell, Rectangle
// } from "recharts";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import SVG from 'react-inlinesvg';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';
import ProjectDetailSub from './app.project_detail_sub';
import ContextMenu from './module.context_menu';
import { getStatic } from "../static";
import {ReactComponent as Cancel} from "../static/svg/cancel_icon.svg";
import {ReactComponent as Checkmark} from "../static/svg/checkmark.circle.fill.svg";
import {ReactComponent as Exclamation} from "../static/svg/exclamationmark.triangle.fill.svg";
import {ReactComponent as WrenchScrewdriver} from "../static/svg/wrench.and.screwdriver.fill.svg"
import {ReactComponent as EllipsisCircle} from "../static/svg/ellipsis.circle.fill.svg";
import {ReactComponent as Pencil} from "../static/svg/square.and.pencil.svg";
import {ReactComponent as SidebarRight} from "../static/svg/sidebar.right.svg"
import {ReactComponent as Trash} from "../static/svg/trash.svg"
import {ReactComponent as Plus} from "../static/svg/plus.svg"
import {ReactComponent as Loader} from "../static/svg/loader.svg";
import {ReactComponent as Clear} from "../static/svg/clear.svg";
import {ReactComponent as ChevronBack} from "../static/svg/chevron.backward.svg";
import {ReactComponent as Star} from "../static/svg/star.fill.svg";
import {ReactComponent as DocOnDoc} from "../static/svg/doc.on.doc.svg";
import {ReactComponent as Info} from "../static/svg/info.circle.svg";

import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "../constants.js";
import { getAllByDisplayValue } from "@testing-library/react";
if (IS_LIVE) {
  var base_url = LIVE_HOSTS.base_url + '/api/'
} else {
  var base_url = LOCAL_HOSTS.base_url + '/api/'
}

function ProjectDetail(props) {
  const nodeRef = React.useRef(null);
  const leftRef = React.useRef(null);
  const rightRef = React.useRef(null);
  const subProjectId = React.useRef(null);
  const tempTextObj = React.useRef(null);

   let {id} = useParams();
   let navigate = useNavigate();
  
      function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const [searchParams, setSearchParams] = useSearchParams();
    const [project, setProject] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [dev, setDev] = useState([]);
    const [up, setUp] = useState([]);
    const [down, setDown] = useState([]);
    const [editField, setEditField] = useState(null);
    const [editSubField, setEditSubField] = useState(null);
    const [clients, setClients] = useState([]);
    const [teamUsers, setTeamUsers] = useState([]);
    const [groups, setGroups] = useState(null);
    const [groupId, setGroupId] = useState();
    const [allGroups, setAllGroups] = useState([]);
    const [useGroups, setUseGroups] = useState([]);
    const [subProject, setSubProject] = useState();
    const [sideBarRightOpen, setSideBarRightOpen] = useState(true);
    const [showGroupFrom, setShowGroupForm] = useState(false);
    const [showSubProjectForm, setShowSubProjectForm] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showPeopleForm, setShowPeopleForm] = useState(false);
    const [peopleFormObj, setPeopleFormObj] = useState();
    const [showProjectDocForm, setShowProjectDocForm] = useState(false);
    const [fileTypes, setFileTypes] = useState([]);
    const [showDetail, setShowDetail] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showContextMenu, setShowContextMenu] = useState(false);

    function fetchJSON() {
        setShowLoader(true);
        try {
            const get_url = base_url + "project/" + id;
            axios({
                method: "get",
                url: get_url,
                headers: {
                  Authorization: 'Token ' + props.token
                },
            }).then((response) => {
                const assetJSON = response.data;
       
                setProject(assetJSON);
                let g = assetJSON.groups;
                let gPlus = [];
                
                g && g.forEach((group, i) => {
                  let subs = assetJSON.subprojects.filter(obj => (obj.group === group.id));
                  let count = subs.length;
                  let inCompleted = subs.filter(obj2 => obj2.status === 2);
                  setUp(inCompleted)
                  let completed = inCompleted.length
                  gPlus.push({
                    id: group.id,
                    name: group.name,
                    slug: group.slug,
                    count: count,
                    completed: completed/count
                  });
                });

                if (assetJSON.new_tasks > 0) {
                  document.title = assetJSON.name + " | New: " + assetJSON.new_tasks
                } else {
                  document.title = assetJSON.name
                }
                
                // if (!assetJSON.project_number) {
                //   setSideBarRightOpen(true)
                // }
                console.log('assetJSON', assetJSON)
                setUseGroups(gPlus);
                
                let groupId = searchParams.get("group");

                if (groupId) {
                  setGroups(assetJSON.groups.filter(obj => obj.id === parseInt(groupId)))
                  // console.log('groupId', groupId, assetJSON.groups, )
                } else {
                  setGroups(assetJSON.groups)
                  console.log('asset groups', assetJSON.groups)
                }
                
                if ((assetJSON.groups && assetJSON.groups.length > 0) || (assetJSON.status === 4)) {
                  setShowGroupForm(false);
                } else {
                  setShowGroupForm(true);
                }

                console.log('project fetched', assetJSON, props);

                if ((gPlus.length === 0) && (assetJSON.subprojects.length === 0)) {
                  let form = document.getElementById('projectCreateForm');
                  gsap.to('#projectCreateForm', {zIndex:3, height: 'auto', duration: 0});
                  gsap.to('#projectCreateForm', {opacity: 1, duration: 0.3});
                  if (form) {
                    form.classList.add('on');
                  }
                  
                };
                setShowLoader(false);
                
            });
        } catch (error) {
            console.error(error);
        }
        try {
          const get_url = base_url + "project-group/";
          axios({
              method: "get",
              url: get_url,
              headers: {
                Authorization: 'Token ' + props.token
              },
          }).then((response) => {
              const assetJSON = response.data;
              console.log('groups', assetJSON)
              setAllGroups(assetJSON);
          });
      } catch (error) {
          console.error(error);
      }

      try {
        const get_url = base_url + "file-type/";
        axios({
            method: "get",
            url: get_url,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
            const json = response.data;
            setFileTypes(json);
        });
    } catch (error) {
        console.error(error);
    }


      if (props.teamUsers.length === 0) {
        try {
          const get_url = base_url + "team-user/";
          axios({
              method: "get",
              url: get_url,
              headers: {
                Authorization: 'Token ' + props.token
              },
          }).then((response) => {
              const result = response.data;
              props.setTeamUsers(result);
              // console.log('team users', result);
          });
        } catch (error) {
            console.error(error);
        }
      }

      if (props.statuses.length === 0) {
        try {
          const get_url = base_url + "status/";
          axios({
              method: "get",
              url: get_url,
              headers: {
                Authorization: 'Token ' + props.token
              },
          }).then((response) => {
              const result = response.data;
              props.setStatuses(result);
              // console.log('team users', result);
          });
        } catch (error) {
            console.error(error);
        }
      }
      if (props.labels.length === 0) {
        try {
          const get_url = base_url + "label/";
          axios({
              method: "get",
              url: get_url,
              headers: {
                Authorization: 'Token ' + props.token
              },
          }).then((response) => {
              const result = response.data;
              props.setLabels(result);
              // console.log('team users', result);
          });
        } catch (error) {
            console.error(error);
        }
      }
      if (props.categories.length === 0) {
        try {
          const get_url = base_url + "categories/";
          axios({
              method: "get",
              url: get_url,
              headers: {
                Authorization: 'Token ' + props.token
              },
          }).then((response) => {
              const result = response.data;
              props.setCategories(result);
              console.log('setCategories', result);
          });
        } catch (error) {
            console.error(error);
        }
      }


    }

    function addSubProject(groupId, name) {
      console.log('addSubProject', id, groupId);
      setLoaded(false);
      let formData = {};
      formData['name'] = "untitled";
      formData['group'] = groupId;
      formData['project'] = id;
      formData['name'] = document.getElementById('subProjectName').value;
      try {
        const url = base_url + "subproject-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          
          setLoaded(true);
          setSubProject(response.data['id'])
          setShowSubProjectForm(null);
          // console.log('add', response, response.data['id'])

        });
      } catch (error) {
          console.error(error);
      }
    }

   

    function deleteSubProject(e, id) {
      console.log('deleteSubProject', id);
      setShowModal(null);
      setLoaded(false);
      let formData = {};
      formData['id'] = id;
      try {
        const url = base_url + "subproject-delete/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          setShowContextMenu(null)
          setLoaded(true);
          fetchJSON();
          // props.setReload(true);
        });
      } catch (error) {
          console.error(error);
      }
    }

    function fetchClients() {
      try {
          const get_url = base_url + "client/";
          axios({
              method: "get",
              url: get_url,
              headers: {
                Authorization: 'Token ' + props.token
              },
          }).then((response) => {
              const responseJSON = response.data;
              setClients(responseJSON);
              console.log('clients fetched', responseJSON);
          });
      } catch (error) {
          console.error(error);
      }
    }


    function handleSubBtn(e, objId) {
      if (!e.target.classList.contains('statusIcon') && !e.target.classList.contains('dropDownChoice')) {
        setSubProject(objId);
        setSearchParams([...searchParams.entries(), ['s', objId]])
        subProjectId.current = objId;
      }
      console.log(e.target.classList.contains('statusIcon'), e.target.classList);
      
      // navigate("/projects/" + id + "/" + obj.id);
    }

    function handleSubText(id) {
      var target = document.getElementById(id);
      target.classList.toggle("on");
    }

    function handleOnValue(state, value) {
      if (state === value) {
        return " on"
      } else {
        return ""
      }
    }

    function handleEditField(e, field) {
      // console.log("handleEditField", field);
      if (field === editField) {
        // e.target.classList.remove('on');
        setEditField(null);
        
      } else {
        // e.target.classList.add('on');
        setEditField(field)
      }
    }

    function editSubProjectField(fieldId, fieldValue, subProjectId) {
      setEditField(fieldId)
      let value;
      let name;
      if (fieldValue) {
        value = fieldValue;
        name = fieldId;
      } else {
        value = document.getElementById(fieldId).value;
        name = document.getElementById(fieldId).name;
      }
      try {
        document.getElementById('fieldValue_' + fieldId).classList.remove('on');
      } catch {}
      
      let formData = {};
      formData['id'] = subProjectId;
      formData['project'] = project.id
      formData[name] = value;
      console.log(formData);
      try {
        const url = base_url + "subproject-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          fetchJSON();
          setEditField(null);
          // props.setReload(true);
        });
      } catch (error) {
          console.error(error);
      }
    }

    function showProductDoc() {
      setShowProjectDocForm(true);
    }


    function addProjectDoc(e) {


      setLoaded(false);
      let headers;
      var formData = new FormData();
      formData.append('project', id);
      let nameVal = document.getElementById('projectDocName').value;
      formData.append('name', nameVal);
      
      let linkVal = document.getElementById('projectDocLink').value;
      if (linkVal) {
        formData.append('link', linkVal);
      }
      let fileTypeVal = document.getElementById('fileTypeSelect').value;
      if (fileTypeVal) {
        formData.append('file_type', fileTypeVal);
      }

      let fileList = document.getElementById('projectDocFile').files;
      if (fileList.length > 0) {
        var arrayLength = fileList.length;
        for (var i = 0; i < arrayLength; i++) {
          // var mediaData = {
          //   'file': fileList[i]
          // }
          formData.append('file', fileList[i], fileList[i].name);
          headers = {
            Authorization: 'Token ' + props.token,
            'Content-Disposition': 'attachment; filename=' + fileList[i].name
          };
        }
        
      } else {
        headers = {
          Authorization: 'Token ' + props.token
        }
      }
     
      try {
        const url = base_url + "project-doc-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: headers
        }).then((response) => {
          setLoaded(true);
          setShowProjectDocForm(null);
          fetchJSON();
          // console.log('add', response, response.data['id'])

        });
      } catch (error) {
          console.error(error);
      }
      
    }

    // function deleteProject(e) {
    //   console.log('deleteProject');
    //   // e.preventDefault(); 

    //   setLoaded(false);
    //   let formData = {};
    //   formData['id'] = project.id;
    //   try {
    //     const url = base_url + "project-delete/";
    //     axios({
    //         method: "post",
    //         url: url,
    //         data: formData,
    //         headers: {
    //           Authorization: 'Token ' + props.token
    //         },
    //     }).then((response) => {
          
    //       window.location.href = "/projects/";
    //     });
    //   } catch (error) {
    //       console.error(error);
    //   }
    // }

  

    useEffect(() => {
      if (props.token) {
        window.scrollTo(0, 0)
        fetchJSON();
        fetchClients();

       
      }
    }, [props.token]);

    useEffect(() => {
      if (project) {
        setTimeout(() => {
          setLoaded(true);
          console.log('project loaded')
        }, 300)

        let params = (new URL(document.location)).searchParams;
        let sub = params.get('s');
        if (sub) {
          subProjectId.current = parseInt(sub);
          setSubProject(parseInt(sub));
          console.log('subProjectId', subProjectId.current)
        }
      }
      
    }, [project]);

    useEffect(() => {
      if ((groups === null)&&(loaded)) {
        setShowGroupForm(true);
        console.log('groups are null')
      } 
     
    }, [groups])

    function animDropDown(open){
      if (open) {
        var tl = gsap.timeline();
        tl.to("#inputField_" + editField + " .dropDownChoices", {height: "auto", duration: 0});
        tl.to("#inputField_" + editField, {zIndex: 3, height: "auto", duration: 0}, "<");
        tl.to("#inputField_" + editField, {scale: 1, opacity: 1, duration: 0.1});
      } else {
        gsap.to(".dropDownChoices", {height: 0, duration: 0});
        gsap.to(".dropDownField", {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
      }
    }

    useEffect(() => {

      if (editField) {
        animDropDown(true)
      } else {
        animDropDown(false)
      }
    }, [editField])

    useEffect(() => {
      if (sideBarRightOpen === "closed") {
        let barAnim = gsap.timeline({
          defaults: {
            duration: 0.3, 
            ease: "none"
          }
        });

        barAnim.to('.projectGroup', {opacity: 0, duration: 0.1});
        barAnim.to(leftRef.current, {x: 0, width: '96vw'})
        if (window.innerWidth < 901) {
          barAnim.to(rightRef.current, {x: 0}, "<");
        } else {
          barAnim.to(rightRef.current, {x: "38vw"}, "<");
        } 
        
        barAnim.to('.projectGroup', {opacity: 1, duration: 0.1});
        
      } 
      if (sideBarRightOpen === "opened") {
        let barAnim = gsap.timeline({
          defaults: {
            duration: 0.3, 
            ease: "none"
          }
        });

        barAnim.to('.projectGroup', {opacity: 0, duration: 0.1});
        
        if (window.innerWidth < 901) {
          barAnim.to(rightRef.current, {x: "100%"})
          barAnim.to(leftRef.current, {x: 0, width: '96vw'}, "<")
        } else {
          barAnim.to(rightRef.current, {x: 0})
          barAnim.to(leftRef.current, {x: 0, width: '57vw'}, "<")
        }
        
        barAnim.to('.projectGroup', {opacity: 1, duration: 0.1});
        
       
      }
    }, [sideBarRightOpen, loaded])

    useEffect(() => {
      if (subProject === 'reload') {
        setSubProject(null);
        searchParams.delete('s')
        setSearchParams(searchParams)
        fetchJSON();
        
      }
    }, [subProject])  
    
    useEffect(() => {
      if (showGroupFrom) {
        let form = document.getElementById('projectCreateForm');
        gsap.to('#projectCreateForm', {zIndex:3, height: 'auto', duration: 0});
        gsap.to('#projectCreateForm', {opacity: 1, duration: 0.3});
        if (form) {
          form.classList.add('on');
        }
        
      }
    }, [showGroupFrom])

    useEffect(() => {
      if (showSubProjectForm) {
        let form = document.getElementById('addSubProjectForm');
        gsap.to('#addSubProjectForm', {zIndex:3, height: 'auto', duration: 0});
        gsap.to('#addSubProjectForm', {opacity: 1, duration: 0.3});
        if (form) {
          form.classList.add('on');
        }
      }
    }, [showSubProjectForm])

    useEffect(() => {
      if (showProjectDocForm) {
        let form = document.getElementById('addProjectDocForm');
        gsap.to('#addProjectDocForm', {zIndex:3, height: 'auto', duration: 0});
        gsap.to('#addProjectDocForm', {opacity: 1, duration: 0.3});
        if (form) {
          form.classList.add('on');
        }
      }
    }, [showProjectDocForm])

    

    useEffect(() => {
      if (showPeopleForm && peopleFormObj) {
        let form = document.getElementById('addPeopleForm');
        if (form) {
          let btns = form.querySelectorAll('.button');
          btns.forEach((obj, i) => {
            let id = parseInt(obj.dataset.id);
            let exists = peopleFormObj['people'].some(obj0 => { if (obj0 === id) { return true } return false})
            let owner;
            
            if (id === peopleFormObj['owner']) { owner = true } else {owner = false};
            // console.log('id', id, exists, peopleFormObj['people'])
            exists && obj.parentElement.classList.add('on'); 
            (owner === true) && obj.parentElement.classList.add('owner'); 
          })
          
  
          gsap.to('#addPeopleForm', {zIndex:3, height: 'auto', duration: 0});
          gsap.to('#addPeopleForm', {opacity: 1, duration: 0.3});
          form.classList.add('on');
          // console.log('props.teamUsers', props.teamUsers)
        }
       
      }
    }, [showPeopleForm, peopleFormObj])

    function handlePeopleForm(name, list) {
        setPeopleFormObj({"form": name, "people": list, "owner": project.owner});
        setShowPeopleForm(true);
        console.log('project.owner', project.owner)
    }

    function handleTeamUser(e, obj) {
      console.log('peopleFormObj', peopleFormObj);
      let formObj = peopleFormObj;
      let teamList = [ ...formObj['people'] ];

      const index = teamList.findIndex(object => {
        return object.id === obj.id;
      });
      if (e.target.parentElement.classList.contains('on')) {
        teamList.splice(index, 1);
        e.target.parentElement.classList.remove('on');

      } else {
        teamList.push(obj['id']);
        e.target.parentElement.classList.add('on');
      }
      formObj['people'] = teamList;
      setPeopleFormObj(formObj);

      // console.log('formObj', formObj);
    }

    function handleOwner(e, objId) {
      if (e.target.classList.contains('on')) {
        e.target.classList.remove('on');
      } else {
        let stars = document.getElementsByClassName('star');
        for (var i = 0; i < stars.length; i++) {
          stars[i].classList.remove('on');
        }
        e.target.classList.add('on');
        let owners = document.getElementsByClassName('owner');
        for (var j = 0; j < owners.length; j++) {
          owners[j].classList.remove('owner');
        }
        e.target.parentElement.classList.add('owner');


        let currForm = peopleFormObj;
        currForm['owner'] = objId
        setPeopleFormObj(currForm)
      }
    }

    function handleSavePeople() {

      let form = document.getElementById('addPeopleForm');
      gsap.to('#addPeopleForm', {opacity: 0, duration: 0.3});
      gsap.to('#addPeopleForm', {zIndex:-1, height: 0, duration: 0, delay: 0.3});
      form.classList.remove('on');

      let teamusers_id = [];

      peopleFormObj['people'].forEach((obj, i) => {
        teamusers_id.push(obj)
      })
      
      let formData = {};
      formData['id'] = project.id;
      formData['teamuser_ids'] = teamusers_id;

      if (peopleFormObj['owner']) {
        formData['owner'] = peopleFormObj['owner'];
      }

      console.log("peopleFormObj['owner']", peopleFormObj['owner'])

      try {
        const url = base_url + "project-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          fetchJSON();
          setEditField(null);
        });
      } catch (error) {
          console.error(error.response.data);
      }

      // console.log('groups', groups)

      setShowPeopleForm(false);
    }

    function editProjectField(fieldId, fieldValue) {
      let value;
      let name;
      if (fieldValue) {
        value = fieldValue;
        name = fieldId;
      } else {
        value = document.getElementById(fieldId).value;
        name = document.getElementById(fieldId).name;
      }
      try {
        document.getElementById('fieldValue_' + fieldId).classList.remove('on');
      } catch {}
      
      let formData = {};
      formData['id'] = project.id;
      formData[name] = value;
      console.log(formData);
      try {
        const url = base_url + "project-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          fetchJSON();
          setEditField(null);
        });
      } catch (error) {
          console.error(error);
      }
    }

    function editProjectValue(name, value) {
      let formData = {};
      formData['id'] = project.id;
      formData[name] = value;
      console.log(formData);
      try {
        const url = base_url + "project-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {

          fetchJSON();
          setEditField(null);
        });
      } catch (error) {
          console.error(error);
      }
    }

    function handleProjectImage() {
      let input = document.getElementById('projectImageFile');
      input.click();

    }

    function submitProjectImage(){
             
      setLoaded(false);
      let headers;
      var formData = new FormData();
      formData.append('id', project.id);
      formData.append('active', project.active);
      
      let fileList = document.getElementById('projectImageFile').files;
      if (fileList.length > 0) {
        var arrayLength = fileList.length;
        for (var i = 0; i < arrayLength; i++) {
          formData.append('thumbnail', fileList[i], fileList[i].name);
          headers = {
            Authorization: 'Token ' + props.token,
            'Content-Disposition': 'attachment; filename=' + fileList[i].name
          };
        }
        
      } else {
        headers = {
          Authorization: 'Token ' + props.token
        }
      }

      // console.log('fileList', fileList)
    
      try {
        const url = base_url + "project-image-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: headers
        }).then((response) => {
          setLoaded(true);
          fetchJSON();
          console.log('add', response)

        });
      } catch (error) {
          console.error(error);
      }
    }


    function handleKeyPress(e, fieldId, fieldValue) {
      if (e.key === "Enter") {
        editProjectField(fieldId, fieldValue)
      }
    }

    function handleTextEdit(fieldId) {
      
      if (tempTextObj.current) {
        editProjectField(fieldId, tempTextObj.current)
        // console.log('handleTextEdit', tempTextObj.current);
      }
      tempTextObj.current = null;
    }

    function handleSubBtnContext(e, objId) {
      console.log('handleSubBtn', e);
      // e.preventDefault();
      // let dd = document.getElementById(objId);
      // // console.log('dd', dd);
      // gsap.to(dd, {zIndex: 3, x: (e.pageX - e.target.offsetLeft), y: (e.pageY - e.target.offsetTop), duration: 0});
      // gsap.to(dd, {opacity: 1, duration: 0.3}, "-=0.3");
      // let listener = window.addEventListener('click', function(){
      //   gsap.to(dd, {opacity: 0, zIndex: -1, duration: 0});
      // })
      // window.removeEventListener('click', listener);
    }

    function filterBy(mode) {
      // switch(mode) {
      //   case "up":
      //     setAssets(up);
      //     setCancel(true);
      //     break;
      //   case "down":
      //     setAssets(down);
      //     setCancel(true);
      //     break;
      //   case "dev":
      //     setAssets(dev);
      //     setCancel(true);
      //     break;
      //   case "apps":
      //     setAssets(apps);
      //     setCategory('apps');
      //     setCancel(true);
      //     break;
      //   case "sites":
      //     setCategory('sites');
      //     setAssets(sites);
         
      //     setCancel(true);
      //     break;
      //   default:
      //     setAssets(allAssets);
      // }
    }

    function cancelAdd() {
      let form = document.getElementById('addTaskForm');
      gsap.to('#addTaskForm', {opacity: 0, duration: 0.3});
      gsap.to('#addTaskForm', {zIndex:-1, height: 0, duration: 0, delay: 0.3});
      form.classList.remove('on');
    }

    function startAdd(){
      let form = document.getElementById('addTaskForm');
  
      if (form.classList.contains('on')) {
        cancelAdd();
      } else {
        gsap.to('#addTaskForm', {zIndex:3, height: 'auto', duration: 0});
        gsap.to('#addTaskForm', {opacity: 1, duration: 0.3});
        form.classList.add('on');
      }
      
    }

    function handleGroup(e, obj) {
      let projectGroups;
      if (groups) {
        projectGroups = [ ...groups ];
      } else {
        projectGroups = []
      }
      
    
      // if (groups && groups.length > 0) {
        
      //   const noneObj = projectGroups.findIndex(object => {
      //     return object.slug === 'none';
      //   });
      //   if (noneObj > -1) {
      //     projectGroups.splice(noneObj, 1);
      //   } 

      // } else {
      //   projectGroups = [];
      // }

      // let noneBtn = document.getElementById('group-none');
      // noneBtn.classList.remove('on');

      const index = projectGroups.findIndex(object => {
        return object.id === obj.id;
      });
    


      if (e.target.classList.contains('on')) {
          projectGroups.splice(index, 1);
          e.target.classList.remove('on');
          console.log('projectGroups', projectGroups)
        } else {
          projectGroups.push(obj);
          e.target.classList.add('on');
        }
       
        setGroups(projectGroups);     
      // if (!e.target.classList.contains('locked')) {
        
      // }
            
      // if (obj.slug === 'none') {
      //   let items = document.querySelectorAll('.projectCreate .button');
      //   items.forEach((obj, i) => {
      //     obj.classList.remove('on');
      //   });
      //   e.target.classList.toggle('on');
      //   setGroups([obj]);
      // } else {

      // }
     
    }

    function handleNext(skip) {
      if (!skip) {
        if (groups && (groups.length > 0)) {
          setShowGroupForm(false);
        
          let formData = {};
          formData['id'] = project.id;
          formData['groups'] = groups;
          try {
            const url = base_url + "project-edit/";
            axios({
                method: "post",
                url: url,
                data: formData,
                headers: {
                  Authorization: 'Token ' + props.token
                },
            }).then((response) => {
              fetchJSON();
              setEditField(null);
            });
          } catch (error) {
              console.error(error);
          }
        } else {
          setShowGroupForm(true);
  
        }
      } else {
        setShowGroupForm(false);
      }
      
      

      console.log('handleNext: groups', groups)
    }

    function createBlock(e) {
      if (e.key === "Enter") {
        addSubProject(showSubProjectForm);
      }
    }
    
    const onDateSelect = (name, date) => {
      let dateFormatted;
      if (date !== null) {
        const datePattern = 'yyyy-MM-dd';
        dateFormatted = format(new Date(date), datePattern);
      } else {
        dateFormatted = null;
      }
     
      // console.log('onChange', name, dateFormatted)
      editProjectValue(name, dateFormatted);
      setShowDatePicker(false);
    };

    useEffect(() => {
      let l;
      if (showDatePicker !== false) {
        l = document.addEventListener('click', function(event) {  
          if (event.target.closest(".dateFilter")) return 
          setShowDatePicker(false)
        } , true);
        
      } else {
       
        document.removeEventListener("click", l);
      }
      
    }, [showDatePicker])

    useEffect(() => {
      if (groupId) {
        fetchJSON();
      }
    }, [groupId])

    function clearParam(param){
      searchParams.delete(param)
      setSearchParams(searchParams)
      setGroupId(null);
      fetchJSON();
    }

    function addGroupParam(value) {
      setGroupId(value);
      setSearchParams([...searchParams.entries(), ['group', value]])
    }

    function handleSort(sort) {
      console.log('sort', sort)
      let orderedSubs;
      let projectCopy = project;
      let subs = [...projectCopy.subprojects]
      if (sort === 'status') {
        orderedSubs = subs.sort((a, b) => (a.status < b.status) ? 1 : -1)
      } 
      if (sort === 'title') {
        orderedSubs = subs.sort((a, b) => (a.name > b.name) ? 1 : -1)
      }
      if (sort === 'start') {
        orderedSubs = subs.sort((a, b) => (a.start_date > b.start_date) ? 1 : -1)
      }
      if (sort === 'owner') {
        orderedSubs = subs.sort((a, b) => (a.owner_name > b.owner_name) ? 1 : -1)
        console.log('order', orderedSubs)
      }
      if (sort === 'id') {
        orderedSubs = subs.sort((a, b) => (a.id < b.id) ? 1 : -1)
      } 
      projectCopy['subprojects'] = orderedSubs;
      setProject(projectCopy);
      setEditField(null)
    }

    function handleShowDetail(name) {
      if (showDetail === name) {
        setShowDetail(null)
      } else {
        setShowDetail(name)
      }
    }

    function showDetailTab(slug) {
      let target = "#detailTab_" + slug;
      let offset;
      if (window.innerWidth < 901) { 
        offset = "0px"
      } else {
        offset = "130px"
      }
      gsap.to(target, {opacity: 1, top: offset, duration: 0.15 })
    }

    function hideDetailTab(slug) {
      let target = "#detailTab_" + slug;
      gsap.to(target, {opacity: 0, top: "100vh", duration: 0.15 })
    }

    function goBack() {
      window.location.href = "/projects/"
    }

    function onShowModal(value) {
      if (value === 'deleteProject') {
        setShowModal(value)
      }
    }

    function clearContext(e) {
      if (e.key === "Escape") {
        setShowContextMenu(false);
        setEditField(null)
      }
      
    }

    function onModuleContext(e, obj) {
      let app = document.querySelector('.app');
      console.log('onModuleContext', e, obj)
      e.preventDefault();

      // let obj = modules.find(a => a.id === id)
      setShowContextMenu({obj: obj, x: e.clientX + window.scrollX, y: (e.clientY + app.scrollTop + 90)})
    }

    const contextBtns = [
      {text: "Delete", action: () => setShowModal({name: 'deleteSubProject', id: showContextMenu.obj.id})}
    ]


    useEffect(() => {
      let showModalVal;
      if (showModal) {
        if (showModal.name) {
          showModalVal = showModal.name
        } else {
          showModalVal = showModal
        }
        gsap.to('#modal_' + showModalVal, {zIndex:3, height: 'auto', duration: 0});
        gsap.to('#modal_' + showModalVal, {opacity: 1, duration: 0.3});
      } 
    }, [showModal])

    return (
      <React.Fragment>
      {showLoader && <div className="loader"><Loader /></div>}
      <CSSTransition in={loaded} timeout={300} unmountOnExit classNames="pageItem" nodeRef={nodeRef}>
        <section className={"main detail projectPage" + ((project.status === 4)?" proposal":"")} ref={nodeRef}>

        {showContextMenu && <ContextMenu 
        contextBtns={contextBtns}
        onContext={showContextMenu}
        setShowContextMenu={setShowContextMenu}
      />}
      


        {showModal && showModal === "deleteProject" &&  <div className="modalForm" id={"modal_" + showModal}>
          
        <span className="modal">
          <div className="warning">
            <h2>Warning</h2>
            <p>Are you sure you want to delete this project?</p>

            

            </div>
            <span className="buttonRow nextRow">
            <div className="btn" onClick={() => setShowModal(false)}>Cancel</div>
            <div className="btn save" onClick={() => handleNext(false)}>Continue</div>
            
          </span>
          </span>
      
        </div>

        }

        {showModal && showModal.name === "deleteSubProject" &&  <div className="modalForm" id={"modal_" + showModal.name}>
          
        <span className="modal">
          <div className="warning">
            <h2>Warning</h2>
            <p>Are you sure you want to delete this project block?</p>
            <p>This deletion is permanent</p>

            

            </div>
            <span className="buttonRow nextRow">
            <div className="btn" onClick={() => setShowModal(false)}>Cancel</div>
            <div className="btn save" onClick={() => deleteSubProject(null, showModal.id)}>Continue</div>
            
          </span>
          </span>
      
        </div>

        }


        
        {showGroupFrom && 
          <div className="modalForm" id="projectCreateForm">
          
          <span className="modal projectCreate">
            <div className="formBody">
              <h2>Select Project Groups</h2>
              <span className="buttonRow">
                {allGroups && allGroups.map((obj, i) => (
                  <div onClick={(e) => handleGroup(e, obj)} id={"group-" + obj.slug} className={"button btn" + ((groups && groups.some(obj0 => { if (obj0.id === obj.id) { return true } return false}))?" on":"") + ((useGroups && useGroups.some(obj0 => { if (obj0.id === obj.id) { return true } return false}))?" locked":"")} key={'groupBtn_' + i}>
                    {obj.name}
                  </div>
                  ))}
                </span>

              

              </div>
              <span className="buttonRow nextRow">
              <div className="btn" onClick={() => handleNext(true)}>Cancel</div>
              <div className="btn save" onClick={() => handleNext(false)}>Save</div>
              
            </span>
            </span>
        
          </div>
        }
        {showSubProjectForm && <div className="userForm modalForm" id="addSubProjectForm">
          <div className="modal">
          <div className="formBody">
            <div className="formField">
              <h2>Create a Block</h2>
              <span className="inputField">
                <input type="text" name="name" placeholder="Name" onKeyUp={(e) => createBlock(e)} autoComplete="off" id="subProjectName" />
                <span className="smCancel btn" onClick={() => setShowSubProjectForm(null)}><Clear /></span>
              </span>
            </div>
          </div>
          </div>
        </div>
        }

        {showProjectDocForm && <div className="userForm modalForm" id="addProjectDocForm">
          <div className="modal">
          <div className="formBody">
            <div className="formField">
              <h2>Add a Document</h2>
              <span className="inputFields">
                <input type="text" name="name" placeholder="Name" autoComplete="off" id="projectDocName" />
                <span className="formDropDown">
                  <select id="fileTypeSelect">
                    <option>Select a File Type</option>
                    {fileTypes.map((obj, i) => (
                      <option key={'option_' + i} data-ext={obj.external} value={obj.id}>{obj.name}</option>
                    ))}
                  </select>
                </span>
                <input type="file" name="file" id="projectDocFile" />
                <input type="text" name="link" placeholder="Link" id="projectDocLink" />
              </span>
              <span className="smCancel btn textarea">
                <Checkmark onClick={(e) => addProjectDoc(e)} />
               <Cancel onClick={() => setShowProjectDocForm(false)} />
            </span>
            </div>
          </div>
          </div>
        </div>
        }


        {showPeopleForm && 
          <div className="modalForm" id="addPeopleForm">
          <span className="modal projectCreate">
          <div className="formBody">
              <h2>Add People</h2>
              <span className="buttonRow teamButtonRow">
                {props.teamUsers.filter(obj0 => (obj0.id === project.owner)).map((obj, i) => (
                  <span className="teamBtn" key={'peopleBtn_' + i}>
                  <div onClick={(e) => handleTeamUser(e, obj)} className={"button btn"} data-id={obj.id}>
                    {obj.name}
                  </div>
                  <Star className={"star" + ((project.owner === obj.id)?" on":"")} onClick={(e) => handleOwner(e, obj.id)} />
                  </span>
                  ))}
                  {props.teamUsers.filter(obj0 => (obj0.id !== project.owner)).map((obj, i) => (
                    <span className="teamBtn" key={'peopleBtn_' + i}>
                    <div onClick={(e) => handleTeamUser(e, obj)} className={"button btn"} data-id={obj.id}>
                      {obj.name}
                    </div>
                    <Star className={"star" + ((project.owner === obj.id)?" on":"")} onClick={(e) => handleOwner(e, obj.id)} />
                    </span>
                    ))}
                </span>
                <span className="buttonRow nextRow" onClick={() => handleSavePeople()}><div className="btn">Save</div></span>
                </div>
                </span>
         
          </div>
        }

       

          {!loaded && <div className="loader"><Loader /></div>}
          <div className="projectHeading">
            <div className="column">
              <span className="headingFlex">
                  <div className="projectTitle"><div className="backBtn" onClick={() => goBack()}><ChevronBack className="icon btn" /></div><h1>{project.name}</h1></div>
                  <div className="siteFilters flex">
                    <div className="bar"></div>
                      <div className="siteFilters flex">
                      <div className="siteFilter btn" onClick={() => filterBy('dev')}>
                        <span className="icon"><WrenchScrewdriver /></span>
                        <span className="statSm">{project.status_stats && project.status_stats.dev}</span>
                    </div>
                    <div className="siteFilter btn" onClick={() => filterBy('down')}>
                      <span className="icon"><Exclamation /></span>
                      <span className="statSm">{project.status_stats && project.status_stats.blocked}</span>
                    </div>
                    <div className="siteFilter btn" onClick={() => filterBy('up')}>
                        <span className="icon"><Checkmark /></span>
                        <span className="statSm">{project.status_stats && project.status_stats.completed}</span>
                    </div>
                  </div>
                   
                    <div className="bar"></div>
                    <div className="filterField">
                    <div className="btn sortBtn" onClick={(e) => handleEditField(e, 'sort')}>Sort</div>
                    <span className="inputField dropDownField" id="inputField_sort">
                      <span className="dropDown">
                        <span className="dropDownChoices">
                          <span className={"dropDownChoice btn"} onClick={() => handleSort('status')}>Status</span>
                          <span className={"dropDownChoice btn"} onClick={() => handleSort('title')}>Title</span>
                          <span className={"dropDownChoice btn"} onClick={() => handleSort('start')}>Start Date</span>
                          <span className={"dropDownChoice btn"} onClick={() => handleSort('owner')}>Owner</span>
                          <span className={"dropDownChoice btn"} onClick={() => handleSort('id')}>Date Added</span>
                        </span>
                      </span>
                    
                    </span> 
                    </div>
                    <div className="siteFilter btn">
                      <input type="text" className="search" placeholder="Search" />
                    </div>
                    <div className="bar"></div>
                    <span onClick={() => setShowGroupForm(!showGroupFrom)}><div className="userBtn btn"><Pencil /></div></span>
                </div>
              </span>
              <span className="flex">
                
                <SidebarRight onClick={() => setSideBarRightOpen(((sideBarRightOpen !== "closed") ? "closed" : "opened"))} className="icon btn sideBarRightBtn" />
              </span>
            </div>
          </div>

          <div className={"column detailColumns" + (sideBarRightOpen?"":" wide")}>
            <div className={"leftColumn"} ref={leftRef}>
              {groupId && <div className="backBtn subProjectBack" onClick={() => clearParam('group')}><ChevronBack className="icon btn" /></div>}
              {subProject && <ProjectDetailSub token={props.token} subId={subProject} setSubProject={setSubProject}  teamUsers={props.teamUsers} setTeamUsers={props.setTeamUsers} statuses={props.statuses} setStatuses={props.setStatuses}  labels={props.labels} setLabels={props.setLabels} setCategories={props.setCategories} categories={props.categories} dark={props.dark} userId={props.userId} />}
              {(groups && !subProject && groups.length > 0) ?
              (<div className="projectGroups">
              {groups.map((item, h) => (
                <div className="projectGroup" key={'projectGroup_' + h}>
                  <div className="groupHeading">
                    <span className="btn" onClick={() => addGroupParam(item.id)}><h3>{item.name}</h3> </span>
                    <Plus onClick={() => setShowSubProjectForm(item.id)}className="icon btn" />  
                  </div>

                  <div className="subProjectsGrid">
                
                  {project.subprojects.filter(obj => obj.group === item.id).map((item, i) => (
                    <span 
                    onContextMenu={(e) => onModuleContext(e, item)}   
                    className={"subRowBtnWrap"} 
                      key={'detailItem_' + i} >
                    {item.new ? <span className={"badge"}>{item.new}</span>:""}
                    <span className={"subRowBtn status" + item.status} >
                    <div className="btnInside" onClick={(e) => handleSubBtn(e, item.id)} 
                    
                    >
                          <span>
                          
                           <h4>{item.name} {(item.labels && (item.labels.length > 0)) && item.labels.map((item0, j) => (
                            <span key={'label_' + j} className={"labelIcon" + ((item0.name === "bug")?" bugIcon":"")} style={{backgroundColor: (props.dark ? item0.color_dark : item0.color)}}>
                            {item0.icon && <img src={item0.icon} alt="icon"  />}
                            {item0.name === "bug" &&  <span className="bugCount">{item.active}</span>}
                            </span>
                          ))}</h4>

                           

                            {item.status_note && <p>{item.status_note}</p>}
                          </span>

                          
                          <span className="blockBottomRow">
                            <span className="statusIcon" onClick={(e) => handleEditField(e, "subproject_status_" + item.id)}>
                              <span className="field">
                              <span className="fieldValue btn">
                                <span className="statusIconBtn" id={"fieldValue_subproject_status_" + item.id}>
                                {props.statuses && props.statuses.filter(obj0 => obj0.id === item.status).map((item, i) => (
                                  <SVG key={'status_' + i} src={item.icon} className="projectIcon" />
                                ))}
                                </span>
                              </span>
                                <span className="inputField dropDownField status" id={"inputField_subproject_status_" + item.id}>
                                  <span className={"dropDown"}>
                                    <span className="dropDownChoices status">        
                                      {props.statuses && props.statuses.map((item0, i) => (
                                        <span key={'dropdownStatus_' + i} className={"dropDownChoice btn" + handleOnValue(item0.status, item0.id)} onClick={() => editSubProjectField("status", item0.id, item.id)}><SVG key={'status_' + i} src={item0.icon} className="projectIcon" /></span>
                                      ))}
                                      
                                    </span>
                                  </span>
                                </span>
                              </span>
                          </span>
                          
                                        
                            <span className="initials">
                            {item.team && item.team.filter(obj0 => obj0.is_owner).map((item, j) => (
                              <span key={'owner_' + j} className={"teamInitial" + ((item.is_owner)?(" ownerInitial"):"")}>{item.initials}</span>
                            ))} 
                            {item.team && item.team.filter(obj0 => !obj0.is_owner).map((item, j) => (
                              <span key={'owner_' + j} className={"teamInitial" + ((item.is_owner)?(" ownerInitial"):"")}>{item.initials}</span>
                            ))} 
                            </span>
                            
                          </span>
                          
                    </div>

                    </span>
                    </span>
                  ))}
                  </div>
                  
                </div>
              ))}

                  
            
    
              </div>):("")}
            </div>
            <div className={"rightColumn"} ref={rightRef}>
              <div className="projectCore">
                <div className="projectThumbnailGrid">
              
                  <div className="projectFields">
          
                  <h2>
                  {(!project.name || editField === "name") ? 
                  <span className="inputField"><input onKeyUp={(e) => handleKeyPress(e, "name", null)} type="text" defaultValue={project.name} name="name" id="name" />
                  <span className="smCancel btn" onClick={() => setEditField(null)}><Cancel/></span>
                </span> : <span className="fieldValue" onClick={() => setEditField("name")}>{project.name}</span>}
                  </h2>
          
                  <div className="projectField">
                  <span className="heading">Status</span>
                  <span className="field">
                  <span className="fieldValue btn" onClick={(e) => handleEditField(e, "status")}>
                    <span id="fieldValue_status">{project.status ? (<span>
                    {props.statuses && props.statuses.filter(obj => obj.id === project.status).map((item, i) => (
                      <span className="statusIcon" key={'status_' + i} >
                          <SVG src={item.icon} className="projectIcon" />
                          <span className="iconName">{item.name}</span></span>
                        ))}</span>) : <span className="fieldCta">Add Status</span>}</span>
                      </span>
                        <span className="inputField dropDownField" id="inputField_status">
                          <span className={"dropDown"}>
                            <span className="dropDownChoices statusWithName">

                              {props.statuses && props.statuses.filter(a => a.name !== "Unread").map((item, i) => (
                                <span key={'dropdownStatus_' + i} className={"dropDownChoice gridChoice btn" + handleOnValue(project.status, item.id)} onClick={() => editProjectField("status", item.id)}>
                                  <SVG key={'statusDd_' + i} src={item.icon} className="projectIcon" />
                                  <span className="iconName">{item.name}</span>
                                  </span>
                              ))}
                              
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  
              
                  
                  <div className="projectField">
                    <span className="heading">Client</span>
                    <span className="field">
                    <span className="fieldValue btn" onClick={(e) => handleEditField(e, "client")}>
                      <span id="fieldValue_client">{project.client_name ? (project.client_name) : <span className="fieldCta">Add Client Name</span>}</span>
                      </span>
                      {(editField === "client") ? 
                      <span className="inputField dropDownField" id="inputField_client">
                        <span className="dropDown">
                          <span className="dropDownChoices">
                            {clients && clients.map((obj, i) => (
                              <span className={"dropDownChoice btn" + handleOnValue(project.client, obj.id)} key={'choice_' + i} onClick={() => editProjectField("client", obj.id)}>{obj.name}</span>
                              ))}
                          </span>
                        </span>
                      
                      </span> 
                      : null}

                    </span>
                  </div>

                  <div className="projectField">
                  <span className="heading">Number</span>
                  <span className="field">
                      {(editField === "project_number") ? 
                        <span className="inputField"><input type="text" defaultValue={project.project_number} placeholder="Number" onKeyUp={(e) => handleKeyPress(e, "project_number", null)} name="project_number" id="project_number" />
                        <span className="smCancel btn" onClick={() => setEditField(null)}><Cancel/></span>
                      </span> : project.project_number ? <span className="fieldValue" onClick={() => setEditField("project_number")}>{project.project_number}</span> : <span className="fieldCta" onClick={() => setEditField("project_number")}>Add Number</span>}
                  </span>
                </div>

                  <div className="projectField">
                    <span className="heading">Category</span>
                    <span className="field">
                      <span className="fieldValue btn" onClick={(e) => handleEditField(e, "project_category")}>{project.category_name ? <span id="fieldValue_project_category">{ project.category_name }</span> : <span className="fieldCta">Add Category</span>}</span>
                      <span className="inputField dropDownField" id="inputField_project_category">

                        <span className="dropDown">
                          <span className="dropDownChoices">
                          {props.categories && props.categories.map((item, i) => (
                            <span key={"category_" + i} className={"dropDownChoice btn" + handleOnValue(project.project_category, item.id)} onClick={() => editProjectField("project_category", item.id)}>{item.name}</span>

                          ))}
                            
              
                          </span>
                        </span>
                      </span> 
      
                              
                    </span>
                  </div>
                  
                  </div>
                
                      
                  <div className="projectThumbnail" onClick={() => handleProjectImage()}>
                  {(project.thumbnail)?<img className="thumb" src={getStatic(project.thumbnail)} alt="" />:<figure className="thumb btn" alt="">Add Image</figure>}              
                  <input type="file" name="file" accept="image/*" onChange={() => submitProjectImage()} id="projectImageFile" />
                </div>
                
                </div>
              
                <div className="projectFields">
                  
                  
                  <div className="projectField">
                    <span className="heading">Start</span>
                    <span className="field btn" onClick={() => setShowDatePicker('start_date')}>{project.start_date ? format(new Date(zonedTimeToUtc(project.start_date, Intl.DateTimeFormat().resolvedOptions().timeZone)), "MMM d, Y") : <span className="fieldCta">Add Start Date</span>}</span>
                    {(showDatePicker === 'start_date') &&  <div className="dateFilterPos"><div className="dateFilter"><DatePicker
                    selected={project.start_date && new Date(zonedTimeToUtc(project.start_date, Intl.DateTimeFormat().resolvedOptions().timeZone))}
                    onChange={(date) => onDateSelect('start_date', date)}
                    onSelect={(date) => onDateSelect('start_date', date)}
                    // startDate={project.start_date}
                    inline
                    /> </div></div>}
                  </div>
                  <div className="projectField">
                    <span className="heading">End</span>
                    <span className="field btn" onClick={() => setShowDatePicker('end_date')}>{project.end_date ? format(new Date(zonedTimeToUtc(project.end_date, Intl.DateTimeFormat().resolvedOptions().timeZone)), "MMM d, Y") : <span className="fieldCta">Add End Date</span>}</span>
                    {(showDatePicker === 'end_date') &&  <div className="dateFilterPos endDateFilter"><div className="dateFilter"><DatePicker
                    selected={project.end_date && new Date(zonedTimeToUtc(project.end_date, Intl.DateTimeFormat().resolvedOptions().timeZone))}
                    onChange={(date) => onDateSelect('end_date', date)}
                    onSelect={(date) => onDateSelect('end_date', date)}
                    inline
                    /> 
                    <span className="clearDate btn" onClick={() => onDateSelect('end_date', null)}>Clear Date</span>
                    </div></div>}
                  </div>
                </div>
                  
                <div className="note">
                  <div className="heading btn" onClick={() => handleShowDetail('notes')}>Notes</div>
                  <div className={"text detailText on" + ((showDetail ==="notes")?" on":"")}>
                  {(editField === "note") ? 
                    <span className="text">
                  
                    <CKEditor
                        editor={ ClassicEditor }
                        data={project.note}
                        config={{
                          toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ]
                        }}
                        onReady={() => {
                          tempTextObj.current = null;
                        }}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            tempTextObj.current = data;
                        } }
                    />
                    <span className="smCancel btn textarea">
                      <Checkmark onClick={(e) => handleTextEdit("note")} />
                      <Cancel onClick={() => setEditField(null)} />
                    </span>
      
                  </span> :
                    project.note ? <div className="text" onClick={() => setEditField("note")} dangerouslySetInnerHTML={ {__html: project.note} } /> : <span className="fieldCta" onClick={() => setEditField("note")}>Add Note</span>
                }
                  </div>
                </div>
              </div>
              <div className="detailTabs">
                <div className="detailTab on" id="detailTab_desc">
                  <div className="tabHeading btn" onClick={() => hideDetailTab('desc')}><span className="backBtn"><ChevronBack className="icon btn" /></span><h3>Details</h3></div>
                  <div className="heading">About</div>
                  <div className={"text detailText on" + ((showDetail ==="desc")?" on":"")}>
                  {(editField === "description") ? 
                    <span className="text">
                  
                    <CKEditor
                        editor={ ClassicEditor }
                        data={project.description}
                        config={{
                          toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ]
                        }}
                        onReady={() => {
                          tempTextObj.current = null;
                        }}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            tempTextObj.current = data;
                        } }
                    />
                    <span className="smCancel btn textarea">
                      <Checkmark onClick={(e) => handleTextEdit("description")} />
                      <Cancel onClick={() => setEditField(null)} />
                    </span>
      
                  </span> :
                    project.description ? <div className="text" onClick={() => setEditField("description")} dangerouslySetInnerHTML={ {__html: project.description} } /> : <span className="fieldCta" onClick={() => setEditField("description")}>Add Descripton</span>
                }
                  </div>
            
                
                <div className="projectFields">
            
                  <div className="projectField">
                  <span className="heading">Budget</span>
                  <span className="field">
                      {(editField === "budget") ? 
                        <span className="inputField"><input type="text" defaultValue={project.budget} placeholder="Number" onKeyUp={(e) => handleKeyPress(e, "budget", null)} name="budget" id="budget" />
                        <span className="smCancel btn" onClick={() => setEditField(null)}><Cancel/></span>
                      </span> : project.budget ? <span className="fieldValue" onClick={() => setEditField("budget")}>${numberWithCommas(project.budget)}</span> : <span className="fieldCta" onClick={() => setEditField("budget")}>Add Budget</span>}
                  </span>
                </div>
          
                    <div className="projectField">
                    <span className="heading">Hourly</span>
                    <span className="field">{(project.hourly_rate)?(project.hourly_rate):("—")}</span>
                  </div>
            
                  </div>
                  
                  {project.team_names && 
                    (
                  <div className="team teamFields">

                    <div className="projectField">
                      <span className="heading btn" onClick={() => handlePeopleForm('team', project.users)}>Team</span>
                      <span className="field teamList">
                        {(project.team_names.length > 0) ? <Fragment>
                          {project.team_names.filter(obj => obj.is_owner).map((item, i) => (
                            <div className="teamMember" key={'teamlItem_' + i}>
                              <span  onClick={() => handlePeopleForm('team', project.users)}  className={"teamInitial btn" + ((item.is_owner)?(" ownerInitial"):"")}>{item.initials}</span>
                            <span onClick={() => handlePeopleForm('team', project.users)} className="subItem btn">{item.name}</span>
                
                          </div>
                          ))}
                          {project.team_names.filter(obj => !obj.is_owner).map((item, i) => (
                            <div className="teamMember" key={'teamlItem_' + i}>
                              <span  onClick={() => handlePeopleForm('team', project.users)}  className={"teamInitial btn" + ((item.is_owner)?(" ownerInitial"):"")}>{item.initials}</span>
                            <span onClick={() => handlePeopleForm('team', project.users)} className="subItem btn">{item.name}</span>
                
                          </div>
                          ))}
                          </Fragment>
                        :<span className="fieldCta" onClick={() => handlePeopleForm('team', project.users)}>Add People</span>}
                      </span>
                    </div>
                          
                    
                  </div>
                  )}
                </div>

                <div className="detailTab on" id="detailTab_docs">
                  <div className="tabHeading btn" onClick={() => hideDetailTab('docs')}><span className="backBtn"><ChevronBack className="icon btn" /></span><h3>Documents</h3></div>
                  <div className="documents">
                    <span className="heading">
                      Documents
                      <Plus onClick={() => showProductDoc()} className="icon btn smallPlus" />  
                    </span>
                    <span className="docGrid">
                    {project.documents && project.documents.map((item, i) => (
                      <div className="fileObj" key={'detailItem_' + i}>
                          <a href={(item.file ? item.file : item.link)} target="blank" className="subItem">
                            {item.icon && <img src={item.icon} alt="icon" className="fileIcon" />}
                            {item.name}
                          </a>
                      </div>
                      ))}
                      </span>
                  </div>
                </div>
          
                <div className="detailTab" id="detailTab_req">
                  {(project.requirementgroups && project.requirementgroups.length > 0) ?
                    (<div className="requirementGroupArray">
                    <div className="heading">REQUIREMENTS</div>

                        {project.requirementgroups.map((item, i) => (
                          <div className={"requirementGroupRow"} id={"subitem_" + i} key={'requirementGroup_' + i}>
                            <div className="requirementGroup">
                                <span className="requirementGroupName"  onClick={(e) => handleSubText("requirementItems_" + i)} >{item.name}</span>
                                <span className="requirementGroupSubRow" id={"requirementItems_" + i}>
                                  {item.text && <span className="description">{item.text}</span>}
                          
                                  {item.requirements.map((requirement, j) => (
                                    <div className={"requirementRow status_" + requirement.status} key={'requirementItem_' + j}>
                                      <div className="requirementGroup">
                                          
                                          <span className="requirementGroupName">{requirement.name}</span>
                                          <span className="requirementStatus">{requirement.status_name}</span>
                                          {requirement.text && <span className="description" dangerouslySetInnerHTML={ {__html: requirement.text}} />}
                                      </div>
                                    </div>
                                  ))}
                                </span>

                            </div>
                          
                          </div>
                        ))}
                  
          
                    </div>):("")}
                  </div>
                  
                
                  <div className="detailTabBtns">
                    <div className="detailTabBtn btn" onClick={() => showDetailTab('desc')}>
                      <Info className="icon" />
                      Details
                    </div>
                    <div className="detailTabBtn btn" onClick={() => showDetailTab('docs')}>
                    <DocOnDoc className="icon" />
                    Documents</div>
                  </div>

                </div>
            </div>
            
          </div>

          <div className="btn userBtn deleteBtn" onClick={(e) => onShowModal('deleteProject')}>
            <Trash />
          </div>
        </section>
      </CSSTransition>
      </React.Fragment>
    );
}

export default ProjectDetail;