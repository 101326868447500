import React, { useState, useEffect, Fragment, useRef } from "react";
// import { useDrag, useDrop, DragPreviewImage } from 'react-dnd'
import { ItemTypes } from './ItemTypes.js'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import update from 'immutability-helper'
import gsap from "gsap";
import axios from "axios";
import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';
import SVG from 'react-inlinesvg';
import DragLine from "../static/svg/dragLine.svg";
import {ReactComponent as DragLineSVG} from "../static/svg/dragLine.svg";
import {ReactComponent as Cancel} from "../static/svg/cancel_icon.svg";
import {ReactComponent as Checkmark} from "../static/svg/checkmark.circle.fill.svg";
import {ReactComponent as Tag} from "../static/svg/tag.svg";
import {ReactComponent as Star} from "../static/svg/star.fill.svg";
import {ReactComponent as Exclamation} from "../static/svg/exclamationmark.triangle.fill.svg";

import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "../constants.js";
if (IS_LIVE) {
  var base_url = LIVE_HOSTS.base_url + '/api/'
} else {
  var base_url = LOCAL_HOSTS.base_url + '/api/'
}

function SubTask(props) {
    const target = useRef();
    const refSub = useRef(null)
    const tempTextObj = useRef(null);
    const userTyped = useRef(null);

  const id = props.id;
  const index = props.index;

  function clearDragLine(){
    // console.log('clearDragline')
    gsap.to('.drop', {backgroundColor: "transparent", duration: 0.1})
  }

  function onCommitText(event){
    if (userTyped.current) {
      props.onTaskText(event, props.obj.id, tempTextObj.current)
    } else {
      props.setEditField(null)
    }
    
  }

  function runReorder(reOrder) {
     
    let idList = []
    reOrder.forEach(a => idList.push(a.id))
    let formData = {};
    formData['ids'] = idList;

    try {
      const url = base_url + "task-reorder/";
      axios({
          method: "post",
          url: url,
          data: formData,
          headers: {
            Authorization: 'Token ' + props.token
          },
      }).then((response) => {
       console.log('task-reorder response', response)
        // props.setReload(true);
      });
    } catch (error) {
        console.error(error);
    }
  }

  

  function moveCard(dragIndex, hoverIndex) {
    console.log('moveCard', dragIndex, hoverIndex)

    let reOrderSubs = update(props.subs, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, props.subs[dragIndex]],
      ],
    })

    let newTasks = [ ...props.tasks];
    newTasks[props.parent_index]['subs'] = reOrderSubs
    props.setTasks(newTasks);

    target.current = null
    clearDragLine()

  }

  // const [{ handlerId, isOver, isOverCurrent }, drop] = useDrop({
  //   accept: ItemTypes.TASK,
  //   drop(_item, monitor) {
  //       const didDrop = monitor.didDrop()
  //       // clearDragLine()
  //       console.log('didDrop', didDrop)
  //       if (!didDrop) {
  //           moveCard(props.index, target.current)
  //           return
  //         }
         
         
  //       // return
  //   },
  //   collect(monitor) {
  //     return {
  //       handlerId: monitor.getHandlerId(),
  //       isOver: monitor.isOver({ shallow: true }),
  //       isOverCurrent: monitor.isOver({ shallow: true })
  //     }

  //   },
  //   hover(item, monitor) {
       
  //     if (!refSub.current) {
  //       return
  //     }
  //     const dragIndex = item.index
  //     const hoverIndex = props.index
  //     // Don't replace items with themselves
  //     if (dragIndex === hoverIndex) {
       
  //       return
  //     }
  //     // Determine rectangle on screen
  //     const hoverBoundingRect = refSub.current?.getBoundingClientRect()
  //     // Get vertical middle
  //     const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
  //     // Determine mouse position
  //     const clientOffset = monitor.getClientOffset()
  //     // Get pixels to the top
  //     const hoverClientY = clientOffset.y - hoverBoundingRect.top
  //     // Only perform the move when the mouse has crossed half of the items height
  //     // When dragging downwards, only move when the cursor is below 50%
  //     // When dragging upwards, only move when the cursor is above 50%

      

  //     // Dragging downwards
  //     if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
  //       return
  //     }
  //     // Dragging upwards
  //     if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
  //       return
  //     }
   

     
  //   // console.log('monitor', monitor.canDrop())
    
  //   target.current = dragIndex;
  //   clearDragLine()
  //   gsap.to('#drop_sub_' + props.tasks[hoverIndex].id, {backgroundColor: "rgba(200,200,200,0.5)", duration: 0.2}) 
  //   // clearDragLine()
    
    
  //   // moveCard(props.index, target.current)
  //   // gsap.to('#dragLine_' + props.tasks[hoverIndex].id, {opacity: 1, duration: 0}) 
 

  //     // Note: we're mutating the monitor item here!
  //     // Generally it's better to avoid mutations,
  //     // but it's good here for the sake of performance
  //     // to avoid expensive index searches.
  //     item.index = hoverIndex
  //   }
  // });
  


  // const [{ isDragging }, drag ] = useDrag({
  //   type: ItemTypes.TASK,
  //   item: () => {
  //       return { id, index }
  //     },
  //   collect: (monitor) => ({
  //     isDragging: monitor.isDragging()
  //   })
    
  // });
  // const opacity = isDragging ? 0.4 : 1;

  // drag(drop(refSub));
 

  function onTaskDown(e) {
    props.onListTab(e, props.sub, props.tasks[props.index-1])
  }

  let timeoutId;

  function simulateLongPress(callback) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(callback, 500);
  }


  function onTaskMouseDown(e) {
    simulateLongPress(function() {
     
    });

  }


    return ( 
        <div className="drop">
        
        <div ref={refSub} id={"drop_sub_" + props.sub.id} className={"task subtask taskRow" + (props.editField === "task_name_" + props.sub.id ? " on":"")} onMouseUp={() => clearTimeout(timeoutId)} onMouseDown={(e) => onTaskMouseDown(e)} onKeyDown={(e) => onTaskDown(e)}>

                <span className="taskRowGrid">
                <span className={"checkbox" + ((props.sub.status === 2) ? " checked":"")} id={"check_" + props.sub.id} onClick={() => props.onCheck("status", props.sub.status, props.obj.id, props.sub.id)}>
                <Checkmark className="checkmark" />
                </span>
                
                {(!props.sub.name|| props.editField === "task_name_" + props.sub.id) ? 
                <span className="inputField taskHeading">{props.sub.name ? <input type="text" defaultValue={props.sub.name} onKeyUp={(e) => props.handleTaskKeyPress(e, "task_name_" + props.sub.id, null, props.sub.id)} name="name" id={"task_name_" + props.sub.id}/> : <input type="text" placeholder={"Name"} name="task_name" id={"task_name_" + props.sub.id} onKeyUp={(e) => props.handleTaskKeyPress(e, "task_name", null, props.sub.id)}  />}
                
                </span> : <span className="fieldValue taskHeading" onClick={(e) => props.editText(e, "task_name_" + props.sub.id)}><h4>{props.sub.name}</h4></span>}
                    
                
                <span className="taskDescription taskDesc">
                {(props.editField === "task_description_" + props.sub.id) ? 
                <div>
                <span className="text">

                <CKEditor
                editor={ ClassicEditor }
                id={"task_description_" + props.sub.id} 
                data={props.sub.description}
                config={{
                    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
                    link: { addTargetToExternalLinks: true }
                }}
                onReady={() => {
                    tempTextObj.current = null;
                }}
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    // console.log( { event, editor, data } );
                    tempTextObj.current = data;
                    
                } }
                /> 

                <span className="smCancel btn textarea">
                    <Checkmark onClick={(event) => props.onTaskText(event,props.sub.id, tempTextObj.current)} />
                    <Cancel onClick={() =>props.setEditField(null)} />
                </span>
                </span>
            </div> :
            (props.sub.description ? <div className={"addText text" + (props.sub.description?"":" empty")} onClick={() =>props.setEditField("task_description_" + props.sub.id)} dangerouslySetInnerHTML={ {__html: props.sub.description} } /> : <span className="fieldCta subTaskFieldCta" onClick={() =>props.setEditField("task_description_" + props.sub.id)}>Add Descripton</span>)
                }
            </span>

                
                </span>
          </div>
    </div>
               
    );
}

export default SubTask;