import React, { useState, useEffect } from "react";
import {
  Link
} from "react-router-dom";
import axios from "axios";
import { CSSTransition } from 'react-transition-group';
import gsap from "gsap";
import { getStatic } from '../static';
import {ReactComponent as Placeholder} from "../static/svg/text.below.photo.svg"
import {ReactComponent as ArrowUpCircle} from "../static/svg/arrow.up.circle.fill.svg"
import {ReactComponent as ArrowDownCircle} from "../static/svg/arrow.down.circle.fill.svg"
import {ReactComponent as WrenchScrewdriver} from "../static/svg/wrench.and.screwdriver.fill.svg"
import {ReactComponent as Clear} from "../static/svg/clear.svg"
import {ReactComponent as Folder} from "../static/svg/folder.fill.svg"

import {ReactComponent as ChevronBackward} from "../static/svg/chevron.backward.svg"
import Loader from "../static/svg/loader.svg";




import imagePlaceholder from '../static/img/image.png'

import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "../constants.js";
if (IS_LIVE) {
  var base_url = LIVE_HOSTS.base_url + '/api/'
} else {
  var base_url = LOCAL_HOSTS.base_url + '/api/'
}

function Site(props) {
  const nodeRef = React.useRef(null);
    const [allAssets, setAllAssets] = useState([]);
    const [assets, setAssets] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [editField, setEditField] = useState(null);
    const [client, setClient] = useState();
    const [dev, setDev] = useState(0);
    const [up, setUp] = useState(0);
    const [down, setDown] = useState(0);
    const [group, setGroup] = useState();
    const [cancel, setCancel] = useState(false);
    const [apps, setApps] = useState();
    const [sites, setSites] = useState();
    const [category, setCategory] = useState();

    function fetchJSON() {
        try {
            const get_url = base_url + "app/";
            axios({
                method: "get",
                url: get_url,
                headers: {
                  Authorization: 'Token ' + props.token
                },
            }).then((response) => {
                const assetJSON = response.data;
                console.log('assets', assetJSON)
                setAssets(assetJSON);
                setAllAssets(assetJSON);
                
            });
        } catch (error) {
            console.error(error);
        }
    }

    function handleEditField(e, field) {
      // console.log("handleEditField", field);
      if (field === editField) {
        e.target.classList.remove('on');
        gsap.to(".dropDownField", {opacity: 0, duration: 0.3});
      } else {
        e.target.classList.add('on');
        setEditField(field)
      }
    }

    function handleOnValue(state, value) {
      if (state === value) {
        return " on"
      } else {
        return ""
      }
    }

    function handleKeyPress(e, fieldId, fieldValue) {
      if (e.key === "Enter") {
       
      }
    }

    function filterBy(mode) {
      switch(mode) {
        case "up":
          setAssets(up);
          setCancel(true);
          break;
        case "down":
          setAssets(down);
          setCancel(true);
          break;
        case "dev":
          setAssets(dev);
          setCancel(true);
          break;
        case "apps":
          setAssets(apps);
          setCategory('apps');
          setCancel(true);
          break;
        case "sites":
          setCategory('sites');
          setAssets(sites);
         
          setCancel(true);
          break;
        default:
          setAssets(allAssets);
      }
    }

    function handleGroup(id, name) {
      try {
        const get_url = base_url + "app-group/" + id;
        axios({
            method: "get",
            url: get_url,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
            const assetJSON = response.data;
            console.log('apps', assetJSON)
            setAssets(assetJSON);
            setAllAssets(assetJSON);
            setGroup(name);
            setCancel(true);
            
        });
      } catch (error) {
          console.error(error);
      }
    }

    function handleCancel() {
      fetchJSON();
      setGroup(null);
      setClient(null);
      setCancel(false);
      setCategory();
    }

    useEffect(() => {
      if (props.token) {
        fetchJSON();
      }
    }, [props.token]);
   
    useEffect(() => {
        if (allAssets.length > 0) {
          setLoaded(true)
          let areDev = allAssets.filter(function(obj) { return obj.status === 2; });
          let areUp = allAssets.filter(function(obj) { return obj.status === 1; });
          let areDown = allAssets.filter(function(obj) { return obj.status === 3; });
          let apps = allAssets.filter(function(obj) { return obj.category === 2; });
          let sites = allAssets.filter(function(obj) { return obj.category === 1; });
          setUp(areUp);
          setDown(areDown);
          setDev(areDev);
          setApps(apps);
          setSites(sites);
        }
    }, [allAssets]);

    useEffect(() => {
      if (client) {
        let filtered = assets.filter(function(obj) { return obj.client === client; });
        setAssets(filtered);
      }
    }, [client]);

    useEffect(() => {
      if (editField) {
        gsap.to("#inputField_" + editField, {zIndex: 3, duration: 0});
        gsap.to("#inputField_" + editField, {opacity: 1, duration: 0.3}, "-=0.3");
      } else {
        gsap.to(".dropDownField", {opacity: 0, zIndex: -1, duration: 0});
      }
    }, [editField])

    return (
      <CSSTransition in={loaded} timeout={0} classNames="pageItem" nodeRef={nodeRef}>
        <section className="main assets" ref={nodeRef}>
          <div className="column">
            <div className="filters">
              <div className="siteFilters flex">
               
                <div className="siteFilter btn" onClick={() => filterBy('up')}>
                    <span className="icon"><ArrowUpCircle /></span>
                    <span className="statSm">{up.length}</span>
                </div>
                <div className="siteFilter btn" onClick={() => filterBy('down')}>
                  <span className="icon"><ArrowDownCircle /></span>
                  <span className="statSm">{down.length}</span>
                </div>
                <div className="siteFilter btn" onClick={() => filterBy('dev')}>
                    <span className="icon"><WrenchScrewdriver /></span>
                    <span className="statSm">{dev.length}</span>
                </div>
                <div className="bar"></div>
                <div className={"siteFilter btn " + ((category === 'apps')?"on":"")} onClick={() => filterBy('apps')}>
              
                  <span className="statSm">Apps</span>
                </div>
                <div className={"siteFilter btn "  + ((category === 'sites')?"on":"")} onClick={() => filterBy('sites')}>
                
                <span className="statSm">Sites</span>
              </div>
              <div className="bar"></div>
                <div className="siteFilter btn">
                  <input type="text" className="search" placeholder="Search ..." />
                </div>
                {cancel && <div onClick={() => handleCancel()} className="clearBtn btn"><Clear /></div>}
              </div>
              <div className="groupHeading">
                {group && <h2>{group}</h2>}
                </div>
            </div>
            <div className="mainArray sitesArray">
              {assets.map((item, i) => (
                (!item.is_group) ?
                (<Link 
                  className={"siteBtn status" + item.status} 
                  key={"service_" + i} 
                  to={item.get_absolute_url}
                  >
                  {(item.thumbnail)?<img className="thumb" src={getStatic(item.thumbnail)} alt="" />:<figure className="thumb" alt=""><Placeholder /></figure>}
                  <span className="item name">{item.name}</span>
                  <span className="item client">{item.client_name}</span>
                </Link>):(
                  <span 
                    className={"siteBtn groupBtn btn status" + item.status} 
                    key={"service_" + i} 
                    onClick={() => handleGroup(item.id, item.name)}
                    >
                    {(item.thumbnail)?<img className="thumb" src={getStatic(item.thumbnail)} alt="" />:<figure className="thumb" alt=""><Placeholder /></figure>}
                    <span className="itemGroup">
                     
                      <span className="itemHeading">
                        <span className="item name">{item.name}</span>
                        <span className="item client">{item.client_name}</span>
                      </span>
                      <span className="icon">
                        <Folder />
                      </span>
                    </span>
                  </span>
                )
              ))}
            </div>
          </div>
        </section>
      </CSSTransition>
    );
}

export default Site;


// {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}



