import React, { useState, useEffect, useRef } from "react";
import {
  Link,
  NavLink,
  useParams
} from "react-router-dom";
import { gsap } from "gsap";
import { CSSTransition } from 'react-transition-group';
import { getStatic } from '../static';
import SVG from 'react-inlinesvg';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import axios from "axios";
import DropDown from './module.dropdown.js'
import HtmlText from './module.htmltext.js'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';

import {ReactComponent as Checkmark} from "../static/svg/checkmark.circle.fill.svg";
import {ReactComponent as Pencil} from "../static/svg/square.and.pencil.svg";
import {ReactComponent as Clear} from "../static/svg/clear.svg"
import {ReactComponent as Loader} from "../static/svg/loader.svg";
import {ReactComponent as ChevronBack} from "../static/svg/chevron.backward.svg";
import {ReactComponent as Cancel} from "../static/svg/cancel_icon.svg";
import {ReactComponent as Trash} from "../static/svg/trash.svg"


import imagePlaceholder from '../static/img/image.png'

import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "../constants.js";
if (IS_LIVE) {
  var base_url = LIVE_HOSTS.base_url + '/api/'
} else {
  var base_url = LOCAL_HOSTS.base_url + '/api/'
}


function Tickets(props) {
  
    const [ticket, setTicket] = useState({})
    const [loaded, setLoaded] = useState(false);
    const [client, setClient] = useState();
    const [group, setGroup] = useState();
    const [cancel, setCancel] = useState(false);
    const [category, setCategory] = useState();
    const [dropDown, setDropDown] = useState();
    const [filterVal, setFilterVal] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [editField, setEditField] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const [clients, setClients] = useState([]);
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState(null);
    const [newClient, setNewClient] = useState(null);
    const [newDesc, setNewDesc] = useState(null);
    const [newStatus, setNewStatus] = useState(null);
    const [newStatusNote, setNewStatusNote] = useState(null);
    const [newDateTime, setNewDateTime] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [newProject, setNewProject] = useState(null);
    const [results, setResults] = useState();
    const [showSearch, setShowSearch] = useState(false);

    const nodeRef = React.useRef(null);
    const statusNewRef = React.useRef(null);
    const tempTextObj = useRef(null);
    const userTyped = useRef(null);
    let {id} = useParams();
  
    // const projectForm = useRef();

    function fetchClients() {
      try {
          const get_url = base_url + "client/";
          axios({
              method: "get",
              url: get_url,
              headers: {
                Authorization: 'Token ' + props.token
              },
          }).then((response) => {
              const responseJSON = response.data;
              setClients(responseJSON);
              console.log('clients fetched', responseJSON);
          });
      } catch (error) {
          console.error(error);
      }
    }

    function fetchJSON() {
        try {
          setShowLoader(true);
            const get_url = base_url + "ticket/" + id;
            axios({
                method: "get",
                url: get_url,
                headers: {
                  Authorization: 'Token ' + props.token
                },
            }).then((response) => {
                const assetJSON = response.data;
     
                setTicket(assetJSON);
                // setNewClient(assetJSON.client)
                // setNewCategory(assetJSON.category)
                // setNewDesc(assetJSON.description)

                setShowLoader(false);
                setLoaded(true);
            });
        } catch (error) {
            console.error(error);
        }

        try {
          const get_url = base_url + "team-user/";
          axios({
              method: "get",
              url: get_url,
              headers: {
                Authorization: 'Token ' + props.token
              },
          }).then((response) => {
              const result = response.data;
              // props.setTeamUsers(result);
              console.log('team users', result);
          });
      } catch (error) {
          console.error(error);
      }

      try {
        const get_url = base_url + "ticket-status/";
        axios({
            method: "get",
            url: get_url,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
            const result = response.data;
            setStatuses(result);
        });
      } catch (error) {
          console.error(error);
      }


      try {
        const get_url = base_url + "ticket-categories/";
        axios({
            method: "get",
            url: get_url,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
            const result = response.data;
            setCategories(result)
            // props.setStatuses(result);
        });
      } catch (error) {
          console.error(error);
      }
      
      fetchClients();

    }

    useEffect(() => {
      if (props.token) {
        fetchJSON();
        console.log('ticket props', props);
        
      }
      
    }, [props.token]);




    function animDropDown(open){
      if (open) {
        var tl = gsap.timeline();
        tl.to("#inputField_" + dropDown + " .dropDownChoices", {height: "auto", duration: 0});
        tl.to("#inputField_" + dropDown, {zIndex: 4, height: "auto", duration: 0}, "<");
        tl.to("#inputField_" + dropDown, {scale: 1, opacity: 1, duration: 0.1});
      } else {
        gsap.to(".dropDownChoices", {height: 0, duration: 0});
        gsap.to(".dropDownField", {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
      }
    }

    function handleDropDown(e, field) {
      if (field === dropDown) {
        setDropDown(null); 
      } else {
        setDropDown(field)
      }
    }

    function handleNewStatus(objId) {
      statusNewRef.current = objId;
      setDropDown(null);
    }

    useEffect(() => {
      if (dropDown) {
        animDropDown(true)
      } else {
        animDropDown(false)
      }
    }, [dropDown])

    useEffect(() => {

      console.log('editField', editField)

      if (editField) {
        gsap.to("#inputField_" + editField + " .dropDownChoices", {height: "auto", duration: 0});
        gsap.to("#inputField_" + editField, {zIndex: 3, duration: 0});
        gsap.to("#inputField_" + editField, {opacity: 1, duration: 0.3}, "-=0.3");
       
      
        // document.addEventListener('click', function(e){cancelEditField(e)}, true)
        
      } else {
        gsap.to(".dropDownChoices", {height: 0, duration: 0});
        gsap.to(".dropDownField", {opacity: 0, zIndex: -1, duration: 0});
        // document.removeEventListener('click', function(e){cancelEditField(e)}, true)
       
      }
      
    }, [editField])

    function handleEditField(e, field) {
      // console.log("handleEditField", field);
      if (field === editField) {
        // e.target.classList.remove('on');
        setEditField(null);
        
      } else {
        // e.target.classList.add('on');
        setEditField(field)
      }
    }
    function handleOnValue(state, value) {
      if (state === value) {
        return " on"
      } else {
        return ""
      }
    }

    function handleNewCategory(objId) {
      setNewCategory(objId)
    }

    function handleNewClient(objId) {
      setNewClient(objId)
    }

    function editTicketField(fieldId, fieldValue) {
      let value;
      let name;
      if (fieldValue) {
        value = fieldValue;
        name = fieldId;
      } else {
        value = document.getElementById(fieldId).value;
        name = document.getElementById(fieldId).name;
      }
      try {
        document.getElementById('fieldValue_' + fieldId).classList.remove('on');
      } catch {}
      
      let formData = new FormData();
      // formData['id'] = ticket.id;
      // formData[name] = value;
      formData.append("id", ticket.id)
      formData.append("active", ticket.active)
      // formData.append("ticket_files", [])
      formData.append(name, value)
      console.log(formData);
      try {
        const url = base_url + "ticket-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          fetchJSON();
          setEditField(null);
        });
      } catch (error) {
          console.error(error);
      }
    }

    function handleKeyPress(e, fieldId, fieldValue) {
      if (e.key === "Enter") {
        let value = e.target.value
        editTicketField(fieldId, value)
      }
    }

    useEffect(() => {
      if (newDesc) {
        editTicketField('description', newDesc)
      }
    }, [newDesc])

    useEffect(() => {
      if (newClient) {
        editTicketField('client', newClient)
      }
    }, [newClient])

    useEffect(() => {
      if (newCategory) {
        editTicketField('category', newCategory)
      }
    }, [newCategory])

    function onCommitText(event){
      if (userTyped.current) {
        // props.onTaskText(event, props.obj.id, tempTextObj.current)
      } else {
        setEditField(null)
      }
      
    }

    function deleteTicket() {
      try {
        let formData = new FormData();
        formData.append("id", ticket.id)

        const url = base_url + "ticket-delete/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          window.location.href = "/support"
        });
      } catch (error) {
          console.error(error);
      }
    }

    function onStatus(objId) {
      setNewStatus(objId)
      
    }



    const onDateSelect = (name, date) => {
      let dateFormatted;
      if (date !== null) {
        const datePattern = 'yyyy-MM-dd HH:mm:ssXXX';
        // dateFormatted = format(new Date(date), datePattern, { timeZone: 'America/New_York' });
        dateFormatted = format(new Date(date), datePattern, { timeZone: 'America/New_York' });
      } else {
        dateFormatted = null;
      }
     
      console.log('onChange', name, dateFormatted)
      // editTicketValue(name, dateFormatted);
      setNewDateTime(dateFormatted)
      // setShowDatePicker(false);
    };

    useEffect(() => {
      let l;
      if (showDatePicker !== false) {
        l = document.addEventListener('click', function(event) {  
          if (event.target.closest(".dateFilter")) return 
          setShowDatePicker(false)
        } , true);
        
      } else {
       
        document.removeEventListener("click", l);
      }
      
    }, [showDatePicker])

    function cancelAdd(objId) {
      let form = document.getElementById(objId);
      gsap.to('#' + objId, {opacity: 0, duration: 0.3});
      gsap.to('#' + objId, {zIndex:-1, height: 0, duration: 0, delay: 0.3});
      form.classList.remove('on');
    }

    function startAdd(objId){
      console.log('objid', objId)
      let form = document.getElementById(objId);
  
      if (form.classList.contains('on')) {
        cancelAdd();
      } else {
        gsap.to('#' + objId, {zIndex:4, height: 'auto', duration: 0});
        gsap.to('#' + objId, {opacity: 1, duration: 0.3});
        form.classList.add('on');
      }
      
    }

    function updateTicket() {
      let formData = {};
      formData['id'] = ticket.id;
      formData['update'] = 'true';
      // formData.append('id', ticket.id)
      if (newStatus) {
        formData['status'] = newStatus;
      }
      if (newStatusNote) {
        formData['note'] = newStatusNote;
      }
      if (newDateTime) {
        formData['datetime'] = newDateTime;
      }
      console.log(formData);
      try {
        const url = base_url + "ticket-admin-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          fetchJSON();
          cancelAdd('updateTicketForm')
          setEditField(null);
        });
      } catch (error) {
          console.error(error);
      }
    }

    function createTicket() {

    }

    const getSearchObj = async(pageUrl) => {
    
      await axios({
        method: 'get',
        url: pageUrl
        })
        .then(response => {
          const dataJSON = response.data;
          if (dataJSON) {
            setResults(dataJSON)
          } else {
            setResults(["No results"])
          } 
          
          console.log('results', pageUrl, dataJSON)
        });
    }



    function search(e) {
      let url = base_url + "project-search/"
      let val = e.target.value;
      if (val.length >= 3) {
        let query = "?q=" + val;
        getSearchObj(url + query)
      } else {
        setResults()
      }



    }

    function selectProject(objId, name) {
      // setNewProject([objId, name])
      editTicketField('project', objId)
      setShowSearch(false);
      setResults(null)
    }

    function getFileType(path, i) {
      let obj;
      if (path.split('.').pop() === 'mp4' || path.split('.').pop() === 'webm') {
        obj = <video controls><source src={getStatic(path)} type="video/mp4" key={"file_" + i} /></video>;
      }
      if (path.split('.').pop() === 'jpg' || path.split('.').pop() === 'jpeg' || path.split('.').pop() === 'png' || path.split('.').pop() === 'webp') {
        obj =  <a href={getStatic(path)} target="_blank" rel="noreferrer"><img src={getStatic(path)} alt={path} key={"file_" + i} /></a>
      }
      if (path.split('.').pop() === 'pdf') {
        obj = <a href={getStatic(path)} target="_blank" rel="noreferrer" key={"file_" + i}>{path}</a>
      }
      return obj
    }


    return (
      <React.Fragment>
      {showLoader && <div className="loader"><Loader /></div>}
      

      <CSSTransition  nodeRef={nodeRef} in={loaded} unmountOnExit timeout={200} classNames="pageItem">
        <section className="main ticketDetail" ref={nodeRef}>

        <div className="modalForm" id="addTicketForm">
      <div className="modal">
      <div className="formBody">

          <h2>Create a Request</h2>
          <span className="buttonRow">
          <span className="inputField">
            <input type="text" name="heading" placeholder="Title" autoComplete="off" id="newHeading" />
            
          </span>

          

            <DropDown 
              onClick={handleNewCategory}
              objs={categories}
              active={newCategory}
              label="Category"
            />
            
            <DropDown 
              onClick={handleNewClient}
              objs={clients}
              active={newClient}
              label="Client"
            />

            <HtmlText 
              obj={newDesc}
              label="Description"
              onNewObj={setNewDesc}
            />
        
          </span>
         
          </div>
          <span className="buttonRow nextRow">
          <div className="btn" onClick={() => cancelAdd('addTicketForm')}>Cancel</div>
          <div className="btn save" onClick={() => createTicket()}>Save</div>
        
        </span>
      </div>
    </div>
       
    <div className="modalForm modalForm2" id="updateTicketForm">
    <div className="modal">
    <div className="formBody">

        <h2>Update Request</h2>
        <span className="buttonRow">
     

        <DropDown 
        onClick={onStatus}
        objs={statuses}
        active={newStatus}
        label="Status"
     
      />


        <span className="btn">
   
          <span className="field btn dateField" onClick={() => setShowDatePicker('start_date')}>{newDateTime ? format(new Date(newDateTime), 'yyyy-MM-dd HH:mm:ss zzz', { timeZone: 'America/New_York' }) : <span className="fieldCta">Add  Date and Time</span>}</span>
          {(showDatePicker === 'start_date') &&  <div className=""><div className="dateFilter">
          <DatePicker
          selected={newDateTime ? new Date(zonedTimeToUtc(newDateTime, Intl.DateTimeFormat().resolvedOptions().timeZone)) : ticket.datetime && new Date(zonedTimeToUtc(ticket.datetime, Intl.DateTimeFormat().resolvedOptions().timeZone))}
          onChange={(date) => onDateSelect('datetime', date)}
          // onSelect={(date) => onDateSelect('datetime', date)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="time"
          
          // dateFormat="MMMM d, yyyy h:mm aa"
          inline
          /> 
          </div></div>}
        </span>

          <HtmlText 
            obj={newStatusNote}
            label="Description"
            onNewObj={setNewStatusNote}
          />
      
        </span>
       
        </div>
        <span className="buttonRow nextRow">
        <div className="btn" onClick={() => cancelAdd('updateTicketForm')}>Cancel</div>
        <div className="btn save" onClick={() => updateTicket()}>Save</div>
      
      </span>
    </div>
  </div>


          <div className="column detailColumns" id="ticketDetail">

              <div className={"leftColumn"}>

                <div className="backBtn"><a href="/support"><ChevronBack className="icon btn" /></a></div>

                  <span className="ticketForm">

                  <h2>
                  {(editField === "name") ? 
                  <span className="inputField"><input onKeyUp={(e) => handleKeyPress(e, "heading", null)} type="text" defaultValue={ticket.heading} name="name" id="name" />
                  <span className="smCancel btn" onClick={() => setEditField(null)}><Cancel/></span>
                </span> : <span className="fieldValue" onClick={() => setEditField("name")}>{ticket.heading}</span>}
                  </h2>

                  <div className="projectField">
                    <span className="heading">Status</span>
                    <span>
                      <span className={"statusName " + ticket.status_slug}>{ticket.status}</span>
                    </span>
                  </div>


               {ticket.app_obj && <div className="projectField">
                    <span className="heading">Site</span>
                    <span>
                      <a href={'/assets/' + ticket.app_obj.id}>{ticket.app_obj.name}</a>
                    </span>
                  </div>}


                  {!ticket.app_obj && <div className="projectField">
                  <span className="heading">Client</span>
                  <span className="field">
                  <DropDown 
                    onClick={handleNewClient}
                    objs={clients}
                    active={ticket.client}
                    label="Client"
                    offsetY={-20}
                  />
                  </span>
                </div>}
                {!ticket.app_obj && 
                <div className="projectField searchField">
                  <span className="heading">Project</span>
                  <span className="field">
                  {!ticket.project || showSearch ? <span className={`formSearch${results ? " hasResults" : ""}`}>
                  <input type="text" placeholder="Project" onKeyUp={(e) => search(e) } />
                 
                      {results && (results.length > 0) && <span className="results">
                        {results.map((obj, i) => (
                          <span key={"result_" + i} className="result ui" onClick={() => selectProject(obj.id, obj.name)}>{obj.name}</span>
                        ))}
                      </span>}
                      {results && results.length === 0 && <span className="results">
                        <h3>No results found</h3>
                      </span>}
                    </span> : 
                    <span className="inputField ui" onClick={() => {setShowSearch(true); setResults(null)}}>
                          {ticket.project_name}
                    
                  </span>
                  }
                  </span>
                </div>}


                

             
                  
                    <div className="projectField">
                    <span className="heading">Category</span>
                    <span className="field">
                    <DropDown 
                    onClick={handleNewCategory}
                    objs={categories}
                    active={ticket.category}
                    label="Category"
                    offsetY={-20}
                  />
   
                        </span>
                    </div>

                   {!ticket.datetime_end && <div className="projectField">
                  <span className="heading">Scheduled</span>
                  <span className="field">
                    {ticket.datetime ? format(new Date(zonedTimeToUtc(ticket.timestamp, Intl.DateTimeFormat().resolvedOptions().timeZone)), "MMM d, Y, h:mm a") : <span className="fieldCta">—</span>}
                      </span>
                    </div>}

                    {ticket.datetime_end && <div className="projectField">
                      <span className="heading">Scheduled</span>
                      <span className="field">
                      {format(new Date(zonedTimeToUtc(ticket.datetime, Intl.DateTimeFormat().resolvedOptions().timeZone)), "MMM d, Y, h:mm a")} - {format(new Date(zonedTimeToUtc(ticket.datetime_end, Intl.DateTimeFormat().resolvedOptions().timeZone)), "MMM d, Y, h:mm a")}

                       
                          </span>
                        </div>}
    
                    
                  <span className="ticketDescription">
                      <HtmlText 
                      obj={ticket.description}
                      label="Description"
                      onNewObj={setNewDesc}
                    />
                  </span>



                      
                  </span>

                 


                  {ticket.files && ticket.files.length > 0 && <span className="ticketFiles">
                  <h2>Files</h2>
                    {ticket.files.map((obj, i) => (
                      
                      
                      getFileType(obj.file, i)
                      
                      
                    ))}
                    </span>}
        
              
         
              
                </div>
                      
              <div className="rightColumn">
        
              <h2>History</h2>
              <div className="historyRows">
                {ticket.ticketlogs && ticket.ticketlogs.map((log, h) => (
                  <span key={"log_" + h} className="historyRow">
                    <span className="logDate">{format(new Date(zonedTimeToUtc(log.timestamp, Intl.DateTimeFormat().resolvedOptions().timeZone)), "MMM d, Y, h:mm a")}</span>
                    <span className="logItem">
                       <strong>{log.username}</strong> 
                        {log.actions && 
                          <ul>
                          {log.actions.map((action, i) => (
                          <li key={'action_' + i}>
                            {action}
                          </li>
                          ))} 
                          </ul>}

                    </span>
                    {log.note && <span className="logNote" ><h3>Note</h3><span dangerouslySetInnerHTML={{__html:log.note}}></span></span>
                  }
                  </span>

                ))}
                </div>
      
              </div>
              

          </div>
                      
          <span className="ticketRow ticketBtnRow">

                              

          {props.userId && props.userId === 2 && 
            <span className="column flex adminBtns">

           

            <span className="btn">
              <span className="fieldCta adminCta" onClick={() => startAdd('updateTicketForm')}>Update Request</span>
            </span>
        

           

            </span>
          }

          <div className="btn userBtn deleteBtn" onClick={() => deleteTicket()}>
          <Trash />
        </div>

        </span>
          
        </section>
      </CSSTransition>
      </React.Fragment>
    );
}

export default Tickets;
