import React, { useState, useEffect } from "react";
import gsap from "gsap";
import style from '../static/css/contextMenu.css';

function ContextMenu(props) {
    const [contextBtns, setContextBtns] = useState([])


    useEffect(() => {
        if (props.onContext) {

            setContextBtns(props.contextBtns)


            setTimeout(() => {
            //   console.log('e', props)
              gsap.to(".contextMenu", {zIndex: 3, x: props.onContext.x - 30, y: props.onContext.y - 120, duration: 0});
              gsap.to(".contextMenu", {opacity: 1, duration: 0.3}, "-=0.3");
              let listener = window.addEventListener('click', function(){
                // props.setShowContextMenu(false);
                gsap.to(".contextMenu", {opacity: 0, zIndex: -1, duration: 0});
              })
              window.removeEventListener('click', listener); 
            }, 100);
        } else {
            gsap.to(".contextMenu", {opacity: 0, zIndex: -1, duration: 0});
        }
    }, [props.onContext])

    return (
            <div className={"contextMenu"}>
               

                {contextBtns && contextBtns.map((obj, i) => (
                  
                    <span key={'context_' + i} className={obj.text?"contextBtn":"break"} onClick={() => obj.text && obj.action()}>{obj.text && obj.text}</span>
                ))}



            </div>
        )

}

export default ContextMenu;