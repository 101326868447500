export const IS_LIVE = true;
export const HOST_NAME = window.location.hostname;
// console.log('hostname', HOST_NAME);
export const LIVE_HOSTS = {
    base_url: "https://cdn.origin.track.udxd.co"
}

export const LOCAL_HOSTS = {
    base_url: "http://localhost:8000"
}

// export const HOSTS