import React, { useState, useEffect, Fragment } from "react";
import { CSSTransition } from 'react-transition-group';
import {
  useParams,
  useNavigate
} from "react-router-dom";
import axios from "axios";
import gsap from "gsap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';
import SVG from 'react-inlinesvg';
import ContextMenu from './module.context_menu';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// import { DndProvider } from 'react-dnd'
// import { HTML5Backend } from 'react-dnd-html5-backend'
// import { TouchBackend } from 'react-dnd-touch-backend'

import Task from './app.project_task.js'

import { getStatic } from "../static";
import {ReactComponent as Cancel} from "../static/svg/cancel_icon.svg";
import {ReactComponent as Checkmark} from "../static/svg/checkmark.circle.fill.svg";
import {ReactComponent as ChevronBack} from "../static/svg/chevron.backward.svg";
import {ReactComponent as Plus} from "../static/svg/plus.svg";
import {ReactComponent as Trash} from "../static/svg/trash.svg";
import {ReactComponent as Loader} from "../static/svg/loader.svg";
import {ReactComponent as Clear} from "../static/svg/clear.svg";
import {ReactComponent as Tag} from "../static/svg/tag.svg";
import {ReactComponent as Star} from "../static/svg/star.fill.svg";


import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "../constants.js";
if (IS_LIVE) {
  var base_url = LIVE_HOSTS.base_url + '/api/'
} else {
  var base_url = LOCAL_HOSTS.base_url + '/api/'
}

function ProjectDetail(props) {
  const nodeRef = React.useRef(null);
  const tempTextObj = React.useRef(null);
  const tempOwnerId = React.useRef(null);
  const windowlistener = React.useRef();
  const dropDown = React.useRef();
  const labelList = React.useRef([]);

   let {id, subId} = useParams();
   let navigate = useNavigate();

    const [subProject, setSubProject] = useState();
    const [tasks, setTasks] = useState([])
    const [loaded, setLoaded] = useState(false);
    const [editField, setEditField] = useState(null);
    const [showTaskForm, setShowTaskForm] = useState();
    const [showPeopleForm, setShowPeopleForm] = useState(false);
    const [peopleFormObj, setPeopleFormObj] = useState();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showLabelForm, setShowLabelForm] = useState(false);
    const [labelFormObj, setLabelFormObj] = useState();
    const [touchDevice, setTouchDevice ] =useState(false);
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [statusFilter, setStatusFilter] = useState(false);
    const [labelFilter, setLabelFilter] = useState(false);


    function handleOnValue(state, value) {
      if (state === value) {
        return " on"
      } else {
        return ""
      }
    }

    function handleEditField(e, field) {
      // console.log("handleEditField", field);
      if (field === editField) {
        setEditField(null)
        e.target.classList.remove('on');
        gsap.to(".dropDownField", {opacity: 0, duration: 0.3});
      } else {
        e.target.classList.add('on');
        setEditField(field)
      }
    }



    function fetchJSON() {
      console.log('fetchJSON')
      try {
          const get_url = base_url + "subproject/" + props.subId;
          axios({
              method: "get",
              url: get_url,
              headers: {
                Authorization: 'Token ' + props.token
              },
          }).then((response) => {
              const assetJSON = response.data;
              if (assetJSON) {
                setSubProject(assetJSON);
                setTasks(assetJSON.tasks.sort((a, b) => a.status_order-b.status_order))
                // tempTextObj.current = null;
      
                console.log('subproject fetched', assetJSON.tasks);
              }
             
          });
      } catch (error) {
          console.error(error);
      }
  }

  function cancelEditField(e) {
    console.log('cancel', e.target.id, editField)
    if (e.target.id !== editField) {
      setEditField(null)
      document.removeEventListener('click', function(e){cancelEditField(e)}, true)
    }
  }

  function clearContext(e) {
    if (e.key === "Escape") {
      setShowContextMenu(false);
      setEditField(null)
    }
    
  }

    useEffect(() => {
      if (props.token) {
        console.log('project detail sub props', props)
        fetchJSON();

        function is_touch_enabled() {
          return ( 'ontouchstart' in window ) || 
                 ( navigator.maxTouchPoints > 0 ) || 
                 ( navigator.msMaxTouchPoints > 0 );
        }
        let touch = is_touch_enabled();
        console.log('touch', touch)
        setTouchDevice(touch);

        

        document.addEventListener('keydown', function(e) { clearContext(e) })

      } else {
        document.removeEventListener('keydown', function(e) { clearContext(e) })
      }

      
    }, [props.token]);


    useEffect(() => {
      if (subProject) {
        setLoaded(true);
        
        
      }
    }, [subProject])


    useEffect(() => {

      console.log('editField', editField)

      if (editField) {
        gsap.to("#inputField_" + editField + " .dropDownChoices", {height: "auto", duration: 0});
        gsap.to("#inputField_" + editField, {zIndex: 3, duration: 0});
        gsap.to("#inputField_" + editField, {opacity: 1, duration: 0.3}, "-=0.3");
       
      
        // document.addEventListener('click', function(e){cancelEditField(e)}, true)
        
      } else {
        gsap.to(".dropDownChoices", {height: 0, duration: 0});
        gsap.to(".dropDownField", {opacity: 0, zIndex: -1, duration: 0});
        // document.removeEventListener('click', function(e){cancelEditField(e)}, true)
       
      }
      
    }, [editField])


    useEffect(() => {
      if (showTaskForm) {
        let form = document.getElementById('addTaskForm');
        gsap.to('#addTaskForm', {zIndex:3, height: 'auto', duration: 0});
        gsap.to('#addTaskForm', {opacity: 1, duration: 0.3});
        form.classList.add('on');
      }
    }, [showTaskForm])

    function editText(e, editField){

      function setCursorPosition(e) {
          const input = document.getElementById(editField);
          if (input) {
            // const rect = input.getBoundingClientRect();
            const x = e.clientX - 60;
            const y = e.clientY;

            let length =input.value.length
            let width = length * 6
            let perc = x/width;
          
            let offset = parseInt(length*perc);
            // console.log('e', width, perc, length, offset, x)
            input.setSelectionRange(offset, offset);
            input.focus();
          }
      }
      
    

      setEditField(editField)
      setTimeout(() => {
        setCursorPosition(e)
    
      }, 200);
      
    }
    

    function editProjectField(fieldId, fieldValue) {
      let value;
      let name;
      if (fieldValue) {
        value = fieldValue;
        name = fieldId;
      } else if (fieldId === "description") {
        name = fieldId;
        value = fieldValue;
        console.log('edit description', name, value)
      }else {
        if (document.getElementById(fieldId)) {
          value = document.getElementById(fieldId).value;
          name = document.getElementById(fieldId).name;
        }
        
      }
      try {
        document.getElementById('fieldValue_' + fieldId).classList.remove('on');
      } catch {}
      
      if (value || value === "") {
        let formData = {};
        formData['id'] = subProject.id;
        formData['project'] = subProject.project
        formData[name] = value;
        console.log(formData);
        try {
          const url = base_url + "subproject-edit/";
          axios({
              method: "post",
              url: url,
              data: formData,
              headers: {
                Authorization: 'Token ' + props.token
              },
          }).then((response) => {
            fetchJSON();
            setEditField(null);
            // props.setReload(true);
          });
        } catch (error) {
            console.error(error);
        }
      } else {
        setEditField(null);
      }
     
    }

    function editTaskField(fieldId, fieldValue, taskId, noFetch) {
     
      console.log('editTaskField', fieldId, fieldValue, taskId)
      let value;
      let name;
      if (fieldValue) {
        value = fieldValue;
        name = fieldId;
      } else if (fieldId === "parent" || fieldId === "description") {
        value = fieldValue
        name = fieldId
        console.log('name, value', name, value)
      } else {
        value = document.getElementById(fieldId).value;
        name = document.getElementById(fieldId).name;
      }
      try {
        document.getElementById('fieldValue_' + fieldId).classList.remove('on');
      } catch {}
      
      let formData = {};
      formData['id'] = taskId;
      formData['subproject'] = subProject.id
      formData[name] = value;
      console.log(formData);
    
      // console.log('id, sub', taskId, subProject.project)
      try {
        const url = base_url + "task-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
         console.log('task-edit response', response)
         if (!noFetch) {
          fetchJSON();
         }
         
         setEditField(null);
          
          // props.setReload(true);
        });
      } catch (error) {
          console.error(error);
      }
    }

    

    function handleKeyPress(e, fieldId, fieldValue) {
      if (e.key === "Enter") {
        editProjectField(fieldId, fieldValue)
      } else if (e === "click") {
        editProjectField(fieldId, fieldValue)
    
      } else if (e.key === "Delete") {
        console.log('test')
    
      } else {
        
        // pass
      }


    }

    function handleTextEdit(fieldId) {
      
      console.log('handleTextEdit', tempTextObj.current);
      if (tempTextObj.current) {
        editProjectField(fieldId, tempTextObj.current)
        
      } else if (tempTextObj.current === "") {

        editProjectField(fieldId, "")
      } else {
        // pass
      }
      tempTextObj.current = null;
    }

    function onListTab(e, obj, dest) {
    
      let activeId;
      if (editField) {
        activeId = editField.replace('task_name_', '')
        // console.log('onListTab', e, editField, obj.id)
        if (obj.id === Number(activeId)) {
  
          if (e.key === "Tab" && !e.shiftKey) {
            console.log('Tab', obj, dest)
            if (dest) {
              if (obj.subs.length === 0) {
                editTaskField('parent', dest.id, obj.id)
                console.log('test', dest.id, obj.id)
              }
              
            }
  
          }
  
          if (e.key === "Tab" && e.shiftKey) {
            console.log('Shift Tab', obj, dest)
            if (obj.parent) {
            
                editTaskField('parent', null, obj.id)
                console.log('parent', dest, obj)
              
              
            }
  
          }
        }
      }
     

       

    }

    function onTaskText(e, taskId, text) {
      console.log('onTsk', e, tempTextObj.current)
      if (tempTextObj.current) {
        editTaskField('description', tempTextObj.current, taskId)
      } else if (text) {
        console.log('text, taskId', text, taskId)
        editTaskField('description', text, taskId)
      } else {
        let taskObj = tasks.find(a => a.id === taskId)
        if (taskObj.description) {
          editTaskField('description', null, taskId)
        } else {
          setEditField(null)
        }
        
      }
      
    }
    function deleteTask(e, itemId) {
      console.log('deleteTask', itemId);
      if (e) {
        e.preventDefault(); 
      }
      
      
      setLoaded(false);
      let formData = {};
      formData['id'] = itemId;
      try {
        const url = base_url + "task-delete/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
        
          fetchJSON();
          setEditField(null);

        });
      } catch (error) {
          console.error(error);
      }
    }

    function handleTaskKeyPress(e, fieldId, fieldValue, taskId) {
      let key;
      if (e === 'click') {
        key = 'click'
      } else {
        key = e.key;
      }

      switch (key) {
        case "Enter":
         
          editTaskField(fieldId, fieldValue, taskId)
          break;
        case "click":
          editTaskField(fieldId, fieldValue, taskId)
          console.log('handleTaskKeyPress', "click")
          break;
        case "Backspace":
          let elem = document.getElementById(fieldId);
          // console.log(e, fieldId, value, taskId)
          if (elem.value === "") {

            function removeTask() {
              document.getElementById("taskRow_" + taskId).remove();
            }
            gsap.to("#taskRow_" + taskId, {opacity: 0, height: 0, duration: 0.2, onComplete: removeTask})

          
             
              deleteTask(e, taskId)
            }
          break;
        default:
          // pass
          break;
    
      
      }
    

    }

    function handleTaskClick(e, fieldId, fieldValue, taskId) {
      editTaskField(fieldId, fieldValue, taskId)
    }

    function addTask(name, isBug) {
      console.log('addTask', id);
      setLoaded(false);
      let formData = {};
      formData['name'] = name;
      formData['subproject'] = subProject.id;
      formData['owner_django_id'] = props.userId
      if (isBug) {
        formData['description'] = tempTextObj.current
        formData['label_ids'] = [2]
      }
      // setSubProject(null);
      // setTasks([])
      try {
        const url = base_url + "task-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          setEditField(null);
         
          setShowTaskForm(false);
          tempTextObj.current = null;

          setTimeout(() => {
            fetchJSON();
          }, 100);
          // props.setReload(true);

        });
      } catch (error) {
          console.error(error);
      }
    }

   

    function deleteSubProject(e) {
      console.log('deleteTask', subProject.id);
      
      setLoaded(false);
      let formData = {};
      formData['id'] = subProject.id;
      try {
        const url = base_url + "subproject-delete/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          props.setSubProject('reload');
          setEditField(null);
          // props.setReload(true);
        });
      } catch (error) {
          console.error(error);
      }
    }

    function goBack() {
      // navigate("/projects/" + id);
      props.setSubProject('reload');
    }

    function createBlock(taskId, e) {
      let name = document.getElementById(taskId).value;
      if (e === "save") {
        addTask(name, true);
      } else {
        if (e.key === "Enter") {
          
          addTask(name);
        }
      }
     
    }
    

    useEffect(() => {
      if (showPeopleForm && peopleFormObj) {
        let form = document.getElementById('addPeopleForm');
        if (form) {
          let btns = form.querySelectorAll('.button');
          btns.forEach((obj, i) => {
            let id = parseInt(obj.dataset.id);
            let exists = peopleFormObj['people'].some(obj0 => { if (obj0 === id) { return true } return false})
            // console.log('id', id, exists, peopleFormObj['people'])
            exists && obj.parentElement.classList.add('on'); 
            let owner;
            if (id === peopleFormObj['owner']) { owner = true } else {owner = false};
            (owner === true) && obj.parentElement.classList.add('owner'); 
          })
          
  
          gsap.to('#addPeopleForm', {zIndex:4, height: 'auto', duration: 0});
          gsap.to('#addPeopleForm', {opacity: 1, duration: 0.3});
          form.classList.add('on');
          console.log('props.teamUsers',peopleFormObj['owner'])
        }
       
      }
    }, [showPeopleForm, peopleFormObj])


    useEffect(() => {
      if (showLabelForm && labelFormObj) {
        let form = document.getElementById('addLabelForm');
        if (form) {
          // console.log("labelFormObj", labelFormObj)
          let btns = form.querySelectorAll('.button');
          btns.forEach((obj, i) => {
            let id = parseInt(obj.dataset.id);
            let exists = labelFormObj['labels'].some(obj0 => { if (obj0 === id) { return true } return false})
            // console.log(id, exists, labelFormObj['labels'])
            exists && obj.classList.add('on'); 
          })
          gsap.to('#addLabelForm', {zIndex:4, height: 'auto', duration: 0});
          gsap.to('#addLabelForm', {opacity: 1, duration: 0.3});
          form.classList.add('on');
        }
       
      }
    }, [showLabelForm, labelFormObj])

    function handleLabelForm(name, list, objId) {
      console.log('handleLabelForm', name, list, objId)
      let ids = []
      list.forEach((obj, i) => {
        ids.push(obj.id)
      });
      labelList.current = ids
      setLabelFormObj({"form": name, "labels": ids, 'id': objId});
      setShowLabelForm(true);

      
    }

    function handlePeopleForm(name, list, objId, ownerId) {
      // console.log('handlePeopleForm', name, list, ownerId, tempOwnerId.current)
      setPeopleFormObj({"form": name, "people": list, "objId": objId, "owner": ownerId});
      setShowPeopleForm(true);
      // tempOwnerId.current = ownerId;
      
  }

    function handleOwner(e, objId) {
      if (e.target.classList.contains('on')) {
        e.target.classList.remove('on');
      } else {
        let stars = document.getElementsByClassName('star');
        for (var i = 0; i < stars.length; i++) {
          stars[i].classList.remove('on');
        }
        e.target.classList.add('on');
        let owners = document.getElementsByClassName('owner');
        for (var j = 0; j < owners.length; j++) {
          owners[j].classList.remove('owner');
        }
        e.target.parentElement.classList.add('owner');
        let currForm = peopleFormObj;
        currForm['owner'] = objId
        setPeopleFormObj(currForm)
      }
    }


    function handleTeamUser(e, obj) {
      console.log('peopleFormObj', peopleFormObj);
      let formObj = peopleFormObj;
      let teamList = [ ...formObj['people'] ];

      const index = teamList.findIndex(object => {
        return object.id === obj.id;
      });
      if (e.target.parentElement.classList.contains('on')) {
        teamList.splice(index, 1);
        e.target.parentElement.classList.remove('on');
      } else {
        teamList.push(obj['id']);
        e.target.parentElement.classList.add('on');
      }
      formObj['people'] = teamList;
      setPeopleFormObj(formObj);

      // console.log('formObj', formObj);
    }

    function handleSavePeople() {

      let form = document.getElementById('addPeopleForm');
      gsap.to('#addPeopleForm', {opacity: 0, duration: 0.3});
      gsap.to('#addPeopleForm', {zIndex:-1, height: 0, duration: 0, delay: 0.3});
      form.classList.remove('on');

      let teamusers_id = [];

      peopleFormObj['people'].forEach((obj, i) => {
        teamusers_id.push(obj)
      })
      
      let formData = {};
     
     
      formData['teamuser_ids'] = teamusers_id;

      console.log("teamusers_id", teamusers_id);

      let postUrl;

      if (peopleFormObj['form'] === "subProject") {
        postUrl = base_url + "subproject-edit/";
        formData['id'] = subProject.id;
        formData['project'] = subProject.project;
      } 
      
      if (peopleFormObj['form'] === "task") {
        formData['id'] = peopleFormObj['objId'];
        formData['subproject'] = subProject.id
        postUrl = base_url + "task-edit/";
      } 

      if (peopleFormObj['owner']) {
        formData['owner'] = peopleFormObj['owner'];
        console.log('owner', peopleFormObj['owner'])
      }

      console.log('postUrl', postUrl, peopleFormObj)

      try {
        const url = postUrl;
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          fetchJSON();
          setEditField(null);
        });
      } catch (error) {
          console.error(error.response.data);
      }

      setShowPeopleForm(false);
    }

    function handleLabelTag(e, obj) {
     
      let formObj = labelFormObj;
      let newLabels = [ ...labelList.current ];
      const index = newLabels.findIndex(a => a === obj.id);
      console.log('handleLabelTag', obj.id, newLabels, index);

      if (index > -1) {
        newLabels.splice(index, 1);
        e.target.classList.remove('on');
      } else {
        newLabels.push(obj.id);
        e.target.classList.add('on');
      }
      formObj['labels'] = newLabels;
      labelList.current = newLabels;
      setLabelFormObj(formObj);

      console.log('formObj', newLabels, formObj, index);
     
    }

    function handleSaveLabel() {
      let form = document.getElementById('addLabelForm');
      gsap.to('#addLabelForm', {opacity: 0, duration: 0.3});
      gsap.to('#addLabelForm', {zIndex:-1, height: 0, duration: 0, delay: 0.3});
      form.classList.remove('on');

      let label_ids = [];

      labelFormObj['labels'].forEach((obj, i) => {
        label_ids.push(obj)
      })
      
      let formData = {};
     
     
      formData['label_ids'] = label_ids;

      console.log("label_ids", label_ids);

      let postUrl;

      if (labelFormObj['form'] === "subProject") {
        postUrl = base_url + "subproject-edit/";
        formData['id'] = subProject.id;
        formData['project'] = subProject.project;
      } 
      
      if (labelFormObj['form'] === "task") {
        formData['id'] = labelFormObj['id'];
        formData['subproject'] = subProject.id
        postUrl = base_url + "task-edit/";
      } 

      console.log('postUrl', postUrl, labelFormObj)

      try {
        const url = postUrl;
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          fetchJSON();
          setEditField(null);
        });
      } catch (error) {
          console.error(error.response.data);
      }

      setShowLabelForm(false);
    }


    function editProjectValue(name, value) {
      let formData = {};
      formData['id'] = subProject.id;
      formData['project'] = subProject.project;
      formData[name] = value;
      console.log(formData);
      try {
        const url = base_url + "subproject-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          fetchJSON();
          setEditField(null);
        });
      } catch (error) {
          console.error(error);
      }

    }


    function editTaskValue(name, value, taskId) {
      let formData = {};
      formData['id'] = taskId;
      formData['subproject'] = subProject.id;
      formData[name] = value;
      console.log(formData);
      try {
        const url = base_url + "task-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          fetchJSON();
          setEditField(null);
        });
      } catch (error) {
          console.error(error);
      }

    }

    const onDateSelect = (form, name, date, taskId) => {
      let dateFormatted;
      if (date !== null) {
        const datePattern = 'yyyy-MM-dd';
        dateFormatted = format(new Date(date), datePattern);
      } else {
        dateFormatted = null;
      }
     
      // console.log('onChange', name, dateFormatted)
      setShowDatePicker(false);

      if (form === "subProject") {
        editProjectValue(name, dateFormatted);
      }

      if (form === "task") {
        editTaskValue(name, dateFormatted, taskId);
      }
    };

    useEffect(() => {
      let l;
      if (showDatePicker !== false) {
        l = document.addEventListener('click', function(event) {  
          if (event.target.closest(".dateFilter")) return 
          setShowDatePicker(false)
        } , true);
        
      } else {
       
        document.removeEventListener("click", l);
      }
      
    }, [showDatePicker])

   

    function onCheck(fieldId, fieldValue, taskId, subId) {

      console.log('onCheck',taskId)
      let tasksCopy = [...tasks];
      let task = tasksCopy.find(a => a.id === taskId)
      if (subId) {
        task = task.subs.find(b => b.id === subId)
        taskId = subId
      }

  
      

    //  console.log('task', task)
      // console.log('onCheck', fieldValue)
      switch (fieldValue) {
        case 1: 
          document.getElementById('check_' + taskId).classList.add('checked');
          // console.log(document.getElementById('check_' + taskId))
          task.status = 2
          editTaskField(fieldId, 2, taskId, true)
          break;
        case 2: 
          document.getElementById('check_' + taskId).classList.remove('checked');
          task.status = 1
          editTaskField(fieldId, 1, taskId, true)

          break;
        default:
          break;
      }
      setTasks(tasksCopy);
    
      if (!subId) {
        let copy = [ ...tasksCopy ];
        setTimeout(() => {
          setTasks(copy.sort((a, b) => a.status-b.status || b.id-a.id))
        }, 200);
      }
      
    }
    function onStatusChange(statusId, taskObj) {
      let taskId = taskObj.id
      let tasksCopy = [...tasks];
      let task = tasksCopy.find(a => a.id === taskId)
      if (task) {
        task.status = statusId
        switch (statusId) {
          case 1:
            document.getElementById('check_' + task.id).classList.remove('checked');
            break;
          case 2:
              document.getElementById('check_' + task.id).classList.remove('checked');
              break;
          default:
            break;
        }
        editTaskField('status', statusId, showContextMenu.obj.id, true)

        let subId = null;
        if (task.parent) {
          subId = task.id
        }
        let tasksCopy = [...tasks];
        setTasks(tasksCopy);
    
        if (!subId) {
          let copy = [ ...tasksCopy ];
          console.log('copy')
          setTimeout(() => {
            setTasks(copy.sort((a, b) => a.status_order-b.status_order || b.id-a.id))
          }, 200);
        }
        
      }
      
    }
    const contextBtns = [
      {text: "In Progress", action: () => onStatusChange(1, showContextMenu.obj)},
      {text: "Completed", action: () => onStatusChange(2, showContextMenu.obj)},
      {text: "Unread", action: () => onStatusChange(8, showContextMenu.obj)},
      {text: "Blocked", action: () => onStatusChange(3, showContextMenu.obj)},
      {text: "Paused", action: () => onStatusChange(9, showContextMenu.obj)},
      {text:null},
      {text: "Delete", action: () => deleteTask(null, showContextMenu.obj.id)}
    ]

  
      function onModuleContext(e, obj) {
        let app = document.querySelector('.app');
        // console.log('onModuleContext', e, e.clientY, app.scrollTop)
        e.preventDefault();

        // let obj = modules.find(a => a.id === id)
        setShowContextMenu({obj: obj, x: e.clientX + window.scrollX, y: (e.clientY + app.scrollTop)})
      }

      function clearDropDown(){
        gsap.to(".dropDownChoices", {height: 0, duration: 0});
          gsap.to(".dropDownField", {opacity: 0, zIndex: -1, duration: 0});
          dropDown.current = null
      }
  
      function onFilter(type, obj) {
        if (type === 'status') {
          setStatusFilter(obj)
        } 
        if (type === 'label') {
          setLabelFilter(obj)
        } 
        clearDropDown()
      }
      
      function showFilter(objSlug) {
        if (dropDown.current !== objSlug) {
          clearDropDown()
          gsap.to("#filter_" + objSlug + " .dropDownChoices", {height: "auto", duration: 0});
          gsap.to("#filter_" + objSlug, {zIndex: 3, duration: 0});
          gsap.to("#filter_" + objSlug, {opacity: 1, duration: 0.3}, "-=0.3");
          dropDown.current = objSlug
          
        } else {
          clearDropDown()
        }
          
         
        
      }
      function clearFilters() {
        setStatusFilter(null)
        setLabelFilter(null)
        clearDropDown()
      }

      const bugTemplate = "<span><p><strong>Describe the Bug You Experienced:</strong></p><br/><p><strong>Steps to Reproduce:</strong></p><br/><p><strong>Expected Behavior:</strong><p /><br/><p><strong>Link(s) to the Example:</strong></p><br/><p><strong>Link(s) to images or video:</strong></p></span>"

    return (
      <CSSTransition in={loaded} timeout={200} classNames="pageItem" nodeRef={nodeRef}>
      <section className="cover" ref={nodeRef}>
      <ContextMenu 
        contextBtns={contextBtns}
        onContext={showContextMenu}
        setShowContextMenu={setShowContextMenu}
      />
      

      {showTaskForm && 
        
        ((subProject.labels.findIndex(a => a.id === 2) === 0) ? 

        <div className="userForm modalForm bugForm" id="addTaskForm">
          <div className="modal">
          <div className="formBody">
            
              <h2>Create a Task</h2>
                <span className="inputField">
                <input type="text" name="name" placeholder="Enter a one sentence summary of the issue" autoComplete="off" id="taskName" />
                <span style={{fontSize: "12px", color: "#666", marginTop: '10px', float: "left", display: "none"}}></span>
                </span>

                <span className="text taskTextForm">

                  <CKEditor
                      editor={ ClassicEditor }
                      data={bugTemplate}
           
                      config={{
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
                        link: { addTargetToExternalLinks: true }
                      }}
                      onReady={() => {
                        tempTextObj.current = bugTemplate;
                      }}
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          console.log( { event, editor, data } );
                          tempTextObj.current = data;
                      } }
                  />
              </span>
          </div>
          <span className="buttonRow nextRow">
            <div className="btn" onClick={() => setShowTaskForm(null)}>Cancel</div>
            <div className="btn save" onClick={() => createBlock('taskName', 'save')}>Save</div>
          
          </span>
          </div>
        </div> : 

        <div className="userForm modalForm" id="addTaskForm">
        <div className="modal">
        <div className="formBody">
          <div className="formField">
            <h2>Create a Task</h2>
            <span className="inputField">
              <input type="text" name="name" placeholder="Name" onKeyUp={(e) => createBlock('taskName', e)} autoComplete="off" id="taskName" />
              <span className="smCancel btn" onClick={() => setShowTaskForm(null)}><Clear /></span>

        
                
                
            
            </span>

          </div>
        </div>
        </div>
      </div>)
      
        }
        {showPeopleForm && 
          <div className="modalForm" id="addPeopleForm">
          <span className="modal projectCreate">
          <span className="formBody">
              <h2>Add People</h2>
              <span className="buttonRow teamButtonRow">
              {props.teamUsers.filter(obj0 => (obj0.id === peopleFormObj['owner'])).map((obj, i) => (
                <span className="teamBtn" key={'peopleBtn_' + i}>
                <div onClick={(e) => handleTeamUser(e, obj)} className={"button btn"} data-id={obj.id}>
                  {obj.name}
                </div>
                <Star className={"star" + ((peopleFormObj['owner'] === obj.id)?" on":"")} onClick={(e) => handleOwner(e, obj.id)} />
   
                </span>
                ))}
                {props.teamUsers.filter(obj0 => (obj0.id !== peopleFormObj['owner'])).map((obj, i) => (
                  <span className="teamBtn" key={'peopleBtn_' + i}>
                  <div onClick={(e) => handleTeamUser(e, obj)} className={"button btn"} data-id={obj.id}>
                    {obj.name}
                  </div>
                  <Star className={"star" + ((peopleFormObj['owner'] === obj.id)?" on":"")} onClick={(e) => handleOwner(e, obj.id)} />
                
                  </span>
                  ))}
                </span>
                </span>
                <span className="buttonRow nextRow">
                  <div className="btn" onClick={() => setShowPeopleForm(false)}>Cancel</div>
                  <div className="btn save" onClick={() => handleSavePeople()}>Save</div>
                </span>
                </span>
          </div>
        }

        {showLabelForm && 
          <div className="modalForm" id="addLabelForm">
          <span className="modal projectCreate">
          <span className="formBody">
              <h2>Add Label</h2>
              <span className="buttonRow">
                {props.labels.map((obj, i) => (
                  <div onClick={(e) => handleLabelTag(e, obj)} className={"label button btn"} data-id={obj.id} key={'labelbtn_' + i}>
          
                  {obj.name}
                  {obj.icon && <img src={obj.icon} alt="icon" className="labelIcon" />}
                  </div>
                  ))}
              
              
                
                </span>
                </span>
                <span className="buttonRow nextRow">
                  <div className="btn" onClick={() => setShowLabelForm(false)}>Cancel</div>
                  <div className="btn save" onClick={() => handleSaveLabel()}>Save</div>
                  
                </span>
                </span>
          </div>
        }
            
     { subProject && <div className="subProject">
          <div className="backBtn" onClick={() => goBack()}><ChevronBack className="icon btn" /></div>
          <div className="subHeading">
            
            {(!subProject.name || editField === "subproject_name") ? 
            <span className="inputField">{subProject.name ? <input type="text" defaultValue={subProject.name} onKeyUp={(e) => handleKeyPress(e, "subproject_name", null)} name="name" id="subproject_name" /> : <input type="text" placeholder={"Name"} name="subproject_name" id="subproject_name" onKeyUp={(e) => handleKeyPress(e, "subproject_name", null)}  />}
            <span className="smCancel btn" onClick={() => setEditField(null)}><Cancel/></span>
          </span> : <span className="fieldValue" onClick={() => setEditField("subproject_name")}><h2>{subProject.name}</h2></span>}
          
          <span className="labels">
         
          {subProject.labels && (subProject.labels.length > 0) ? subProject.labels.map((item, j) => (
            <span key={'owner_' + j} onClick={() => handleLabelForm('subProject', subProject.labels, subProject.id)} className={"label btn"} style={{backgroundColor: (props.dark ? item.color_dark : item.color)}}>
            {item.name}
            {item.icon && <img src={item.icon} alt="icon" className="labelIcon" />}
           
            
            </span>
          )) : <span className="fieldCta" onClick={() => handleLabelForm('subProject', subProject.labels, subProject.id)}><Tag className="icon" /></span> } 
         
          </span>  
            
          </div>
          
          <div className="subProjectRow">
            <div className="note">
                <div className="text">
                {(editField === "status_note") ? 
                <span className="inputField">{subProject.status_note ? <textarea type="text" defaultValue={subProject.status_note} name="status_note" id="status_note" onChange={(e) => ( tempTextObj.current = e.target.value)} /> : <textarea type="text" placeholder={"Add Status Notes"} name="status_note" id="status_note"  onChange={(e) => ( tempTextObj.current = e.target.value)} />}
                <span className="smCancel btn textarea">
                <Checkmark onClick={(e) => handleTextEdit("status_note")} />
                <Cancel onClick={() => setEditField(null)} />
              </span>
              </span> : subProject.status_note ? <span className="fieldValue" onClick={() => setEditField("status_note")}>{subProject.status_note}</span> : <span className="fieldCta" onClick={() => setEditField("status_note")}>Add Status Note</span>}
              </div>
          </div>
          <div className="text">
          {(editField === "description") ? 
            <span className="text">

            <CKEditor
                editor={ ClassicEditor }
                data={subProject.description}
                config={{
                  toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
                  link: { addTargetToExternalLinks: true }
                }}
                onReady={() => {
                  tempTextObj.current = null;
                }}
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    console.log( { event, editor, data } );
                    tempTextObj.current = data;
                } }
            />
            <span className="smCancel btn textarea">
              <Checkmark onClick={(e) => handleTextEdit("description")} />
              <Cancel onClick={() => setEditField(null)} />
            </span>
            
          </span> :
            subProject.description ? <div className="text" onClick={() => setEditField("description")} dangerouslySetInnerHTML={ {__html: subProject.description} } /> : <span className="fieldCta" onClick={() => setEditField("description")}>Add Descripton</span>
        }
          </div>
            <span className="subProjectIcons">
              <span className="statusIcon">
                <span className="field">
                <span className="fieldValue btn" onClick={(e) => handleEditField(e, "subproject_status")}>
                  <span id="fieldValue_subproject_status">
                  {props.statuses && props.statuses.filter(obj0 => obj0.id === subProject.status).map((item, i) => (
                    <SVG key={'status_' + i} src={item.icon} className="projectIcon" />
                  ))}
                  </span>
                </span>
                  <span className="inputField dropDownField status" id="inputField_subproject_status">
                    <span className={"dropDown"}>
                      <span className="dropDownChoices status">        
                        {props.statuses && props.statuses.map((item, i) => (
                          <span key={'dropdownStatus_' + i} className={"dropDownChoice btn" + handleOnValue(subProject.status, item.id)} onClick={() => editProjectField("status", item.id)}><SVG key={'status_' + i} src={item.icon} className="projectIcon" /></span>
                        ))}
                        
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              
              <span className="initials">
              {subProject.team && (subProject.team.length > 0) ? 
                <Fragment>
                {subProject.team && subProject.team.filter(obj0 => (obj0.is_owner === true)).map((item, j) => (
                  <span key={'owner_' + j}  onClick={() => handlePeopleForm('subProject', subProject.users, subProject.id, subProject.owner)} className={"teamInitial btn" + ((item.is_owner)?(" ownerInitial"):"")}>{item.initials}</span>
                ))} 
                {subProject.team && subProject.team.filter(obj0 => (obj0.is_owner === false)).map((item, j) => (
                  <span key={'owner_' + j} onClick={() => handlePeopleForm('subProject', subProject.users, subProject.id, subProject.owner)} className={"teamInitial btn" + ((item.is_owner)?(" ownerInitial"):"")}>{item.initials}</span>
                ))} 
                </Fragment>
               : <span className="fieldCta" onClick={() => handlePeopleForm('subProject', subProject.users, subProject.id, subProject.owner)}>Add People</span> } 
              </span>
              
              
            
            </span>


            <div className="tasks">
              <div className="headingRow">
                <div className="groupHeading">
                  <h3>Tasks</h3> 
                  <Plus onClick={() => setShowTaskForm(true)}className="icon btn" />  
                </div>
                <div className="taskFilters">
                  <span className="field">
                  <span onClick={() => showFilter('label')} className="btn filterBtn">
                  <span>{labelFilter ? <span className="label btn">
                  {labelFilter.name}
                  {labelFilter.icon && <SVG  src={labelFilter.icon} className="labelIcon" />}
                  </span>: "Label"}</span>
                    </span>
                      <span className="inputField dropDownField" id="filter_label">
                        <span className={"dropDown"}>
                          <span className="dropDownChoices">

                            {props.labels && props.labels.map((item, i) => (
                              <span className="dropDownChoice gridChoice btn labelBtn" key={'dropdownLabel_' + i} onClick={() => onFilter("label", item)}>
                              <span className="iconName">{item.name}</span>
                                <SVG key={'label_' + i} src={item.icon} className="projectIcon" />
                                
                                </span>
                            ))}
                            
                          </span>
                        </span>
                      </span>
                    </span>
                    <span className="field">
                      <span onClick={() => showFilter('status')} className="btn filterBtn">
                      <span>{statusFilter ? <span className="label btn">
                     
                                    <span className="iconName">{statusFilter.name}</span>
                                    <SVG src={statusFilter.icon} className="projectIcon" />
                                    </span> : "Status"}</span>
                        </span>
                          <span className="inputField dropDownField" id="filter_status">
                            <span className={"dropDown"}>
                              <span className="dropDownChoices">

                                {props.statuses && props.statuses.map((item, i) => (
                                  <span className="dropDownChoice gridChoice btn" key={'dropdownStatus_' + i} onClick={() => onFilter("status", item)}>
                                    <SVG key={'statusDd_' + i} src={item.icon} className="projectIcon" />
                                    <span className="iconName">{item.name}</span>
                                    </span>
                                ))}
                                
                              </span>
                            </span>
                          </span>
                        </span>
                      {(statusFilter || labelFilter) && <span className="clearBtn btn" onClick={() => clearFilters()}><Cancel /></span> }
                </div>
              </div>
            

              <div className="taskGrid">
             
              <ul>
              {tasks && tasks.filter(a => (statusFilter ? (a.status === statusFilter.id):a)).filter(a => (labelFilter ? (a.label_ids.indexOf(labelFilter.id) > -1):a)).map((obj, i) => (
                <li key={'task_' + obj.id} className={(subProject.labels.findIndex(a => a.id === 2) === 0) ? 'bugTask':"defaultTask"} id={"taskRow_" + obj.id}>
                  <Task 
                    index={i}
                    id={obj.id}
                    obj={obj} 
                    tasks={tasks}
                    setTasks={setTasks}
                    editText={editText}
                    editField={editField} 
                    setEditField={setEditField}
                    onListTab={onListTab}
                    onCheck={onCheck}
                    handleTaskKeyPress={handleTaskKeyPress}
                    onTaskText={onTaskText}
                    onRightClick={onModuleContext}
                    showLabelForm={showLabelForm}
                    handleLabelForm={handleLabelForm}
                    token={props.token}
                    teamUsers={props.teamUsers}
                    />
                
                </li>
              ))}
              </ul>
             { (tasks && tasks.filter(a => (statusFilter ? (a.status === statusFilter.id):a)).filter(a => (labelFilter ? (a.label_ids.indexOf(labelFilter.id) > -1):a)).length === 0 && <h4>Nothing found</h4>)}
              </div>
            </div>
          </div>
      </div>}

      <div className="btn userBtn deleteBtn deleteSubProject" onClick={(e) => deleteSubProject(e)}>
        <Trash />
      </div>
    </section>
    
      </CSSTransition>
    );
}

export default ProjectDetail;