import React, { useState, useEffect } from "react";
import {
  Link
} from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { getStatic } from '../static';
import {ReactComponent as Placeholder} from "../static/svg/text.below.photo.svg"
import Loader from "../static/svg/loader.svg";
import Close from "../static/svg/close.svg";
import CloseDelete from "../static/svg/close-delete.svg";
import LeftArrow from "../static/svg/leftarrow.svg";
import Lock from "../static/svg/lock.svg";
import Unlock from "../static/svg/unlock.svg";
import OpenLink from "../static/svg/openLink-w.svg";

import {
  useParams
} from "react-router-dom";
import axios from "axios";

import imagePlaceholder from '../static/img/image.png'

import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "../constants.js";
if (IS_LIVE) {
  var base_url = LIVE_HOSTS.base_url + '/api/'
} else {
  var base_url = LOCAL_HOSTS.base_url + '/api/'
}

function Site(props) {
  const nodeRef = React.useRef(null);
    const [apps, setApps] = useState([]);
    const [loaded, setLoaded] = useState(false);
    function fetchJSON() {
        try {
            const get_url = base_url + "app-flat/";
            axios({
                method: "get",
                url: get_url,
                headers: {
                  Authorization: 'Token ' + props.token
                },
            }).then((response) => {
                const assetJSON = response.data;
                console.log('site', assetJSON)
                setApps(assetJSON);
                
            });
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
      if (props.token) {
        fetchJSON();
      }
    }, [props.token]);
   
    useEffect(() => {
      ((apps.length > 0) && 
        setLoaded(true)
        )
    }, [apps]);

    function handleGroup(id, name) {
      // try {
      //   const get_url = base_url + "app-group/" + id;
      //   axios({
      //       method: "get",
      //       url: get_url,
      //       headers: {
      //         Authorization: 'Token ' + props.token
      //       },
      //   }).then((response) => {
      //       const assetJSON = response.data;
      //       console.log('apps', assetJSON)
      //       setAssets(assetJSON);
      //       setAllAssets(assetJSON);
      //       setGroup(name);
      //       setCancel(true);
            
      //   });
      // } catch (error) {
      //     console.error(error);
      // }
    }

    return (
      <CSSTransition in={loaded} timeout={0} classNames="pageItem" nodeRef={nodeRef}>
        <section className="main" ref={nodeRef}>
        
          <div className="column">
            <div className="mainArray sitesArray">
              {apps.filter(a => a.site_id !== null).map((item, i) => (
                <Link 
                  className={"siteBtn"} 
                  key={"service_" + i} 
                  to={"/reports/" + item.id}
                  >
                  {(item.thumbnail)?<img className="thumb" src={getStatic(item.thumbnail)} alt="" />:<figure className="thumb" alt="" />}
                  <span className="item name">{item.name}</span>
                </Link>
                
              ))}
            </div>
          </div>
        </section>
      </CSSTransition>
    );
}

export default Site;


// {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}



// (!item.is_group) ?
//                 (<Link 
//                   className={"siteBtn status" + item.status} 
//                   key={"service_" + i} 
//                   to={item.get_absolute_url}
//                   >
//                   {(item.thumbnail)?<img className="thumb" src={getStatic(item.thumbnail)} alt="" />:<figure className="thumb" alt=""><Placeholder /></figure>}
//                   <span className="item name">{item.name}</span>
//                   <span className="item client">{item.client_name}</span>
//                 </Link>):(
//                   <span 
//                     className={"siteBtn groupBtn btn status" + item.status} 
//                     key={"service_" + i} 
//                     onClick={() => handleGroup(item.id, item.name)}
//                     >
//                     {(item.thumbnail)?<img className="thumb" src={getStatic(item.thumbnail)} alt="" />:<figure className="thumb" alt=""><Placeholder /></figure>}
//                     <span className="itemGroup">
                     
//                       <span className="itemHeading">
//                         <span className="item name">{item.name}</span>
//                         <span className="item client">{item.client_name}</span>
//                       </span>
//                       <span className="icon">
//                         <Folder />
//                       </span>
//                     </span>
//                   </span>
//                 )