import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { CSSTransition } from 'react-transition-group';
import { getStatic } from '../static';
import ProjectPeopleDetail from './app.project_people_detail';
import {ReactComponent as Checkmark} from "../static/svg/checkmark.circle.fill.svg";
import {ReactComponent as WrenchScrewdriver} from "../static/svg/wrench.and.screwdriver.fill.svg"
import {ReactComponent as Exclamation} from "../static/svg/exclamationmark.triangle.fill.svg";
import {ReactComponent as Pencil} from "../static/svg/square.and.pencil.svg";
import {ReactComponent as Clear} from "../static/svg/clear.svg"
import {ReactComponent as People} from "../static/svg/person.2.svg"

import axios from "axios";

import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "../constants.js";
if (IS_LIVE) {
  var base_url = LIVE_HOSTS.base_url + '/api/'
} else {
  var base_url = LOCAL_HOSTS.base_url + '/api/'
}

function ProjectPeople(props) {
  const nodeRef = React.useRef(null);
    const [projects, setProjects] = useState([]);
    const [proposals, setProposals] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [client, setClient] = useState();
    const [dev, setDev] = useState(0);
    const [up, setUp] = useState(0);
    const [down, setDown] = useState(0);
    const [onGoing, setOnGoing] = useState([]);
    const [group, setGroup] = useState();
    const [cancel, setCancel] = useState(false);
    const [category, setCategory] = useState();
    const [user, setUser] = useState();
  
    // const projectForm = useRef();

    function fetchJSON() {
        // try {
        //     const get_url = base_url + "team-user/";
        //     axios({
        //         method: "get",
        //         url: get_url,
        //         headers: {
        //           Authorization: 'Token ' + props.token
        //         },
        //     }).then((response) => {
        //         const assetJSON = response.data;
        //         const projectsJSON = assetJSON.filter(item => item.status !== 4);
        //         // let inProgress = projectsJSON.filter(function(obj) { return obj.status === 1; });
        //         // let inCompleted = projectsJSON.filter(function(obj) { return obj.status === 2; });
        //         // let inDown = projectsJSON.filter(function(obj) { return obj.status === 3; });
        //         // setDev(inProgress);
        //         // setDown(inDown);
        //         // setUp(inCompleted);
        //         console.log('projectsJSON', projectsJSON)
        //         // const companyJSON = projectsJSON.filter(obj => obj.company === props.teamUser['company']);
        //         setProjects(projectsJSON);
        //         setLoaded(true);
        //     });
        // } catch (error) {
        //     console.error(error);
        // }

        try {
          const get_url = base_url + "team-user/";
          axios({
              method: "get",
              url: get_url,
              headers: {
                Authorization: 'Token ' + props.token
              },
          }).then((response) => {
              const result = response.data;
              props.setTeamUsers(result);
              console.log('team users', result);
              setLoaded(true);
          });
      } catch (error) {
          console.error(error);
      }

      try {
        const get_url = base_url + "status/";
        axios({
            method: "get",
            url: get_url,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
            const result = response.data;
            props.setStatuses(result);
        });
      } catch (error) {
          console.error(error);
      }
      
    }

    useEffect(() => {
      if (props.token) {
        fetchJSON();
        console.log('people props', props);
      }
      
    }, [props.token]);

    function createProject(){
      console.log('createProject');
      setLoaded(false);
      let formData = {};
      formData['name'] = document.getElementById('projectName').value;
      try {
        const url = base_url + "project-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          fetchJSON();
          window.location.href = "/projects/" + response.data['id']

          console.log('response', response)

        });
      } catch (error) {
          console.error(error);
      }
    }

    function handleCancel() {
      fetchJSON();
      setGroup(null);
      setClient(null);
      setCancel(false);
      setCategory();
    }

    
    function filterBy(mode) {
      // switch(mode) {
      //   case "up":
      //     setAssets(up);
      //     setCancel(true);
      //     break;
      //   case "down":
      //     setAssets(down);
      //     setCancel(true);
      //     break;
      //   case "dev":
      //     setAssets(dev);
      //     setCancel(true);
      //     break;
      //   case "apps":
      //     setAssets(apps);
      //     setCategory('apps');
      //     setCancel(true);
      //     break;
      //   case "sites":
      //     setCategory('sites');
      //     setAssets(sites);
         
      //     setCancel(true);
      //     break;
      //   default:
      //     setAssets(allAssets);
      // }
    }

    function cancelAdd() {
      let form = document.getElementById('addProjectForm');
      gsap.to('#addProjectForm', {opacity: 0, duration: 0.3});
      gsap.to('#addProjectForm', {zIndex:-1, height: 0, duration: 0, delay: 0.3});
      form.classList.remove('on');
    }

    function startAdd(){
      let form = document.getElementById('addProjectForm');
  
      if (form.classList.contains('on')) {
        cancelAdd();
      } else {
        gsap.to('#addProjectForm', {zIndex:4, height: 'auto', duration: 0});
        gsap.to('#addProjectForm', {opacity: 1, duration: 0.3});
        form.classList.add('on');
      }
      
    }



    function handleKeyPress(e, fieldId, fieldValue) {
      if (e.key === "Enter") {
        if (fieldId === 'project_name') {
          createProject();
          cancelAdd();
        }
        
      }
    }
   

    return (
      <CSSTransition  nodeRef={nodeRef} in={loaded} unmountOnExit timeout={200} classNames="pageItem">
        <section className="main" ref={nodeRef}>


          <div className="column">
            
            
            {user ? <ProjectPeopleDetail user={user} setUser={setUser} token={props.token} /> : <div className="mainArray projectsArray assets">
            {props.teamUsers && props.teamUsers.map((item, i) => (
              <span 
                className={"siteBtn btn"} 
                key={"user_" + i}
                onClick={() => setUser(item)}
                >
                {(item.thumbnail) && <img className="thumb" src={getStatic(item.thumbnail)} alt="" />}
             
                <span className="projectBtnText">
                  <span className="item name">{item.name}</span>
                  <span className="userStats">
                    <span className="item">Projects: {item.stats.projects}</span>
                    <span className="item">Deliverables: {item.stats.subs}</span>
                    <span className="item">Tasks: {item.stats.tasks}</span>
                
                  </span>
                </span>
              </span>
            ))}
            </div> }
          </div>
          
        

          
        </section>
      </CSSTransition>
    );
}

export default ProjectPeople;


// {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}



// <div className="filters">
// <div className="filterBtn">Date</div>
// <div className="filterBtn">Date</div>
// </div>