import React, { useState, useEffect, useRef } from "react";
import {
    useParams
  } from "react-router-dom";
  import axios from "axios";
import { CSSTransition } from 'react-transition-group';
import { getStatic } from '../static';
import DatePicker from 'react-datepicker';
import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import "react-datepicker/dist/react-datepicker.css";
import {
    BarChart,Bar,
    LineChart,Line,
    XAxis,YAxis,
    CartesianGrid,
    Tooltip, Legend,
    ResponsiveContainer,
    AreaChart,Area,
    PieChart, Pie, Cell
  } from "recharts";
// import CustomYAxisTick from './chart.yAxisTick';
import imagePlaceholder from '../static/img/image.png';
import user3rd from '../static/svg/user-3rd.svg';
import userUnion from '../static/svg/user-bp-union.svg';
import userBp from '../static/svg/user-bp.svg';
import userUnknown from '../static/svg/user-questionmark.svg';
import logo from '../static/svg/bp-logo.svg';
import {ReactComponent as EngagementIcon} from '../static/svg/engagement.svg';
import {ReactComponent as LocationIcon} from '../static/svg/location.svg';
import {ReactComponent as TechIcon} from '../static/svg/tech.svg';
import {ReactComponent as Arrow} from '../static/svg/arrow-down.svg';
import {ReactComponent as Loader} from "../static/svg/loader.svg";
import {ReactComponent as Close} from "../static/svg/close.svg";

import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "../constants.js";

let base_url = null;
if (IS_LIVE) {
  base_url = LIVE_HOSTS.base_url + '/api/'
} else {
  base_url = LOCAL_HOSTS.base_url + '/api/'
}
mapboxgl.accessToken = 'pk.eyJ1IjoiYnBzdjMiLCJhIjoiY2tpNG5oZXpoMjA2ODJxcGRmYnJlajV2ayJ9.ibWqAVMhvzPSqEZfLJE63w';

const rightArrow = <svg xmlns="http://www.w3.org/2000/svg" width="12.967" height="21" viewBox="0 0 12.967 21"><path d="M10.5,0,0,10.5l2.467,2.467L10.5,4.952l8.033,8.015L21,10.5Z" transform="translate(12.967) rotate(90)" fill="#000"/>
</svg>

const leftArrow = <svg xmlns="http://www.w3.org/2000/svg" width="12.967" height="21" viewBox="0 0 12.967 21"><path d="M10.5,0,0,10.5l2.467,2.467L10.5,4.952l8.033,8.015L21,10.5Z" transform="translate(0 21) rotate(-90)" fill="#000"/>
</svg>

function truncateString(str, n) {
    if (str.length > n) {
      return str.substring(0, n) + "...";
    } else {
      return str;
    }
  }


const CustomYAxisTick = ({ x, y, payload }) => {
    if (payload) {
      return (
        <g transform={`translate(${0},${y})`}>
            <text x={0} y={0}
                textAnchor="start"
                fill="#666"
                fontSize={13}
                >{truncateString(payload.value, 30)}</text>
        </g>
      );
    }
  
    return null;
  };

  const CustomYAxisTick2 = ({ x, y, payload }) => {
    if (payload) {
      return (
        <g transform={`translate(${x},${y})`}>
            <text x={-5} y={0}
                textAnchor="end"
                fill="#666"
                fontSize={14}
                >{payload.value}</text>
        </g>
      );
    }
  
    return null;
  };


  const CustomXAxisTick = ({ x, y, payload }) => {
    if (payload) {
      return (
        <g transform={`translate(${x},${y})`}>
            <text x={-20} y={20}
                textAnchor="start"
                fill="#666"
                fontSize={14}
                >{payload.value}</text>
        </g>
      );
    }
  
    return null;
  };


function ReportDetail(props) {
    const nodeRef = useRef(null);
    const mapContainer = useRef(null);
    const orderAsc = useRef(true);
    const reload = useRef(false);
    
    let {id} = useParams();
    let newDate = new Date();
    const COLORS = ['#1B72E8', '#76AAF1', '#D1E3FA'];
    const [report, setReport] = useState(null);
    const [reportArray, setReportArray] = useState(null);
    const [activeKey, setActiveKey] = useState(null);
    const [stats, setStats] = useState(null);
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate()-14)));
    const [endDate, setEndDate] = useState(new Date());
    const [restartOn, setRestartOn] = useState(true);
    const [reloadOn, setReloadOn] = useState(true);
    const [renewCertbotOn, setRenewCertbotOn] = useState(true)
    const [response, setResponse] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [username, setUsername] = useState(null);
    const [market, setMarket] = useState(null);
    const [category, setCategory] = useState(null);
    const [showUsers, setShowUsers] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [pageCount, setPageCount] = useState(null);
    const [map, setMap] = useState();
    const [lng, setLng] = useState(-70.9);
    const [lat, setLat] = useState(42.35);
    const [zoom, setZoom] = useState(1.5);
    const [installations, setInstallations] = useState([]);
    const [pings, setPings] = useState(null);

    const datePattern = 'yyyy-MM-dd';

    function fetchReport(path) {
        try {
            setShowLoader(true);
            const get_url = base_url + path;
            let formData = {
                "username": username?username:"",
                "category": category?category:"",
                "market": market?market:"",
                "startdate": format(new Date(startDate), datePattern) + "T00:00:00-04:00",
                "enddate": format(new Date(endDate), datePattern) + "T23:59:59-04:00",
                "app_id": id,
                "page": pageNum
            };
            axios({
                method: "post",
                url: get_url,
                data: formData,
                headers: {
                  Authorization: 'Token ' + props.token
                },
            }).then((response) => {
                const assetJSON = response.data;
                setReport(assetJSON);
                console.log('app', assetJSON);

               
                
            });
        } catch (error) {
            console.error(error);
            setReportArray(null);
        }
       
    }


    useEffect(() => {
      if (props.token) {
        setLoaded(true);

        if (map) return; // initialize map only once
        var mapCurrent = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/bpsv3/cl7tkfd2q002t15rv40y4lkto',
            center: [lng, lat],
            zoom: zoom,
            attributionControl: false
        });
        setMap(mapCurrent);

        mapCurrent.on('load', function(){
            mapCurrent.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
        })


        fetchReport('app-report-date-summary/')

        // var installCron = setInterval(() => {
        //     try {
        //         const get_url = base_url + 'app-installations/' + id + '/';
        //         axios({
        //             method: "get",
        //             url: get_url,
        //             headers: {
        //               Authorization: 'Token ' + props.token
        //             },
        //         }).then((response) => {
        //             const assetJSON = response.data;
        //             setInstallations(assetJSON);
        //             console.log('setInstallations', assetJSON)
        //         });
        //     } catch (error) {
        //         console.error(error);
        //         setInstallations(null);
        //     }
        // }, 300000);
        const get_url = base_url + 'app-pings/' + id + '/';
                axios({
                    method: "get",
                    url: get_url,
                    headers: {
                        Authorization: 'Token ' + props.token
                    },
                }).then((response) => {
                    const assetJSON = response.data;
                    setPings(assetJSON.geojson);
                    console.log('setPings', assetJSON.geojson)
                });
                

        var pingCron = setInterval(() => {
            try {         
                const get_url = base_url + 'app-pings/' + id + '/';
                axios({
                    method: "get",
                    url: get_url,
                    headers: {
                        Authorization: 'Token ' + props.token
                    },
                }).then((response) => {
                    const assetJSON = response.data;
                    setPings(assetJSON.geojson);
                    console.log('setPings', assetJSON.geojson)
                });
                
            } catch (error) {
                console.error(error);
                setPings(null);
            }
        }, 300000);
        
    }
    return ()=>{
        // clearInterval(installCron);
        clearInterval(pingCron);
    }
    }, [props.token]);

    useEffect(() => {
        if (pings) {
            console.log('pings', pings)
            const markerInner = '<svg viewBox="0 0 100 100" expanded="true" width="280px" height="280px" xmlns="http://www.w3.org/2000/svg" class="cta on"><circle cx="50%" cy="50%" r="25%" fill="rgba(253,221,0,0.5)" class="pulse2"></circle><circle cx="50%" cy="50%" r="25%" fill="rgba(253,221,0,0.5)" class="pulse"></circle><circle cx="50%" cy="50%" r="25%" fill="rgba(253,221,0,0.3)" ></circle> <circle cx="50%" cy="50%" r="25%" fill="none" stroke="rgba(238,54,38,1)" strokeWidth="3"></circle></svg>';
    
            var markerContainer = document.createElement('div');
            markerContainer.className = "ping";
            markerContainer.innerHTML = markerInner;
    
            pings.features.forEach((obj, i) => {
                // console.log('obj', obj, obj.geometry.coordinates)
                new mapboxgl.Marker({
                    element: markerContainer,
                    offset: [15, -5],
                    anchor: "top"
                  })
                  .setLngLat(obj.geometry.coordinates)
                  .addTo(map);
            })
        }
    }, [pings, map])

    useEffect(() => {
        report && setShowLoader(false);
        if (report && report.events && report.events.main) {
            setReportArray(report.events.main);
            setStats([{"top_narratives": report.events.top_narratives}, {"top_markets": report.events.top_markets}, {"top_users": report.events.top_users}, {"avg_duration": report.events.avg_duration}, {"sessions_over_time": report.events.sessions_over_time}, {"total_sessions": report.events.total_sessions}])
            setPageCount(report.events.page_count);
        } else if (report && report.locations) {
            setReportArray(report.locations);
            setPageCount(null);
            map && updateMap();
        } else if (report && report.devices) {
            // pass
            setReportArray({'platforms': report.devices, "applications": report.applications, "modes": report.modes});
            setPageCount(null);
        } else {
            setPageCount(null);
            // pass
        }
    }, [report])

    useEffect(() => {
        if (username) {
            if (report.type === 'engagement') {
                fetchReport('app-report-date/')
            } else if (report.type === 'location') {
                fetchReport('app-report-location/')
            } else {
                fetchReport('app-report-tech/')
            }
        } else {
            if (reload.current) {
                if (report.type === 'engagement') {
                    fetchReport('app-report-date/')
                } else if (report.type === 'location') {
                    fetchReport('app-report-location/')
                } else {
                    fetchReport('app-report-tech/')
                }
                reload.current = false;
            }
        }
    }, [username])

    useEffect(() => {
        if (market) {
            if (report.type === 'engagement') {
                fetchReport('app-report-date/')
            } else if (report.type === 'location') {
                fetchReport('app-report-location/')
            } else {
                fetchReport('app-report-tech/')
            }
        } else {
            if (reload.current) {
                if (report.type === 'engagement') {
                    fetchReport('app-report-date/')
                } else if (report.type === 'location') {
                    fetchReport('app-report-location/')
                } else {
                    fetchReport('app-report-tech/')
                }
                reload.current = false;
            }
        }
    }, [market])

    useEffect(() => {
        if (category) {
            if (report.type === 'engagement') {
                fetchReport('app-report-date/')
            } else if (report.type === 'location') {
                fetchReport('app-report-location/')
            } else {
                fetchReport('app-report-tech/')
            }
        }  else {
            if (reload.current) {
                if (report.type === 'engagement') {
                    fetchReport('app-report-date/')
                } else if (report.type === 'location') {
                    fetchReport('app-report-location/')
                } else {
                    fetchReport('app-report-tech/')
                }
                reload.current = false;
            }
        }
    }, [category])
    

    useEffect(() => {
        if (showUsers && report && report.type === "engagement") {
            fetchReport('app-report-date/')
        } else if (!showUsers && report && report.type === "engagement") {
            fetchReport('app-report-date-summary/')
        } else {
            // 
        }
    }, [showUsers])

    useEffect(() => {
        if (pageNum && report) {
            if (report.type === 'engagement') {
                if (showUsers) {
                    fetchReport('app-report-date/')
                } else {
                    fetchReport('app-report-date-summary/')
                }
                
            } else if (report.type === 'location') {
                fetchReport('app-report-location/')
            } else {
                fetchReport('app-report-date-summary/')
            }
        }
    }, [pageNum])

    useEffect(() => {
        if (!showDatePicker && report && report.type) {
            if (report.type === 'engagement') {
                if (showUsers) {
                    fetchReport('app-report-date/')
                } else {
                    fetchReport('app-report-date-summary/')
                }
                
            } else if (report.type === 'location') {
                fetchReport('app-report-location/')
            } else {
                fetchReport('app-report-date-summary/')
            }
        }
    }, [showDatePicker])

    function updateMap(){
        // console.log('m', map, report)
        var sourceId = 'locationSource';
            let mapSource = map.getSource(sourceId);

            if (!mapSource) {
                console.log('report.geojson', report.geojson)
                map.addSource(sourceId, {
                    type: 'geojson',
                    data: report.geojson,
                    cluster: true,
                    clusterMaxZoom: 8,
                    clusterRadius: 30 
                });
    
                map.addLayer({
                    "id": "locationSourceLayers",
                    "type": "circle",
                    "source": sourceId,
                    "minzoom": 1,
                    "maxzoom": 21,
                    "filter": ["!has", "point_count"],
                    "paint": {
                        "circle-color": "rgb(27,114,232)",
                        "circle-stroke-width": 0.5,
                        "circle-stroke-color": "rgb(27,114,232)",
                        "circle-opacity": 1
                    }
                  });

                 
    
                map.addLayer({
                    "id": "clusters",
                    "type": "circle",
                    "source": sourceId,
                    "filter": ["has", "point_count"],
                    "paint": {
                        "circle-color": {
                            "property": "point_count",
                            "type": "interval",
                            "stops": [
                                [0, "rgb(27,114,232)"],
                                [100, "rgb(27,114,232)"],
                                [750, "rgb(27,114,232)"],
                            ]
                        },
                        "circle-radius": {
                            "property": "point_count",
                            "type": "interval",
                            "stops": [
                                [0, 20],
                                [100, 30],
                                [750, 40]
                            ]
                        },
                        "circle-stroke-width": 1,
                        "circle-stroke-color":"rgb(27,114,232)",
                        "circle-opacity": 0.8
                    }
                });
                map.addLayer({
                    "id": 'cluster-count',
                    "type": 'symbol',
                    "source": sourceId,
                    "filter": ['has', 'point_count'],
                    "layout": {
                        'text-field': '{point_count_abbreviated}',
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 14
                    },
                    "paint": {
                        "text-color": "#ffffff"
                    }
                  
                });

            }  else {
                map.getSource(sourceId).setData(report.geojson);
            }   
    }



   
    function handleReportArrayOrderBy(key){
        if (reportArray) {
            
            var currArray = [...reportArray];
            let newArray = null;
            if (orderAsc.current) {
                if (key === 'count' || key === "ip" || key === "avg_duration" ) {
                    newArray = currArray.sort((a, b) => b[key] - a[key]);
                } else {
                    newArray = currArray.sort((a, b) =>  {
                        if(a[key] < b[key] ) { return -1; }
                        if(a[key]  > b[key] ) { return 1; }
                        return 0;
                    }
                    );
                }
                orderAsc.current = false;
            } else {
                if (key === 'count' || key === "ip" || key === "avg_duration" ) {
                    newArray = currArray.sort((a, b) => a[key] - b[key]);
                }
                else {
                    newArray = currArray.sort((a, b) =>  {
                        if(a[key] > b[key] ) { return -1; }
                        if(a[key] < b[key] ) { return 1; }
                        return 0;
                        }
                    );
                }
                orderAsc.current = true;
            }
            
            setReportArray(newArray);
            setActiveKey(key);
            console.log('key', key, newArray[0])
        }
    }

    const onChange = (dates) => {
        const [start, end] = dates;
        console.log(dates)
        setStartDate(start);
        setEndDate(end);
        if (end) {
            setShowDatePicker(false);
        }
        
      };

      function handleMarket(e) {
        if (e.key === "Enter") {
          setMarket(e.target.value)
          e.target.blur();
          console.log('enter', e.target.value)
        }
      }

      function handleCategory(e) {
        if (e.key === "Enter") {
          setCategory(e.target.value)
          e.target.blur();
          console.log('enter', e.target.value)
        }
      }

      function handleUsername(e) {
        if (e.key === "Enter") {
          setUsername(e.target.value)
          e.target.blur();
          console.log('enter', e.target.value)
        }
      }



    function handleUserIcon(category) {
        let resultIcon;
        if (category === "Brookfield") {
            resultIcon = userBp;
        } else if (category === "Third Party") {
            resultIcon = user3rd;
        } else if (category === "Union"){
            resultIcon = userUnion;
        } else {
            resultIcon = userUnknown
        }
        return <img src={resultIcon} className="userIcon" width="24px" height="auto" alt="userIcon" />
    }

    function handleClearFilter(e, field) {
        if (field === "market") {
            setMarket(null)
        } 
        if (field === "username") {
            setUsername(null)
        } 
        if (field === "category") {
            setCategory(null)
        } 

        var input = e.target.parentElement.parentElement.querySelector('input');
        // console.log('input', input, e.target.parentElement.parentElement);
        input.value = "";

        reload.current = true;
    }

    return (
      <CSSTransition in={loaded} timeout={0} classNames="pageItem" nodeRef={nodeRef}>
        <section className="main reports" ref={nodeRef}>
        {showLoader && <div className="loader"><Loader /></div>}
        <div ref={mapContainer} className="map-container" />
        <div className="branding">
            <h1>Brookfield Presentation App Analytics</h1>
            <img style={{height: 40, width: "auto"}} src={logo} alt="logo" />
        </div>
         <nav className="subNav">   
            <ul>
                <li onClick={() => (showUsers ? fetchReport('app-report-date/') : fetchReport('app-report-date-summary/'))} className={report && (report.type === "engagement")?"on":"off"}>
                <EngagementIcon className="icon" />
                Engagement</li>
                <li onClick={() => fetchReport('app-report-location/')} className={report && (report.type === "location")?"on":"off"}><LocationIcon className="icon" /> Location</li>
                <li onClick={() => fetchReport('app-report-tech/')} className={report && (report.type === "tech")?"on":"off"}><TechIcon className="icon" /> Tech</li>
            </ul>

            <div className="filters">
                <label className={"switch" + (showUsers ? " on":"")} onClick={() => setShowUsers(prevCheck => !prevCheck)}>
                    <span className="slider round"> {showUsers?"Users":"All"}</span>
                   
                </label>
                {showUsers && <React.Fragment><div className={"userSelected" + (market?" on":"")}><input type="text" onKeyPress={(e) => handleMarket(e)} placeholder={market?market:"Filter by Market"} />
                {market && <span onClick={(e) => handleClearFilter(e, 'market')} className="closeBtn"><Close /></span>}
                </div>
                <div className={"userSelected" + (category?" on":"")}><input type="text" onKeyPress={(e) => handleCategory(e)} placeholder={category?category:"Filter by Category"} />
                {category && <span onClick={(e) => handleClearFilter(e, 'category')} className="closeBtn"><Close /></span>}
                </div>
                <div className={"userSelected" + (username?" on":"")}><input type="text" onKeyPress={(e) => handleUsername(e)} placeholder={username?username:"Filter by Username"} />
                {username && <span onClick={(e) => handleClearFilter(e, 'username')} className="closeBtn"><Close /></span>}
                </div></React.Fragment>}
                <div className="dateSelected" onClick={() => setShowDatePicker(prevCheck => !prevCheck)}>
                    {startDate && format(startDate, "MMM d, Y")} - {endDate && format(endDate, "MMM d, Y")}
      
                </div>
                {showDatePicker && <div className="dateFilter">
                    <DatePicker
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    />
                </div>}
            </div>
         </nav>
         
          <div className={"reportDetail" + ((report && report.locations)?" locations":"")}>
            

            
            {report && (<div className="reportObj">

                {(report.type === "engagement") && <div className="reportCharts">

                    <div className="chart lg">
                    <div className="chartHeading"><h2>Session Counts by Page</h2></div>
                        <ResponsiveContainer width="100%" height={760}>
                            <BarChart
                                layout={"vertical"}
                                data={reportArray}
                                barSize={40}
                                barGap={10}
                                margin={{
                                    top: 40,
                                    right: 20,
                                    bottom: 20,
                                    left: 100,
                                }}
                            >
                                <CartesianGrid 
                                    stroke="#666"
                                    horizontal={false}
                                />
                                <YAxis 
                                    dataKey="page_title" 
                                    type="category"  
                                    width={120}
                                    tickLine={false}
                                    tick={<CustomYAxisTick />}
                                >
                                </YAxis>
                                <XAxis type="number" />
                                <Tooltip />
                                <Bar type="monotone" dataKey="count" fill="#1B73E8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>

                    <div className="chartGrid">
                        <div className="chart sm">
                            <div className="chartHeading"><h2>Top Narratives</h2></div>
                            <div className="headingSm">
                                <span>Name</span>
                                <span>Count</span>
                            </div>
                            {stats && stats[0].top_narratives && stats[0].top_narratives.map((obj, i) => (
                                <div className="statRow" key={"stat0_" + i}>
                                    <span>{obj.page_title}</span>
                                    <span>{obj.count}</span>
                                </div>
                            ))}
                        </div>
                        <div className="chart sm">
                            <div className="chartHeading"><h2>Top Active Markets</h2></div>
                            <div className="headingSm">
                                <span>Location</span>
                                <span>Count</span>
                            </div>
                            {stats && stats[1].top_markets && stats[1].top_markets.map((obj, i) => (
                                <div className="statRow" key={"stat0_" + i}>
                                    <span>{obj.market}</span>
                                    <span>{obj.count}</span>
                                </div>
                            ))}
                        </div>
                        <div className="chart sm">
                            <div className="chartHeading"><h2>Total Sessions Over Time</h2></div>
                            {stats && stats[4].sessions_over_time && 
                            <ResponsiveContainer width="100%" aspect={1.6}>
                                <AreaChart data={stats[4].sessions_over_time}
                                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                    <defs>
                                        <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#1B72E8" stopOpacity={0.3}/>
                                        <stop offset="95%" stopColor="#ffffff" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="timestamp__date" tick={<CustomXAxisTick />}  />
                                    <YAxis width={40} tick={<CustomYAxisTick2 />} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="count" strokeWidth="2px" stroke="#1B72E8" fillOpacity={1} fill="url(#areaGradient)" />
                                </AreaChart>
                            </ResponsiveContainer>
                        
                        }
                        </div>
                        <div className="chart sm">
                            <div className="chartHeading"><h2>Average Session Duration</h2></div>
                            <span className="statLg">{stats && stats[3].avg_duration && stats[3].avg_duration}</span>
                        </div>
                       
                        <div className="chart sm">
                            <div className="chartHeading"><h2>Top Active Users</h2></div>
                            <div className="headingSm">
                                <span>Username</span>
                                <span>Count</span>
                            </div>
                            {stats && stats[2].top_users && stats[2].top_users.map((obj, i) => (
                                <div className="statRow" key={"stat0_" + i}>
                                    <span>{handleUserIcon(obj.category)} {obj.username}</span>
                                    <span>{obj.count}</span>
                                </div>
                            ))}
                        </div>
                        <div className="chart sm">
                        <div className="chartHeading"><h2>Total Sessions</h2></div>
                            <span className="statLg">{stats && stats[5].total_sessions && stats[5].total_sessions}</span>
                        </div>
                    </div>
                    
                </div>}

                {(report.type === "tech") && <div className="reportCharts grid3">
                            
                    <div className="chart md">
                    <div className="chartHeading"><h2>Operating Systems</h2></div>
                        <ResponsiveContainer width="100%" height={400}>
                            <PieChart>
                                <Pie
                                    data={reportArray.platforms && reportArray.platforms}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius="70%"
                                    outerRadius="90%"
                                    fill="#8884d8"
                                    paddingAngle={5}
                                    dataKey="count"
                                    legendType="circle"
                                    nameKey={"platform"}
                                    startAngle={180}
                                    endAngle={-180}

                
                                >
                                {reportArray.platforms && reportArray.platforms.map((entry, i) => (
                                    <Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
                                ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        <div className="legend">
                            {reportArray.platforms && reportArray.platforms.map((entry, index) => (
                                <span key={`legend_0_${index}`} className="legendItem">
                                    <svg>
                                        <circle cx="7" cy="7" r="7" fill={COLORS[index % COLORS.length]}></circle>
                                    </svg>
                                    {entry.platform}<span>({(entry.count * 100).toFixed(2)}%)</span>
                                </span>
                            ))}

                            </div>
                    </div>

                    <div className="chart md">
                    <div className="chartHeading"><h2>Applications</h2></div>
                        <ResponsiveContainer width="100%" height={400}>
                            <PieChart>
                                <Pie
                                    data={reportArray.applications && reportArray.applications}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius="70%"
                                    outerRadius="90%"
                                    fill="#8884d8"
                                    paddingAngle={5}
                                    dataKey="count"
                                    legendType="circle"
                                    nameKey={"application"}
                                    startAngle={180}
                                    endAngle={-180}
                
                                >
                                {reportArray.applications && reportArray.applications.map((entry, i) => (
                                    <Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
                                ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        <div className="legend">
                            {reportArray.applications && reportArray.applications.map((entry, index) => (
                                <span key={`legend_0_${index}`} className="legendItem">
                                    <svg>
                                        <circle cx="7" cy="7" r="7" fill={COLORS[index % COLORS.length]}></circle>
                                    </svg>
                                    {entry.application}<span>({(entry.count * 100).toFixed(2)}%)</span>
                                </span>
                            ))}

                            </div>
                    </div>

                    <div className="chart md">
                    <div className="chartHeading"><h2>Modes</h2></div>
                    <ResponsiveContainer width="100%" height={400}>
                        <PieChart>
                            <Pie
                                data={reportArray.modes && reportArray.modes}
                                cx="50%"
                                cy="50%"
                                innerRadius="70%"
                                outerRadius="90%"
                                fill="#8884d8"
                                paddingAngle={5}
                                dataKey="count"
                                legendType="circle"
                                nameKey={"mode"}
                                startAngle={180}
                                endAngle={-180}
            
                            >
                            {reportArray.modes && reportArray.modes.map((entry, i) => (
                                <Cell key={`cell-mode-${i}`} fill={COLORS[i % COLORS.length]} />
                            ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                    <div className="legend">
                        {reportArray.modes && reportArray.modes.map((entry, index) => (
                            <span key={`legend_mode_${index}`} className="legendItem">
                                <svg>
                                    <circle cx="7" cy="7" r="7" fill={COLORS[index % COLORS.length]}></circle>
                                </svg>
                                {entry.mode}<span>({(entry.count * 100).toFixed(2)}%)</span>
                            </span>
                        ))}

                        </div>
                </div>

                </div>}
        

               {report.headings && (report.headings.length > 0) && <div className="reportArray chart">
                    <div className="chartHeading">
                        <div className="chartHeading"><h2>Report Results</h2></div>
                        {pageCount && <div className="pagination">{((pageNum > 1) && (pageNum <= pageCount)) ? <span className="prev pageBtn" onClick={() => setPageNum(pageNum-1)}>{leftArrow}</span>:null}{pageNum} of {pageCount} {(pageNum < pageCount) ? <span className="next pageBtn" onClick={() => setPageNum(pageNum+1)}>{rightArrow}</span>:null}</div>}
                    </div>
                    <div className={"reportHeading " + (report.type) + ((showUsers)?" userReport":"")}>
                        {report.headings.map((item, j) => (
                            <span key={'heading_' + j} onClick={() => handleReportArrayOrderBy(item.key)} className="reportCol">
                                {item.name}
                                <Arrow className={"arrowIcon" + ((item.key === activeKey)?" on":"") + ((orderAsc.current === true)?" asc":" des")}/>
                            </span>
                        ))}
                    </div>
                    {reportArray && (report.type === "engagement") &&  (reportArray.length > 0) && reportArray.map((item, i) => (
                    <div className={"reportRow engagement" + ((showUsers)?" userReport":"")} key={'event_' + i}>
                            {item.username && <span className="reportCol">
                                {handleUserIcon(item.category)} {item.username}
                            </span>}
                            {item.market &&<span className="reportCol">
                                {item.market}
                            </span>}
                            <span className="reportCol">
                                {item.page}
                            </span>
                            <span className="reportCol">
                                {item.page_title}
                            </span>
                            <span className="reportCol">
                                {item.name === "page-enter" ? "Session" : item.name}
                            </span>
                            <span className="reportCol">
                                {item.avg_duration}s
                            </span>
                            <span className="reportCol">
                                {item.count}
                            </span>
                    </div>
                    ))}
                    {reportArray && (report.type === "location") && (reportArray.length > 0) && reportArray.map((item, i) => (
                    <div className="reportRow" key={'event_' + i}>
                            <span className="reportCol">
                                {handleUserIcon(item.category)} {item.username}
                            </span>
                            <span className="reportCol">
                                {item.loc}
                            </span>
                            <span className="reportCol">
                                {item.ip_address}
                            </span>
                            <span className="reportCol">
                                {item.city}
                            </span>
                            <span className="reportCol">
                                {item.country}
                            </span>
                    </div>
                    ))}
                   
                </div>}
                
                </div>)}

                
          </div>

          
        </section>
      </CSSTransition>
    );
}


export default ReportDetail;


// {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}


// {reportArray && (report.type === "tech") && reportArray.map((item, i) => (
//     <div className="reportRow" key={'event_' + i}>
//             <span className="reportCol">
//                 {item.platform}
//             </span>
//             <span className="reportCol">
//                 {item.electron ? "True" : "False"}
//             </span>
//             <span className="reportCol">
//                 {(item.count * 100).toFixed(2)}%
//             </span>
//     </div>
//     ))}