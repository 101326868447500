import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { CSSTransition } from 'react-transition-group';
import { getStatic } from '../static';
import SVG from 'react-inlinesvg';
import {ReactComponent as ChevronBack} from "../static/svg/chevron.backward.svg";
import {ReactComponent as Placeholder} from "../static/svg/text.below.photo.svg"
import {ReactComponent as ArrowUpCircle} from "../static/svg/arrow.up.circle.fill.svg"
import {ReactComponent as ArrowDownCircle} from "../static/svg/arrow.down.circle.fill.svg"
import {ReactComponent as Checkmark} from "../static/svg/checkmark.circle.fill.svg";
import {ReactComponent as WrenchScrewdriver} from "../static/svg/wrench.and.screwdriver.fill.svg"
import {ReactComponent as Exclamation} from "../static/svg/exclamationmark.triangle.fill.svg";
import {ReactComponent as HourGlass} from "../static/svg/hourglass.svg";
import {ReactComponent as Pencil} from "../static/svg/square.and.pencil.svg";
import {ReactComponent as Clear} from "../static/svg/clear.svg"
import {ReactComponent as Folder} from "../static/svg/folder.fill.svg"
import {ReactComponent as People} from "../static/svg/person.2.svg"

import Cancel from "../static/svg/close.svg";

import {
  useParams
} from "react-router-dom";
import axios from "axios";

import imagePlaceholder from '../static/img/image.png'

import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "../constants.js";
if (IS_LIVE) {
  var base_url = LIVE_HOSTS.base_url + '/api/'
} else {
  var base_url = LOCAL_HOSTS.base_url + '/api/'
}


function ProjectPeopleDetail(props) {
  const nodeRef = React.useRef(null);
    const [projects, setProjects] = useState([]);
    const [proposals, setProposals] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [client, setClient] = useState();
    const [dev, setDev] = useState(0);
    const [up, setUp] = useState(0);
    const [down, setDown] = useState(0);
    const [onGoing, setOnGoing] = useState([]);
    const [group, setGroup] = useState();
    const [cancel, setCancel] = useState(false);
    const [category, setCategory] = useState();
    const [userId, setUserId] = useState();
    const [stats, setStats] = useState();
  
    // const projectForm = useRef();

    function fetchJSON() {
        try {
            const get_url = base_url + "team-user-project/" + props.user.id + "/";
            axios({
                method: "get",
                url: get_url,
                headers: {
                  Authorization: 'Token ' + props.token
                },
            }).then((response) => {
                const assetJSON = response.data;
                console.log('assetJSON', assetJSON)
                if (assetJSON.projects.length > 0) {
                    const projectsJSON = assetJSON.projects.filter(item => item.status !== 4);
                    // let inProgress = projectsJSON.filter(function(obj) { return obj.status === 1; });
                    // let inCompleted = projectsJSON.filter(function(obj) { return obj.status === 2; });
                    // let inDown = projectsJSON.filter(function(obj) { return obj.status === 3; });
                    console.log('projectsJSON', projectsJSON)
                    setProjects(projectsJSON);
                    setStats(assetJSON.stats)
                    setLoaded(true);
                }
               
            });
        } catch (error) {
            console.error(error);
        }

    //     try {
    //       const get_url = base_url + "team-user/";
    //       axios({
    //           method: "get",
    //           url: get_url,
    //           headers: {
    //             Authorization: 'Token ' + props.token
    //           },
    //       }).then((response) => {
    //           const result = response.data;
    //           props.setTeamUsers(result);
    //           console.log('team users', result);
    //           setLoaded(true);
    //       });
    //   } catch (error) {
    //       console.error(error);
    //   }

    //   try {
    //     const get_url = base_url + "status/";
    //     axios({
    //         method: "get",
    //         url: get_url,
    //         headers: {
    //           Authorization: 'Token ' + props.token
    //         },
    //     }).then((response) => {
    //         const result = response.data;
    //         props.setStatuses(result);
    //     });
    //   } catch (error) {
    //       console.error(error);
    //   }
      
    }

    useEffect(() => {
      if (props.user && props.token) {
        fetchJSON();
        console.log('people detail props', props);
        setLoaded(true);
      }
      
    }, [props.user, props.token]);

    function createProject(){
      console.log('createProject');
      setLoaded(false);
      let formData = {};
      formData['name'] = document.getElementById('projectName').value;
      try {
        const url = base_url + "project-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          fetchJSON();
          window.location.href = "/projects/" + response.data['id']

          console.log('response', response)

        });
      } catch (error) {
          console.error(error);
      }
    }

    function handleCancel() {
      fetchJSON();
      setGroup(null);
      setClient(null);
      setCancel(false);
      setCategory();
    }

    
    function filterBy(mode) {
      // switch(mode) {
      //   case "up":
      //     setAssets(up);
      //     setCancel(true);
      //     break;
      //   case "down":
      //     setAssets(down);
      //     setCancel(true);
      //     break;
      //   case "dev":
      //     setAssets(dev);
      //     setCancel(true);
      //     break;
      //   case "apps":
      //     setAssets(apps);
      //     setCategory('apps');
      //     setCancel(true);
      //     break;
      //   case "sites":
      //     setCategory('sites');
      //     setAssets(sites);
         
      //     setCancel(true);
      //     break;
      //   default:
      //     setAssets(allAssets);
      // }
    }

    function cancelAdd() {
      let form = document.getElementById('addProjectForm');
      gsap.to('#addProjectForm', {opacity: 0, duration: 0.3});
      gsap.to('#addProjectForm', {zIndex:-1, height: 0, duration: 0, delay: 0.3});
      form.classList.remove('on');
    }

    function startAdd(){
      let form = document.getElementById('addProjectForm');
  
      if (form.classList.contains('on')) {
        cancelAdd();
      } else {
        gsap.to('#addProjectForm', {zIndex:4, height: 'auto', duration: 0});
        gsap.to('#addProjectForm', {opacity: 1, duration: 0.3});
        form.classList.add('on');
      }
      
    }



    function handleKeyPress(e, fieldId, fieldValue) {
      if (e.key === "Enter") {
        if (fieldId === 'project_name') {
          createProject();
          cancelAdd();
        }
        
      }
    }

    function goBack() {
        // navigate("/projects/" + id);
        props.setUser(null);
      }
    
      function handleSubRow(rowId) {
        let row = document.getElementById(rowId);
        row.classList.toggle('on');
      }
   

    return (
      <CSSTransition nodeRef={nodeRef} in={loaded} unmountOnExit timeout={200} classNames="pageItem">
        <section className="cover" ref={nodeRef}>

         
          <span className="headingFlex">
          <div className="projectTitle"><div className="backBtn" onClick={() => goBack()}><ChevronBack className="icon btn" /></div><h1>{props.user.name}</h1></div>
          <div className="siteFilters flex">
            <div className="bar"></div>
              <div className="siteFilters flex">
              <div className="siteFilter btn">
                <span className="statSm">Projects {stats && stats.projects}</span>
              </div>
              <div className="siteFilter btn">
              <span className="statSm">Deliverables {stats && stats.subs}</span>
            </div>
            <div className="siteFilter btn">
            <span className="statSm">Tasks {stats && stats.tasks}</span>
          </div>
            </div>

          </div>
      </span>
            <div className="userProjects">
            {projects && projects.map((obj, i) => (
                <span className="userProject" key={'project_' + i}>
                    <h2 className={(obj.subs && (obj.subs.length > 0)) ? "btn" : "noBtn"} onClick={() => handleSubRow("subProjectRow" + obj.id)}>{obj.name}</h2>

                    {obj.icon && <img src={obj.icon} alt="icon" className="projectIcon" />}
                    <span className="userStats">
                    <span className="item">Deliverables: {obj.stats.subs}</span>
                    <span className="item">Tasks: {obj.stats.tasks}</span>
                
                     </span>
                    <div className="userProject1" id={"subProjectRow" + obj.id}>
                        {obj.subs && obj.subs.map((obj1, i) => (
                            <span key={'project_' + i}>
                                <h3>{obj1.name}</h3>
                                {obj1.icon && <img src={obj1.icon} alt="icon" className="projectIcon" />}
                               
                                {obj1.tasks && 
                                    <ul>
                                    {obj1.tasks.map((obj2, i) => (
                                    <li key={"tasks_" + i}><span  className="userProject2" key={'project_' + i}>
                                        <h4>{obj2.name}</h4>
                                        {obj2.icon && <img src={obj2.icon} alt="icon" className="projectIcon"  />}
                                    </span></li>
                                ))}
                                </ul>}
                            </span>
                        ))}
                    </div>
                </span>
            ))}
            </div>
     
        </section>
      </CSSTransition>
    );
}

export default ProjectPeopleDetail;


// {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}



// <div className="filters">
// <div className="filterBtn">Date</div>
// <div className="filterBtn">Date</div>
// </div>