import React, { useState, useEffect, useRef } from "react";
import {
  Link,
  NavLink
} from "react-router-dom";
import { gsap } from "gsap";
import { CSSTransition } from 'react-transition-group';
import { getStatic } from '../static';
import SVG from 'react-inlinesvg';
import {ReactComponent as Placeholder} from "../static/svg/text.below.photo.svg"
import {ReactComponent as ArrowUpCircle} from "../static/svg/arrow.up.circle.fill.svg"
import {ReactComponent as ArrowDownCircle} from "../static/svg/arrow.down.circle.fill.svg"
import {ReactComponent as Checkmark} from "../static/svg/checkmark.circle.fill.svg";
import {ReactComponent as WrenchScrewdriver} from "../static/svg/wrench.and.screwdriver.fill.svg"
import {ReactComponent as Exclamation} from "../static/svg/exclamationmark.triangle.fill.svg";
import {ReactComponent as HourGlass} from "../static/svg/hourglass.svg";
import {ReactComponent as Pencil} from "../static/svg/square.and.pencil.svg";
import {ReactComponent as Clear} from "../static/svg/clear.svg"
import {ReactComponent as Folder} from "../static/svg/folder.fill.svg"
import {ReactComponent as People} from "../static/svg/person.2.svg"
import {ReactComponent as Loader} from "../static/svg/loader.svg";

import Cancel from "../static/svg/close.svg";

import {
  useParams
} from "react-router-dom";
import axios from "axios";

import imagePlaceholder from '../static/img/image.png'

import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "../constants.js";
if (IS_LIVE) {
  var base_url = LIVE_HOSTS.base_url + '/api/'
} else {
  var base_url = LOCAL_HOSTS.base_url + '/api/'
}


function Project(props) {
  
    const [projects, setProjects] = useState([]);
    const [allProjects, setAllProjects] = useState();
    const [proposals, setProposals] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [client, setClient] = useState();
    const [dev, setDev] = useState(0);
    const [up, setUp] = useState(0);
    const [down, setDown] = useState(0);
    const [onGoing, setOnGoing] = useState([]);
    const [group, setGroup] = useState();
    const [cancel, setCancel] = useState(false);
    const [category, setCategory] = useState();
    const [dropDown, setDropDown] = useState();
    const [filterVal, setFilterVal] = useState();
    const [showLoader, setShowLoader] = useState(false);

    const nodeRef = React.useRef(null);
    const statusNewRef = React.useRef(null);
  
    // const projectForm = useRef();

    function fetchJSON() {
        try {
          setShowLoader(true);
            const get_url = base_url + "project-grid/";
            axios({
                method: "get",
                url: get_url,
                headers: {
                  Authorization: 'Token ' + props.token
                },
            }).then((response) => {
                const assetJSON = response.data;
                const projectsJSON = assetJSON.filter(item => item.active === true);
                let inProgress = projectsJSON.filter(function(obj) { return obj.status === 1; });
                let inCompleted = projectsJSON.filter(function(obj) { return obj.status === 2; });
                let inDown = projectsJSON.filter(function(obj) { return obj.status === 3; });
                setDev(inProgress);
                setDown(inDown);
                setUp(inCompleted);
                console.log('projectsJSON', projectsJSON)
                // const companyJSON = projectsJSON.filter(obj => obj.company === props.teamUser['company']);
                setProjects(projectsJSON.filter(a => ![2,7].includes(a.status)));
                setAllProjects(projectsJSON);
                setShowLoader(false);
                setLoaded(true);
            });
        } catch (error) {
            console.error(error);
        }

        try {
          const get_url = base_url + "team-user/";
          axios({
              method: "get",
              url: get_url,
              headers: {
                Authorization: 'Token ' + props.token
              },
          }).then((response) => {
              const result = response.data;
              props.setTeamUsers(result);
              console.log('team users', result);
          });
      } catch (error) {
          console.error(error);
      }

      try {
        const get_url = base_url + "status/";
        axios({
            method: "get",
            url: get_url,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
            const result = response.data;
            props.setStatuses(result);
        });
      } catch (error) {
          console.error(error);
      }
      
    }

    useEffect(() => {
      if (props.token) {
        fetchJSON();
        console.log('project props', props);
        
      }
      
    }, [props.token]);

    function createProject(){
      console.log('createProject');
      setLoaded(false);
      let formData = {};
      formData['name'] = document.getElementById('projectName').value;
      if (statusNewRef.current) {
        formData['status'] = statusNewRef.current
      }
      try {
        const url = base_url + "project-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          fetchJSON();
          statusNewRef.current = null;
          window.location.href = "/projects/" + response.data['id']
          console.log('response', response)

        });
      } catch (error) {
          console.error(error);
      }
    }

    function handleCancel() {
      fetchJSON();
      setGroup(null);
      setClient(null);
      setCancel(false);
      setCategory();
    }

    
    function filterBy(field, value, name) {
     
      setFilterVal(value);
      switch(field) {
        case "status":
          let h = document.getElementById('filterHeading_' + field);
          if (value) {
            const projectsFiltered = allProjects.filter(item => item.status === value);
            setProjects(projectsFiltered);
          } else {
            setProjects(allProjects);
          }
          h.innerHTML = name;
          setDropDown(null);
          break;
        case "ongoing":
          let h2 = document.getElementById('filterHeading_status');
          if (value) {
            const projectsFiltered = allProjects.filter(item => item.on_going === true);
            setProjects(projectsFiltered);
          } else {
            setProjects(allProjects);
          }
          h2.innerHTML = name;
          setDropDown(null);
          break;
        default:
          setDropDown(null);
          setProjects(allProjects);
      }
    }

    function cancelAdd() {
      let form = document.getElementById('addProjectForm');
      gsap.to('#addProjectForm', {opacity: 0, duration: 0.3});
      gsap.to('#addProjectForm', {zIndex:-1, height: 0, duration: 0, delay: 0.3});
      form.classList.remove('on');
    }

    function startAdd(){
      let form = document.getElementById('addProjectForm');
  
      if (form.classList.contains('on')) {
        cancelAdd();
      } else {
        gsap.to('#addProjectForm', {zIndex:4, height: 'auto', duration: 0});
        gsap.to('#addProjectForm', {opacity: 1, duration: 0.3});
        form.classList.add('on');
      }
      
    }



    function handleKeyPress(e, fieldId, fieldValue) {
      if (e.key === "Enter") {
        if (fieldId === 'project_name') {
          createProject();
          cancelAdd();
        }
        
      }
    }

    function animDropDown(open){
      if (open) {
        var tl = gsap.timeline();
        tl.to("#inputField_" + dropDown + " .dropDownChoices", {height: "auto", duration: 0});
        tl.to("#inputField_" + dropDown, {zIndex: 4, height: "auto", duration: 0}, "<");
        tl.to("#inputField_" + dropDown, {scale: 1, opacity: 1, duration: 0.1});
      } else {
        gsap.to(".dropDownChoices", {height: 0, duration: 0});
        gsap.to(".dropDownField", {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
      }
    }

    function handleDropDown(e, field) {
      if (field === dropDown) {
        setDropDown(null); 
      } else {
        setDropDown(field)
      }
    }

    function handleNewStatus(objId) {
      statusNewRef.current = objId;
      setDropDown(null);
    }

    useEffect(() => {
      if (dropDown) {
        animDropDown(true)
      } else {
        animDropDown(false)
      }
    }, [dropDown])


   

    return (
      <React.Fragment>
      {showLoader && <div className="loader"><Loader /></div>}
      <CSSTransition  nodeRef={nodeRef} in={loaded} unmountOnExit timeout={200} classNames="pageItem">
        <section className="main assets" ref={nodeRef}>
         
          <div className="userForm modalForm" id="addProjectForm">
            <div className="modal">
            <div className="formBody">
              <div className="formField">
                <h2>Create a Project</h2>
                <span className="inputField">
                  <input type="text" name="name" placeholder="Name" autoComplete="off" id="projectName" onKeyUp={(e) => handleKeyPress(e, "project_name", null)} />
                  <span className="smCancel btn" onClick={() => cancelAdd()}><Clear /></span>
                </span>
                <span className="inputField">
                   <span className="fieldValue btn" onClick={(e) => handleDropDown(e, "status_new")}>
                    <span id="fieldValue_status_new">{statusNewRef.current ? (<span>
                      {props.statuses && props.statuses.filter(obj => obj.id === statusNewRef.current).map((item, i) => (
                        <span className="statusIcon" key={'status_' + i} >
                            <SVG src={item.icon} className="projectIcon" />
                            <span className="iconName">{item.name}</span></span>
                          ))}</span>) : <span className="fieldCta">Add Status</span>}</span>
                        </span>

                      <span className="inputField dropDownField" id="inputField_status_new">
                        <span className={"dropDown"}>
                          <span className="dropDownChoices statusWithName">

                            {props.statuses && props.statuses.map((item, i) => (
                              <span key={'dropdownStatus_new_' + i} className={"dropDownChoice gridChoice btn"} onClick={() => handleNewStatus(item.id)}>
                                <SVG key={'statusDd_' + i} src={item.icon} className="projectIcon" />
                                <span className="iconName">{item.name}</span>
                                </span>
                            ))}
                            
                          </span>
                        </span>
                      </span>
                    </span>
                    </div>
              </div>
            </div>
          </div>

          <div className="column">

          <div className="filters">
            <div className="siteFilters flex">
              <div className="siteFilter btn" onClick={() => filterBy('status', 1, 'In Progress')}>
                <span className="icon"><WrenchScrewdriver /></span>
                <span className="statSm">{dev.length}</span>
            </div>
            <div className="siteFilter btn" onClick={() => filterBy('status', 3, 'Blocked')}>
              <span className="icon"><Exclamation /></span>
              <span className="statSm">{down.length}</span>
            </div>
            <div className="siteFilter btn" onClick={() => filterBy('status', 2, 'Completed')}>
                <span className="icon"><Checkmark /></span>
                <span className="statSm">{up.length}</span>
            </div>
           
           
          
          <div className="bar"></div>
            <div className="filterField">
            <div className="btn sortBtn" onClick={(e) => handleDropDown(e, 'status')} id="filterHeading_status">Status</div>
            <span className="inputField dropDownField" id="inputField_status">
              <span className="dropDown">
                <span className="dropDownChoices">
                  {props.statuses && props.statuses.map((obj, i) => (
                    <span className={"dropDownChoice btn"} key={'statusDD_' + i} onClick={() => filterBy('status', obj.id, obj.name)}>{obj.name}</span>
                  ))}
                  <span className={"dropDownChoice btn"} onClick={() => filterBy('ongoing', true, 'Ongoing')}>Ongoing</span>
                  <span className={"dropDownChoice btn"} onClick={() => filterBy('status', null, 'Status')}>All</span>
                </span>
              </span>
            
            </span> 
            </div>
          <div className="bar"></div>
            
            <div className="siteFilter btn">
              <input type="text" className="search" placeholder="Search ..." />
            </div>
            <NavLink
            key={"/projects/people"}
            to={"/projects/people"}
            >
            <People className="icon btn"/>
            </NavLink>
            
            {cancel && <div onClick={() => handleCancel()} className="clearBtn btn"><Clear /></div>}
          </div>
          <div className="groupHeading">
            {group && <h2>{group}</h2>}
            </div>
            
            <span onClick={() => startAdd()}><div className="userBtn btn"><Pencil /></div></span>
        </div>
          
            <div className="itemHeader">
              <span className="thumb">Project</span>
              <span className="item"></span>
              <span className="item">Start Date</span>
              <span className="item">Client</span>
              <span className="item">Status</span>
              <span className="item">End Date</span>
            </div>
            
            <div className={"mainArray projectsArray arrayLength_" + projects.length}>
                {projects.map((item, i) => (
                  <Link 
                    className={"siteBtn"} 
                    key={"service_" + i} 
                    to={item.get_absolute_url}
                    >
                    {(item.thumbnail)?<img className="thumb" src={getStatic(item.thumbnail)} alt="" />:<figure className="thumb" alt="" />}
                    <span className="blockBottomRow">
                    <span className="statusIcon">
                    {props.statuses && props.statuses.filter(obj => obj.id === item.status).map((item, i) => (
                      <span key={'status_' + i} className="iconObj">
                        <SVG src={item.icon} className="projectIcon" />
                      </span>
                    ))}
                  
                    </span>
                    <span className="initials">
                    {item.team && item.team.map((item, j) => (
                      <span key={'owner_' + j} className={"teamInitial" + ((j === 0)?(" ownerInitial"):"")}>{item.initials}</span>
                    ))} 
                    </span>
                  
                  </span>
                    <span className="projectBtnText">
                      <span className="item name">{item.name}</span>
                      {item.client_name ? <span className="item">{item.client_name}</span> : ""}
                    </span>
                  </Link>
                ))}
            </div>


          </div>
          
        

          
        </section>
      </CSSTransition>
      </React.Fragment>
    );
}

export default Project;


// {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}



// <div className="filters">
// <div className="filterBtn">Date</div>
// <div className="filterBtn">Date</div>
// </div>