import axios from 'axios'

const postEvent = (username, data, market, category) => {
 
    var base_url = 'https://cdn.origin.track.udxd.co/api/';

    // console.log('market', market)

    const postData = async () => {
        // console.log('postEvent', ip, isElectron, os)
        try {
            const url = base_url + "site-ping/";
            let postData =  {
                "site_id": "UDXD-TW87580H",
            };
           
            axios({
                method: "post",
                url: url,
                data: postData
            }).then((response) => {
                console.log("postEvent", response['data'])
             
            });
        } catch (error) {
            console.error(error);
        }
    }
    postData();
}

export default postEvent

