import React, { useState, useEffect,useRef } from "react";
import gsap from "gsap";
import SVG from 'react-inlinesvg';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {ReactComponent as Checkmark} from "../static/svg/checkmark.circle.fill.svg";
import {ReactComponent as Pencil} from "../static/svg/square.and.pencil.svg";
import {ReactComponent as Clear} from "../static/svg/clear.svg"
import {ReactComponent as Loader} from "../static/svg/loader.svg";
import {ReactComponent as ChevronBack} from "../static/svg/chevron.backward.svg";
import {ReactComponent as Cancel} from "../static/svg/cancel_icon.svg";

function HtmlText(props) {
    const tempTextObj = useRef(props.obj);
    const userTyped = useRef(null);

    const [editField, setEditField] = useState(null);
    
    function onCancel() {
        setEditField(null)
        tempTextObj.current = props.obj;
    }

    function onSave(){
        props.onNewObj(tempTextObj.current)
        onCancel()
    }
     
    return (
        <span className="inputField">
        {(editField === "description") ? 
        <div>


            <CKEditor
            editor={ ClassicEditor }
            id={"description"} 
            data={props.obj}
            config={{
            toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
            link: { addTargetToExternalLinks: true }
            }}
            onReady={() => {
            tempTextObj.current = null;
            userTyped.current = false;
            }}
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                // console.log( { event, editor, data } );
                tempTextObj.current = data;
                userTyped.current = true;
            } }
            /> 

            <span className="smCancel btn textarea">
            <Checkmark onClick={() => onSave()} />
            <Cancel onClick={() =>onCancel()} />
            </span>
   
        </div> :
        (props.obj ? <span onClick={() =>setEditField("description")} dangerouslySetInnerHTML={ {__html: props.obj} } /> : <span className={"fieldCta"} onClick={() =>setEditField("description")}>Add {props.label}</span>)
        }
        </span>

        )

}

export default HtmlText;




