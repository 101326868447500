import React, { useState, useEffect,useRef } from "react";
import gsap from "gsap";
import SVG from 'react-inlinesvg';

function DropDown(props) {
    const ddRef = useRef();
    const ddChoicesRef = useRef();
    const ddActive = useRef(false);

    function animDropDown(){
      let yPos = 0;
        if (props.offsetY) {
            yPos = props.offsetY
        }
        if (!ddActive.current) {
            ddActive.current = true;
          var tl = gsap.timeline();
          tl.to(ddChoicesRef.current, {height: "auto", duration: 0, y: yPos});
          tl.to(ddRef.current, {zIndex: 4, height: "auto", duration: 0}, "<");
          tl.to(ddRef.current, {scale: 1, opacity: 1, duration: 0.1});
        } else {
           
          gsap.to(".dropDownChoices", {height: 0, duration: 0});
          gsap.to(".dropDownField", {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
          ddActive.current = false;
        }
      }

      function handleDropDown(objId) {
        props.onClick(objId)
        animDropDown()
      }

      useEffect(() => {
        console.log('active', props.active)
  
        
      }, [props.active])
  
     
    return (
            
            <span className="inputField">
            <span className="btn" onClick={(e) => animDropDown()}>
                <span id="fieldValue_status_new">
                {props.active ? (<span>
                {props.objs && props.objs.filter(obj => obj.id === props.active).map((item, i) => (
                <span className="statusIcon" key={'status_' + i} >
                    <SVG src={item.icon} className="projectIcon" />
                    <span className="iconName">{item.name}</span></span>
                    ))}</span>) : <span className="fieldCta">Add {props.label}</span>}
                </span>
            </span>

            <span className="inputField dropDownField" ref={ddRef}>
            <span className={"dropDown"}>
                <span className="dropDownChoices statusWithName" ref={ddChoicesRef}>

                {props.objs && props.objs.map((item, i) => (
                    <span key={'dropdown_new_' + i} className={"dropDownChoice btn"} onClick={() => handleDropDown(item.id)}>
                    {item.icon && <SVG key={'statusDd_' + i} src={item.icon} className="projectIcon" />}
                    <span className="iconName">{item.name}</span>
                    </span>
                ))}
                
                </span>
            </span>
            </span>
            </span>
        )

}

export default DropDown;