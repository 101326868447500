import React, { useState, useEffect } from "react";
import {
  Link,
  useSearchParams
} from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import SVG from 'react-inlinesvg';
import { getStatic } from '../static';
import Loader from "../static/svg/loader.svg";
import Close from "../static/svg/close.svg";
import CloseDelete from "../static/svg/close-delete.svg";
import LeftArrow from "../static/svg/leftarrow.svg";
import Lock from "../static/svg/lock.svg";
import Unlock from "../static/svg/unlock.svg";
import OpenLink from "../static/svg/openLink-w.svg";

import {
  useParams
} from "react-router-dom";
import axios from "axios";

import imagePlaceholder from '../static/img/image.png'


import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "../constants.js";
if (IS_LIVE) {
  var base_url = LIVE_HOSTS.base_url + '/api/'
} else {
  var base_url = LOCAL_HOSTS.base_url + '/api/'
}

function Home(props) {
  const nodeRef = React.useRef(null);
    const [projects, setProjects] = useState([]);
    const [loaded, setLoaded] = useState(false);

    function fetchJSON() {
        try {
            const get_url = base_url + "user-project-grid/";
            axios({
                method: "get",
                url: get_url,
                headers: {
                  Authorization: 'Token ' + props.token
                },
            }).then((response) => {
                const assetJSON = response.data;
                setProjects(assetJSON);
                setLoaded(true);
            });
        } catch (error) {
            console.error(error);
        }

        try {
          const get_url = base_url + "team-user/";
          axios({
              method: "get",
              url: get_url,
              headers: {
                Authorization: 'Token ' + props.token
              },
          }).then((response) => {
              const result = response.data;
              props.setTeamUsers(result);
              console.log('team users', result);
          });
      } catch (error) {
          console.error(error);
      }

      try {
        const get_url = base_url + "status/";
        axios({
            method: "get",
            url: get_url,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
            const result = response.data;
            props.setStatuses(result);
        });
      } catch (error) {
          console.error(error);
      }
      
    }

    useEffect(() => {
      if (props.token) {
        fetchJSON();
        console.log('props', props)
      }
    }, [props.token]);
   

    return (
      <CSSTransition in={loaded} timeout={200} unmountOnExit classNames="pageItem" nodeRef={nodeRef}>
        <section className="main" ref={nodeRef}>
          <div className="column">
          <div className="homeRow">
          <div className="groupHeading">
            <h2>My Projects</h2>
          </div>
          <div className={"mainArray projectsArray arrayLength_" + projects.length}>
          {projects.filter(a => a.status !== 2).map((item, i) => (
            <Link 
              className={"siteBtn"} 
              key={"service_" + i} 
              to={item.get_absolute_url}
              >
              {(item.thumbnail)?<img className="thumb" src={getStatic(item.thumbnail)} alt="" />:<figure className="thumb" alt="" />}
              <span className="blockBottomRow">
              <span className="statusIcon">
              {props.statuses && props.statuses.filter(obj => obj.id === item.status).map((item, i) => (
                <span key={'status_' + i} className="iconObj">
                  <SVG src={item.icon} className="projectIcon" />
                </span>
              ))}
            
              </span>
              <span className="initials">
              {item.team && item.team.map((item, j) => (
                <span key={'owner_' + j} className={"teamInitial" + ((j === 0)?(" ownerInitial"):"")}>{item.initials}</span>
              ))} 
              </span>
            
            </span>
              <span className="projectBtnText">
                <span className="item name">{item.name}</span>
                {item.client_name ? <span className="item">{item.client_name}</span> : ""}
              </span>
            </Link>
          ))}
      </div>
      </div>
          </div>
        </section>
      </CSSTransition>
    );
}

export default Home;


// {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}



// <div className="filters">
// <div className="filterBtn">Date</div>
// <div className="filterBtn">Date</div>
// </div>