import React, { useState, useEffect } from "react";
import {
  Link
} from "react-router-dom";
import Cookies from 'js-cookie';

import { getStatic } from '../static';
import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "../constants.js";

import Loader from "../static/svg/loader.svg";
import Close from "../static/svg/close.svg";
import CloseDelete from "../static/svg/close-delete.svg";
import LeftArrow from "../static/svg/leftarrow.svg";
import Lock from "../static/svg/lock.svg";
import Unlock from "../static/svg/unlock.svg";
import OpenLink from "../static/svg/openLink-w.svg";

import {
  useParams
} from "react-router-dom";
import axios from "axios";

import imagePlaceholder from '../static/img/image.png'

// jackson
// Jpx7h*2#rtAr65Uu

if (IS_LIVE) {
  var base_url = LIVE_HOSTS.base_url
} else {
  var base_url = LOCAL_HOSTS.base_url
}

function Login() {

    function postLogin() {

        var username = document.getElementById('username');
        var password = document.getElementById('password');
        console.log('username', username.value, password.value);
        if (username.value && password.value) {
          axios({
            method: 'post',
            baseURL: base_url,
            url: '/api-token-auth/',
            data: {
              'username': username.value, 
              'password': password.value
            }
            })
            .then(response => {

                console.log('response', response);
                Cookies.set('radial_token', response['data'].token);
                axios({
                    method: 'get',
                    baseURL: base_url,
                    url: '/auth/users/me/',
                    headers: {
                        Authorization: 'Token ' + Cookies.get('radial_token')
                    }
                    })
                    .then(response => {
                      console.log('response', response)
                      window.location.href = "/";
                        // if (Cookies.get('bw_token')) {
                        //    console.log("token exists");
                        // } else {
                        //     console.log("token exists");
                        // }
                    });
                          
            })
            .catch(function (error) {
              if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              } else if (error.request) {
              console.log(error.request);
              } else {
              console.log('Error', error.message);
              }
              console.log(error.config);
          });
        }
		
    }
    useEffect(() => {
    //   fetchJSON();
    }, []);
   

    return (
      <section className="main pageItem-enter-active" id="loginFormPage">
   
          <div className="loginForm">
            <h1>Log In</h1> 
            <div className="formField">
                <input type="text" name="username" id="username" required placeholder="username" />
            </div>

            <div className="formField">
                <input type="password" name="password" id="password" required placeholder="password" />
            </div>
            <div className="formField">
                <input type="submit" className="btn submitBtn" value="Sign In" onClick={()=>postLogin()} id="loginSubmit" />
            </div>
          </div>
      </section>
    );
}

export default Login;


// {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}



// <div className="filters">
// <div className="filterBtn">Date</div>
// <div className="filterBtn">Date</div>
// </div>