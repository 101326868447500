import React, { useState, useEffect } from "react";
import {
  useParams
} from "react-router-dom";
import axios from "axios";

import { CSSTransition } from 'react-transition-group';
import { getStatic } from '../static';

import {ReactComponent as OpenIn} from "../static/svg/arrow.up.forward.svg";
import {ReactComponent as Link} from "../static/svg/link.svg";
import {ReactComponent as Cancel} from "../static/svg/cancel_icon.svg";
import {ReactComponent as Loader} from "../static/svg/loader.svg";
import {ReactComponent as Reload} from "../static/svg/reload.svg";
import {ReactComponent as Accessiblity} from "../static/svg/accessibility.svg";

// import imagePlaceholder from '../static/img/image.png'

import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "../constants.js";
let base_url = null;
if (IS_LIVE) {
  base_url = LIVE_HOSTS.base_url + '/api/'
} else {
  base_url = LOCAL_HOSTS.base_url + '/api/'
}


function Site(props) {
  const nodeRef = React.useRef(null);
   let {id} = useParams();

    const [site, setSite] = useState({});
    const [restartOn, setRestartOn] = useState(true);
    const [reloadOn, setReloadOn] = useState(true);
    const [renewCertbotOn, setRenewCertbotOn] = useState(true)
    const [response, setResponse] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [command, setCommand] = useState();
    const [run, setRun] = useState(false);


    function fetchJSON() {
        try {
            const get_url = base_url + "app/" + id;
            axios({
                method: "get",
                url: get_url,
                headers: {
                  Authorization: 'Token ' + props.token
                },
            }).then((response) => {
                const assetJSON = response.data;
                setSite(assetJSON);
                console.log("app", assetJSON)
                
            });
        } catch (error) {
            console.error(error);
        }
    }

    function runCommand(command) {
      setRestartOn(false);
      try {
          const get_url = base_url + "run-command/";
          axios({
              method: "post",
              url: get_url,
              data: {
                  "id": id,
                  "command": command
              }
              // headers: {
              //   Authorization: 'Token ' + Cookies.get('ud_token')
              // },
          }).then((response) => {
              console.log("restarted")
              setRestartOn(true);
              setResponse(response.data)
              setShowLoader(false);
          });
      } catch (error) {
          console.error(error);
      }
  }

  function renewCertbot(command) {
    // setRenewCertbotOn(false);
    try {
        const get_url = base_url + "renew-certbot/";
        axios({
            method: "post",
            url: get_url,
            data: {
                "id": id
            }
        }).then((response) => {
            // console.log("certbot", response['data'])
            // setRenewCertbotOn(true);
            let text = response['data'].join(' ');
            let success = text.includes("Congratulations");
            // console.log('text', text, text.includes("Congratulations"))
            if (success) {
              setResponse('Success')
            } else {
              setResponse(text)
            }
            setShowLoader(false);
        });
    } catch (error) {
        console.error(error);
    }
}

  function deployAws() {
    // setRenewCertbotOn(false);
    try {
        const get_url = base_url + "deploy-to-green/";
        axios({
            method: "post",
            url: get_url,
            data: {
                "id": id
            }
        }).then((response) => {
            console.log("certbot", response['data'])
            // setRenewCertbotOn(true);
            setResponse(response.data['msg']);
            setShowLoader(false);
        });
    } catch (error) {
        console.error(error);
    }
}

function handleAlert(command) {
  setCommand(command);
  setShowAlert(true)
}

function cancelCommand() {
  setCommand();
  setShowAlert(false);
}

function continueCommand() {
  setShowAlert(false);
  setRun(true);
  setShowLoader(true);
}

function reloadScreenshot() {
  try {
    const get_url = base_url + "run-screenshot/" + id;
    axios({
        method: "get",
        url: get_url,
    }).then((response) => {
        // setShowLoader(false);
        setResponse('Success');
        setShowLoader(false);
        fetchJSON();
    });
  } catch (error) {
      console.error(error);
  }
}

function calcDate(date) {
    var date1 = new Date(Date.now());
    var date2 = new Date(date);
    var diff = Math.floor(date1.getTime() - date2.getTime());
    var day = 1000 * 60 * 60 * 24;

    var days = Math.floor(diff/day);
    var months = Math.floor(days/31);
    var years = months/12;

    // var message = date2.toDateString();
    // message += " was "
    // message += days + " days " 
    // message += months + " months "
    var message = years;

    return message
  }

  


    useEffect(() => {
      if (props.token) {
        fetchJSON();
        // console.log('props.token', props.token)
      }
    }, [props.token]);
   
    useEffect(() => {
      (site && 
        setLoaded(true)
        
        )
    }, [site]);



    useEffect(() => {
      if (run && command) {
        if ((command !== "renew-certbot")&&(command !== 'ping')) {
          runCommand(command);
        } else if (command === "renew-certbot") {
          renewCertbot(command);
        } else if (command === "ping") {
          reloadScreenshot()
        } else {
          // pass
        }

        setRun(false);
      }
    }, [command, run])

    return (
      <CSSTransition in={loaded} timeout={0} classNames="pageItem" nodeRef={nodeRef}>
        <section className="main detail" ref={nodeRef}>
          {showLoader && <div className="loader"><Loader /></div>}
          {showAlert && 
            <section className="alert">
              <h2>Are You Sure?</h2>
              <div className="buttons">
                <div className="button"  onClick={() => continueCommand(true)}>Yes</div>
                <div className="button on" onClick={() => cancelCommand()}>No</div>
              </div>
              
            </section>
          }
          <div className="column detailColumns">
            <div className="leftColumn">
              <div className="heading"><h1>{site.name}</h1>{site.url && <a href={'//' + site.url} rel="noreferrer" target="_blank"><OpenIn /></a>}</div>

              <div className="projectFields assetFields">
                <div className="projectField">
                  <span className="heading">Status</span>
                  <span className="field">{site.status_name}</span>
                </div>
                <div className="projectField">
                  <span className="heading">Ping</span>
                  <span className="field">{site.ping_datetime}</span>
                </div>
                <div className="projectField">
                  <span className="heading">Client</span>
                  <span className="field">{site.client_name}</span>
                </div>
                <div className="projectField">
                  <span className="heading">Created</span>
                  <span className="field">{site.date}</span>
                </div>
                <div className="projectField">
                  <span className="heading">Stack</span>
                  <span className="field">{site.server} | {site.webserver} | {site.platform}</span>
                </div>
                <div className="projectField">
                <span className="heading">AGE</span>
                <span className="field">{calcDate(site.date)}</span>
              </div>
                <div className="projectField">
                  <span className="heading">IP</span>
                  <span className="field">{site.ip}</span>
                </div>
                <div className="projectField">
                  <span className="heading"><Accessiblity className="icon" /></span>
                  <span className="field">{(site.accessibility)?site.accessibility:"?"}</span>
                </div>
              </div>
              {(site.note)?
              (<div className="note">
                <div className="heading">Note</div>
                <div className="text">{site.note}</div>
              </div>):("")}
              
              {(site.hosting && site.hosting.length > 0) ?
                (<div className="subAssetArray">
                  <div className="heading">HOSTING FINANCIALS</div>
                  <div className="subRow itemHeader assetRow">
                    <span className="subItem">Period</span>
                    <span className="subItem">Cost</span>
                    <span className="subItem">Invoice</span>
                    <span className="subItem">Invoice Date</span>
                  </div>
          
                    {site.hosting.map((item, i) => (
                      <div className={"status"} id={"subitem_" + i} key={'hostingItem_' + i}>
                        <div className="subRow assetRow">
                            <span className="subItem">{item.start_date} - {item.end_date}</span>
                            <span className="subItem">${item.hosting_cost}</span>
                            <span className="subItem">${item.invoice_amt}</span>
                            <span className="subItem">{item.invoice}</span>
                        </div>
    
                      </div>
                    ))}
              
      
                </div>):("")}

            </div>
            <div className="rightColumn">
            <a href={"//" + site.url} target="_blank" rel="noreferrer">{(site.thumbnail)?<img className="screenshot" src={getStatic(site.thumbnail)} alt="" />:<figure className="screenshot" alt="" />}</a>

            <div className="reloadRow" style={{display: 'none'}}><Reload className="icon" onClick={() => reloadScreenshot()}/></div>
                
              <div className="heading">SERVER</div>
              <div className="text">
              
                <div className="projectField">
                  <span className="heading">HOST</span>
                  <span className="field">{(site.hosting_company_name)?(<span className="fieldwIcon">{site.hosting_company_name} <a href={site.hosting_url} rel="noreferrer"  target="_blank"><OpenIn className="icon" /></a></span>):("—")}</span>
                </div>
                <div className="projectField">
                <span className="heading">USERNAME</span>
                <span className="field">{(site.hosting_username)?(site.hosting_username):("—")}</span>
              </div>
              
              <div className="projectField">
              <span className="heading">COST/M</span>
              <span className="field">{(site.hosting_cost > 0)?("$" + site.hosting_cost):("—")}</span>
              </div>
     
              </div>
                      
              <div className="heading">ADMIN ACTIONS</div>
              <div className="buttons">
                    
                {(site.restart_server)?<div className={"button" + ((restartOn)?(""):(" loading"))} onClick={() => handleAlert('restart-server')}>Restart Server</div>:null}
                {(site.restart_database)?<div className={"button" + ((restartOn)?(""):(" loading"))} onClick={() => handleAlert('restart-database')}>Restart Database</div>:null}
                {(site.reload_django)?<div className={"button" + ((reloadOn)?(""):(" loading"))} onClick={() => handleAlert('reload-django')}>Reload Django</div>:null}
                {(site.renew_certbot)?<div className={"button" + ((reloadOn)?(""):(" loading"))} onClick={() => handleAlert('renew-certbot')}>Renew Certbot SSL</div>:null}
                {(site.id === 1)?<div className={"button" + ((reloadOn)?(""):(" loading"))} onClick={() => deployAws()}>Blue/Green Swap</div>:null}
                <div className={"button" + ((reloadOn)?(""):(" loading"))} onClick={() => handleAlert('ping')}>Ping</div>
              </div>

             { response && <div className="serverResponse">
                {response}
                <span className="smCancel btn" onClick={() => setResponse("")}><Cancel/></span>
              </div> }
            </div>
            </div>
        </section>
      </CSSTransition>
    );
}

export default Site;


// {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}



