import React, { useState, useEffect, useRef } from "react";
import {
  Link,
  NavLink
} from "react-router-dom";
import { gsap } from "gsap";
import { CSSTransition } from 'react-transition-group';
import { getStatic } from '../static';
import SVG from 'react-inlinesvg';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DropDown from './module.dropdown.js'
import HtmlText from './module.htmltext.js'

import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';

import {ReactComponent as Placeholder} from "../static/svg/text.below.photo.svg"
import {ReactComponent as ArrowUpCircle} from "../static/svg/arrow.up.circle.fill.svg"
import {ReactComponent as ArrowDownCircle} from "../static/svg/arrow.down.circle.fill.svg"
import {ReactComponent as Checkmark} from "../static/svg/checkmark.circle.fill.svg";
import {ReactComponent as WrenchScrewdriver} from "../static/svg/wrench.and.screwdriver.fill.svg"
import {ReactComponent as Exclamation} from "../static/svg/exclamationmark.triangle.fill.svg";
import {ReactComponent as HourGlass} from "../static/svg/hourglass.svg";
import {ReactComponent as Pencil} from "../static/svg/square.and.pencil.svg";
import {ReactComponent as Clear} from "../static/svg/clear.svg"
import {ReactComponent as Folder} from "../static/svg/folder.fill.svg"
import {ReactComponent as People} from "../static/svg/person.2.svg"
import {ReactComponent as Loader} from "../static/svg/loader.svg";

import Cancel from "../static/svg/close.svg";

import {
  useParams
} from "react-router-dom";
import axios from "axios";

import imagePlaceholder from '../static/img/image.png'

import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "../constants.js";
if (IS_LIVE) {
  var base_url = LIVE_HOSTS.base_url + '/api/'
} else {
  var base_url = LOCAL_HOSTS.base_url + '/api/'
}


function Tickets(props) {
  
    const [tickets, setTickets] = useState([]);
    const [allTickets, allAllTickets] = useState();
    const [loaded, setLoaded] = useState(false);
    const [client, setClient] = useState();
    const [dev, setDev] = useState(0);
    const [up, setUp] = useState(0);
    const [down, setDown] = useState(0);
    const [onGoing, setOnGoing] = useState([]);
    const [group, setGroup] = useState();
    const [cancel, setCancel] = useState(false);
    const [category, setCategory] = useState();
    const [dropDown, setDropDown] = useState();
    const [filterVal, setFilterVal] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [apps, setApps] = useState([]);
    const [categories, setCategories] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [clients, setClients] = useState([]);
    const [newCategory, setNewCategory] = useState(null);
    const [newClient, setNewClient] = useState(null);
    const [newDesc, setNewDesc] = useState(null);
    const [newProject, setNewProject] = useState(null);
    const [newFiles, setNewFiles] = useState(null);
    const [results, setResults] = useState();
    const [ticketResults, setTicketResults] = useState();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [newStartTime, setNewStartTime] = useState(null);
    const [newEndTime, setNewEndTime] = useState(null);

    const nodeRef = useRef(null);
    const categoryNewRef = useRef(null);
    const clientNewRef = useRef(null);
    const ticketNameTemp = useRef();
    // const projectForm = useRef();

    function fetchClients() {
      try {
          const get_url = base_url + "client/";
          axios({
              method: "get",
              url: get_url,
              headers: {
                Authorization: 'Token ' + props.token
              },
          }).then((response) => {
              const responseJSON = response.data;
              setClients(responseJSON);
              console.log('clients fetched', responseJSON);
          });
      } catch (error) {
          console.error(error);
      }
    }

    function updateTickets(ticketsJSON){
      let inProgress = ticketsJSON.filter(function(obj) { return obj.status === 'In-Progress'; });
      let inCompleted = ticketsJSON.filter(function(obj) { return obj.status === 'Done'; });
      let inDown = ticketsJSON.filter(function(obj) { return obj.status === 'Returned'; });
      setDev(inProgress);
      setDown(inDown);
      setUp(inCompleted);
      console.log('ticketsJSON', ticketsJSON)
      // const companyJSON = ticketsJSON.filter(obj => obj.company === props.teamUser['company']);
      setTickets(ticketsJSON);
    }

    function fetchJSON() {
        try {
          setShowLoader(true);
            const get_url = base_url + "ticket/";
            axios({
                method: "get",
                url: get_url,
                headers: {
                  Authorization: 'Token ' + props.token
                },
            }).then((response) => {
                const assetJSON = response.data;
                const ticketsJSON = assetJSON;
                updateTickets(ticketsJSON);
                allAllTickets(ticketsJSON);
                setShowLoader(false);
                setLoaded(true);
            });
        } catch (error) {
            console.error(error);
        }

        try {
          const get_url = base_url + "team-user/";
          axios({
              method: "get",
              url: get_url,
              headers: {
                Authorization: 'Token ' + props.token
              },
          }).then((response) => {
              const result = response.data;
              // props.setTeamUsers(result);
              console.log('team users', result);
          });
      } catch (error) {
          console.error(error);
      }

      try {
        const get_url = base_url + "ticket-status/";
        axios({
            method: "get",
            url: get_url,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
            const result = response.data;
            setStatuses(result);
        });
      } catch (error) {
          console.error(error);
      }

      try {
        const get_url = base_url + "ticket-categories/";
        axios({
            method: "get",
            url: get_url,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
            const result = response.data;
            setCategories(result)
            // props.setStatuses(result);
        });
      } catch (error) {
          console.error(error);
      }

      try {
        const get_url = base_url + "app-flat/";
        axios({
            method: "get",
            url: get_url,
            headers: {
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
            const result = response.data;
            setApps(result)
            console.log('apps', result)
            // props.setStatuses(result);
        });
      } catch (error) {
          console.error(error);
      }
      
      fetchClients();

    }

    useEffect(() => {
      if (props.token) {
        fetchJSON();
        console.log('ticket props', props);
        
      }
      
    }, [props.token]);

    useEffect(() => {
      let l;
      if (showDatePicker !== false) {
        l = document.addEventListener('click', function(event) {  
          if (event.target.closest(".dateFilter")) return 
          setShowDatePicker(false)
        } , true);
        
      } else {
       
        document.removeEventListener("click", l);
      }
      
    }, [showDatePicker])

    useEffect(() => {
      // if (newStartTime)
      setShowDatePicker(false)
    }, [newStartTime, newEndTime])

    function createTicket(){
      console.log('createProject');
      setLoaded(false);
      let formData = new FormData();
      let fileList = null;
      let fileInput = document.getElementById('ticketFiles');
      if (fileInput) {
        fileList = fileInput.files;
      }
      formData.append('heading', document.getElementById('newHeading').value)
      if (newCategory) {
        // formData['category'] = newCategory
        formData.append('category', newCategory)
      }
      if (newClient) {
        // formData['client'] = newClient
        formData.append('client', newClient)
      }
      if (newDesc) {
        // formData['description'] = newDesc
        formData.append('description', newDesc)
      }
      if (newProject) {
        // formData['project'] = newProject[0]
        formData.append('project', newProject[0])
      }
      if (newStartTime) {
        const datePattern = 'yyyy-MM-dd HH:mm:ss';
        let dateFormatted = format(new Date(newStartTime), datePattern);
        formData.append('datetime', dateFormatted)
      }
      if (newEndTime) {
        const datePattern = 'yyyy-MM-dd HH:mm:ss';
        let dateFormatted = format(new Date(newEndTime), datePattern);
        formData.append('datetime_end', dateFormatted)
      }
      if (fileList) {
        for (var i = 0; i < fileList.length; i++) {
          formData.append('ticket_files', fileList[i], fileList[i].name)
        }
        
        // formData["ticket_files"] = formFiles
      
      }
      try {
        const url = base_url + "ticket-edit/";
        axios({
            method: "post",
            url: url,
            data: formData,
            headers: {
              'Content-Disposition': 'attachment; filename=ticket_files',
              Authorization: 'Token ' + props.token
            },
        }).then((response) => {
          
          fetchJSON();
          categoryNewRef.current = null;
          window.location.href = "/support/" + response.data['id']
          console.log('response', response)

        });
      } catch (error) {
          console.error(error);
      }
    }

    function handleCancel() {
      fetchJSON();
      setGroup(null);
      setClient(null);
      setCancel(false);
      setCategory();
    }

    
    function filterBy(field, value, name) {
     
      setFilterVal(value);
      switch(field) {
        case "status":
          let h = document.getElementById('filterHeading_' + field);
          if (value) {
            const projectsFiltered = allTickets.filter(item => item.status === value);
            setTickets(projectsFiltered);
          } else {
            setTickets(allTickets);
          }
          h.innerHTML = name;
          setDropDown(null);
          break;
        case "ongoing":
          let h2 = document.getElementById('filterHeading_status');
          if (value) {
            const projectsFiltered = allTickets.filter(item => item.on_going === true);
            setTickets(projectsFiltered);
          } else {
            setTickets(allTickets);
          }
          h2.innerHTML = name;
          setDropDown(null);
          break;
        default:
          setDropDown(null);
          setTickets(allTickets);
      }
    }

    function cancelAdd() {
      let form = document.getElementById('addTicketForm');
      gsap.to('#addTicketForm', {opacity: 0, duration: 0.3});
      gsap.to('#addTicketForm', {zIndex:-1, height: 0, duration: 0, delay: 0.3});
      form.classList.remove('on');
    }

    function startAdd(){
      let form = document.getElementById('addTicketForm');
  
      if (form.classList.contains('on')) {
        cancelAdd();
      } else {
        gsap.to('#addTicketForm', {zIndex:4, height: 'auto', duration: 0});
        gsap.to('#addTicketForm', {opacity: 1, duration: 0.3});
        form.classList.add('on');
      }
      
    }


    function animDropDown(open){
      if (open) {
        var tl = gsap.timeline();
        tl.to("#inputField_" + dropDown + " .dropDownChoices", {height: "auto", duration: 0});
        tl.to("#inputField_" + dropDown, {zIndex: 4, height: "auto", duration: 0}, "<");
        tl.to("#inputField_" + dropDown, {scale: 1, opacity: 1, duration: 0.1});
      } else {
        gsap.to(".dropDownChoices", {height: 0, duration: 0});
        gsap.to(".dropDownField", {opacity: 0, scale: 0, zIndex: -1, duration: 0.1});
      }
    }

    function handleDropDown(e, field) {
      if (field === dropDown) {
        setDropDown(null); 
      } else {
        setDropDown(field)
      }
    }

    function handleNewCategory(objId) {
      setNewCategory(objId)
    }

    function handleNewClient(objId) {
      setNewClient(objId)
    }

    useEffect(() => {
      if (dropDown) {
        animDropDown(true)
      } else {
        animDropDown(false)
      }
    }, [dropDown])

    const getSearchObj = async(pageUrl) => {
    
      await axios({
        method: 'get',
        url: pageUrl
        })
        .then(response => {
          const dataJSON = response.data;
          if (dataJSON) {
            setResults(dataJSON)
          } else {
            setResults(["No results"])
          } 
          
          console.log('results', pageUrl, dataJSON)
        });
    }



    function search(e) {
      let url = base_url + "project-search/"
      let val = e.target.value;
      if (val.length >= 3) {
        let query = "?q=" + val;
        getSearchObj(url + query)
      } else {
        setResults()
      }



    }

    function selectProject(objId, name) {
      setNewProject([objId, name])
    }

    const getTicketSearchObj = async(pageUrl) => {
    
      await axios({
        method: 'get',
        url: pageUrl
        })
        .then(response => {
          const dataJSON = response.data;
          if (dataJSON) {
            updateTickets(dataJSON);
          } else {
            // setTickets(["No results"])
          } 
          setShowLoader(false)
          console.log('results', pageUrl, dataJSON)
        });
    }

    function searchTickets(e) {

      if (e.key === "Enter") {
     
        let url = base_url + "ticket-search/"
        let val = e.target.value;
        if (val.length >= 3) {
          setShowLoader(true)
          let query = "?q=" + val;
          getTicketSearchObj(url + query)
        }  else {
          fetchJSON();
          document.getElementById('ticketSearch').value = null;
        }
      }
      



    }

    function selectTicket(objId, name) {
      // setNewProject([objId, name])
    }

    return (
      <React.Fragment>
      {showLoader && <div className="loader"><Loader /></div>}
      <CSSTransition  nodeRef={nodeRef} in={loaded} unmountOnExit timeout={200} classNames="pageItem">


        <section className="main tickets" ref={nodeRef}>

        <div className="filters ticketFilters projectHeading">
          <span className="column">
            <span className="siteFilters flex">
              <div className="siteFilter btn" onClick={() => filterBy('status', 'In-Progress', 'In Progress')}>
                <span className="icon"><WrenchScrewdriver /></span>
                <span className="statSm">{dev.length}</span>
            </div>
            <div className="siteFilter btn" onClick={() => filterBy('status', 'Returned', 'Returned')}>
              <span className="icon"><Exclamation /></span>
              <span className="statSm">{down.length}</span>
            </div>
            <div className="siteFilter btn" onClick={() => filterBy('status', 'Completed', 'Completed')}>
                <span className="icon"><Checkmark /></span>
                <span className="statSm">{up.length}</span>
            </div>
           
          
          
          <div className="bar"></div>
            <div className="filterField">
            <div className="btn sortBtn" onClick={(e) => handleDropDown(e, 'status')} id="filterHeading_status">Status</div>
            <span className="inputField dropDownField" id="inputField_status">
              <span className="dropDown">
                <span className="dropDownChoices">
                  {statuses && statuses.map((obj, i) => (
                    <span className={"dropDownChoice btn"} key={'statusDD_' + i} onClick={() => filterBy('status', obj.name, obj.name)}>
                 
                      {obj.name}
                    </span>
                  ))}
                  <span className={"dropDownChoice btn"} onClick={() => filterBy('status', null, 'Status')}>All</span>
                </span>
              </span>
            
            </span> 
            </div>
          <div className="bar"></div>
            

            <span className={`siteFilter btn formSearch${ticketResults ? " hasResults" : ""}`}>
                <input id="ticketSearch" type="text" placeholder="Search" onKeyUp={(e) => searchTickets(e) } />
               
                {ticketResults && (ticketResults.length > 0) && <span className="results">
                   {ticketResults.map((obj, i) => (
                    <span key={"result_" + i} className="result ui" onClick={() => selectTicket(obj.id, obj.heading)}>{obj.heading}</span>
                  ))}
                </span>}
                {ticketResults && ticketResults.length === 0 && <span className="results">
                   <h3>No results found</h3>
                </span>}
              </span>
            
            
            {cancel && <div onClick={() => handleCancel()} className="clearBtn btn"><Clear /></div>}
          </span>
      
            
            <span onClick={() => startAdd()}><div className="userBtn btn"><Pencil /></div></span>
     
            </span>
          </div>
         
          <div className="modalForm modalForm2" id="addTicketForm">
            <div className="modal">
            <div className="formBody">
      
                <h2>Create a Request</h2>
                <span className="buttonRow">
                <span className="inputField">
                  <input type="text" name="heading" placeholder="Title" autoComplete="off" id="newHeading" />
                  
                </span>

                <span className="inputField">
   
               {!newProject ? <span className={`formSearch${results ? " hasResults" : ""}`}>
                <input type="text" placeholder="Project" onKeyUp={(e) => search(e) } />
               
                {results && (results.length > 0) && <span className="results">
                   {results.map((obj, i) => (
                    <span key={"result_" + i} className="result ui" onClick={() => selectProject(obj.id, obj.name)}>{obj.name}</span>
                  ))}
                </span>}
                {results && results.length === 0 && <span className="results">
                   <h3>No results found</h3>
                </span>}
              </span> : 
              <span className="inputField ui" onClick={() => {setNewProject(null); setResults(null)}}>
                    {newProject[1]}
              
            </span>
            }

              
              
              </span>

                  <DropDown 
                    onClick={handleNewCategory}
                    objs={categories}
                    active={newCategory}
                    label="Category"
                  />
                  
                  <DropDown 
                    onClick={handleNewClient}
                    objs={clients}
                    active={newClient}
                    label="Client"
                  />

                 <span className={"dateFields"}>
                  <div className="dateField">
              
                    <span className="field btn" onClick={() => setShowDatePicker('start_date')}>
                      <span className="fieldCta">{!newStartTime?"Starts":format(newStartTime, "MMM d, Y, h:mmaaa")}</span>
                    </span>
                    {(showDatePicker === 'start_date') &&  <div className="dateFilterPos"><div className="dateFilter"><DatePicker
                      showTimeSelect
                      onChange={(date) => setNewStartTime(date)}
                      onSelect={(date) => setNewStartTime(date)}
                      selected={newStartTime?newStartTime:null}
                    // startDate={project.start_date}
                    inline
                    /> </div></div>}
                  </div>

                  <div className="dateField">
              
                  <span className="field btn" onClick={() => setShowDatePicker('end_date')}>
                    <span className="fieldCta">{!newEndTime?"Ends":format(newEndTime, "MMM d, Y, HH:mmaaa")}</span>
                  </span>
                  {(showDatePicker === 'end_date') &&  <div className="dateFilterPos"><div className="dateFilter"><DatePicker
                    showTimeSelect
                    onChange={(date) => setNewEndTime(date)}
                    onSelect={(date) => setNewEndTime(date)}
                    selected={!newEndTime?(newStartTime?newStartTime:null):newEndTime}
                  // startDate={project.start_date}
                  inline
                  /> </div></div>}
                </div>
                
                </span>

                  <HtmlText 
                    obj={newDesc}
                    label="Description"
                    onNewObj={setNewDesc}
                  />
                  
                  <span>
                    {newFiles ? <input type="file" accept="video/*|image/*" multiple id="ticketFiles" /> : <span className="fieldCta" onClick={() => {setNewFiles(true); setTimeout(() => {
                      document.getElementById('ticketFiles').click() 
                    }, 50);}}>Add Files</span>}
                  </span>
              
                </span>
               
                </div>
                <span className="buttonRow nextRow">
                <div className="btn" onClick={() => cancelAdd(false)}>Cancel</div>
                <div className="btn save" onClick={() => createTicket()}>Save</div>
              
              </span>
            </div>
          </div>

          <div className="column ticketArray">

          
          
            <div className="itemHeader ticketHeader">
              <span className="item">Status</span>
              <span className="item">Created</span>
              <span className="item">Category</span>
              <span className="item">Heading</span>
              
              <span className="item">Client | Site</span>
              
              <span className="item">Scheduled</span>
              <span className="item">Owner</span>
             
            </div>
            
            <div className={"mainArray ticketsArray arrayLength_" + tickets.length}>
                {tickets.length === 0 && <span className="noResults"><h2>No results found</h2></span>}
                {tickets.map((item, i) => (
                  <Link 
                    className={"siteBtn ticket"} 
                    key={"service_" + i} 
                    to={item.get_absolute_url}
                    >
                      <span className="item">
                        
                         
                        <span className={"statusName " + item.status_slug}>{item.status}</span>  
                      </span>
                      <span className="item">{
                        item.timestamp && 
                        format(new Date(zonedTimeToUtc(item.timestamp, Intl.DateTimeFormat().resolvedOptions().timeZone)), "MMM d, Y, h:mm a")
                      }</span>
                      <span className="item">
                      {item.category_name}
                  </span>
                        <span className="item">{item.heading}</span>
                      

                        <span className="item">
                          {item.client_name && item.client_name}
                          {item.app_name && item.app_name}
                          </span>
                       
                        <span className="item">{
                          item.datetime && 
                          format(new Date(zonedTimeToUtc(item.datetime, Intl.DateTimeFormat().resolvedOptions().timeZone)), "MMM d, Y, h:mm a")
                        }</span>
                        <span className="item">
                            <span className={"teamInitial"}>{item.owner_initials}</span>
                        </span>
                      
                
                
                  </Link>
                ))}
            </div>


          </div>
          
        

          
        </section>
      </CSSTransition>
      </React.Fragment>
    );
}

export default Tickets;
// <span className="blockBottomRow">
// <span className="statusIcon">
// {props.statuses && props.statuses.filter(obj => obj.id === item.status).map((item, i) => (
//   <span key={'status_' + i} className="iconObj">
//     <SVG src={item.icon} className="projectIcon" />
//   </span>
// ))}

// </span>
// <span className="initials">
// {item.team && item.team.map((item, j) => (
//   <span key={'owner_' + j} className={"teamInitial" + ((j === 0)?(" ownerInitial"):"")}>{item.initials}</span>
// ))} 
// </span>

// </span>