import React, { useState, useEffect, Fragment, useRef } from "react";
// import { useDrag, useDrop, DragPreviewImage } from 'react-dnd'
// import { ItemTypes } from './ItemTypes.js'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import update from 'immutability-helper'
import gsap from "gsap";
import axios from "axios";
import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';
import SVG from 'react-inlinesvg';

import SubTask from './app.project_task_sub.js'

import DragLine from "../static/svg/dragLine.svg";
import {ReactComponent as DragLineSVG} from "../static/svg/dragLine.svg";
import {ReactComponent as Cancel} from "../static/svg/cancel_icon.svg";
import {ReactComponent as Checkmark} from "../static/svg/checkmark.circle.fill.svg";
import {ReactComponent as Tag} from "../static/svg/tag.svg";
import {ReactComponent as Star} from "../static/svg/star.fill.svg";
import {ReactComponent as Exclamation} from "../static/svg/exclamationmark.triangle.fill.svg";
import {ReactComponent as Pause} from "../static/svg/pause_circle.svg";

import { IS_LIVE, LOCAL_HOSTS, LIVE_HOSTS } from "../constants.js";
if (IS_LIVE) {
  var base_url = LIVE_HOSTS.base_url + '/api/'
} else {
  var base_url = LOCAL_HOSTS.base_url + '/api/'
}

function Task(props) {
    const target = useRef();
    const ref = useRef(null)
    const refSub = useRef(null)
    const tempTextObj = useRef(null);
    const userTyped = useRef(null);

  const id = props.id;
  const index = props.index;

  function clearDragLine(){
    // console.log('clearDragline')
    gsap.to('.drop', {backgroundColor: "transparent", duration: 0.1})
  }

  function onCommitText(event){
    if (userTyped.current) {
      props.onTaskText(event, props.obj.id, tempTextObj.current)
    } else {
      props.setEditField(null)
    }
    
  }

  function runReorder(reOrder) {
     
    let idList = []
    reOrder.forEach(a => idList.push(a.id))
    let formData = {};
    formData['ids'] = idList;

    try {
      const url = base_url + "task-reorder/";
      axios({
          method: "post",
          url: url,
          data: formData,
          headers: {
            Authorization: 'Token ' + props.token
          },
      }).then((response) => {
       console.log('task-reorder response', response)
        // props.setReload(true);
      });
    } catch (error) {
        console.error(error);
    }
  }

  

  function moveCard(dragIndex, hoverIndex) {
    console.log('moveCard', dragIndex, hoverIndex)

    let reOrder = update(props.tasks, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, props.tasks[dragIndex]],
      ],
    })
    props.setTasks(reOrder);
    runReorder(reOrder)


    // console.log('reOrder', reOrder)
    target.current = null
    clearDragLine()

  }

  // const [{ handlerId, isOver, isOverCurrent }, drop] = useDrop({
  //   accept: ItemTypes.TASK,
  //   drop(_item, monitor) {
  //       const didDrop = monitor.didDrop()
  //       // clearDragLine()
  //       console.log('didDrop', didDrop)
  //       if (!didDrop) {
  //           moveCard(props.index, target.current)
  //           return
  //         }
         
         
  //       // return
  //   },
  //   collect(monitor) {
  //     return {
  //       handlerId: monitor.getHandlerId(),
  //       isOver: monitor.isOver({ shallow: true }),
  //       isOverCurrent: monitor.isOver({ shallow: true })
  //     }

  //   },
  //   hover(item, monitor) {
       
  //     if (!ref.current) {
  //       return
  //     }
  //     const dragIndex = item.index
  //     const hoverIndex = props.index
  //     // Don't replace items with themselves
  //     if (dragIndex === hoverIndex) {
       
  //       return
  //     }
  //     // Determine rectangle on screen
  //     const hoverBoundingRect = ref.current?.getBoundingClientRect()
  //     // Get vertical middle
  //     const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
  //     // Determine mouse position
  //     const clientOffset = monitor.getClientOffset()
  //     // Get pixels to the top
  //     const hoverClientY = clientOffset.y - hoverBoundingRect.top
  //     // Only perform the move when the mouse has crossed half of the items height
  //     // When dragging downwards, only move when the cursor is below 50%
  //     // When dragging upwards, only move when the cursor is above 50%

      

  //     // Dragging downwards
  //     if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
  //       return
  //     }
  //     // Dragging upwards
  //     if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
  //       return
  //     }
   

     
  //   // console.log('monitor', monitor.canDrop())
    
  //   target.current = dragIndex;
  //   clearDragLine()
  //   gsap.to('#drop_' + props.tasks[hoverIndex].id, {backgroundColor: "rgba(200,200,200,0.5)", duration: 0.2}) 
  //   // clearDragLine()
    
    
  //   // moveCard(props.index, target.current)
  //   // gsap.to('#dragLine_' + props.tasks[hoverIndex].id, {opacity: 1, duration: 0}) 
 

  //     // Note: we're mutating the monitor item here!
  //     // Generally it's better to avoid mutations,
  //     // but it's good here for the sake of performance
  //     // to avoid expensive index searches.
  //     item.index = hoverIndex
  //   }
  // });


  // const [{ isDragging }, drag ] = useDrag({
  //   type: ItemTypes.TASK,
  //   item: () => {
  //       return { id, index }
  //     },
  //   collect: (monitor) => ({
  //     isDragging: monitor.isDragging()
  //   })
    
  // });
  // const opacity = isDragging ? 0.4 : 1;

  // drag(drop(ref));



    return ( 
        <div className="drop" id={"drop_" + props.obj.id}>
        {props.obj.status === 8 && <span className="unRead"/>}
        <div 
        id={"task_" + props.obj.id}
        className={"task taskRow" + (((props.editField === "task_name_" + props.obj.id) ||(props.editField === "task_description_" + props.obj.id))  ? " on":"")} 
        onKeyDown={(e) => props.onListTab(e, props.obj, props.tasks[props.index-1])}
        ref={ref}
        onContextMenu={(e) => props.onRightClick(e, props.obj)}
        // onClick={() => clearDragLine()}
        >    
            
            

            <div className="dragHandle"></div>
                    
                <span className="taskRowGrid">
                <span className={"checkbox" + ((props.obj.status === 2) ? " checked":"") + (props.obj.status === 3 || props.obj.status === 9 ?" noBorder":"")} id={"check_" + props.obj.id} 
                onClick={() => props.onCheck("status", props.obj.status, props.obj.id)}
                
                >
                {(props.obj.status !== 3 && props.obj.status !== 9) && <Checkmark className="checkmark" />}
                {props.obj.status === 3 && <Exclamation className="blocked" />}
                {props.obj.status === 9 && <Pause className="blocked" />}
                </span>
             
            
                <span className="inputField taskHeading" onClick={(e) => props.editText(e, "task_name_" + props.obj.id)}>{props.obj.name ? <input type="text" defaultValue={props.obj.name} onKeyUp={(e) => props.handleTaskKeyPress(e, "task_name_" + props.obj.id, null, props.obj.id)} name="name" id={"task_name_" + props.obj.id}/> : <input type="text" placeholder={"Name"} name="task_name" id={"task_name_" + props.obj.id} onKeyUp={(e) => props.handleTaskKeyPress(e, "task_name", null, props.obj.id)}  />} </span>

           <span className="taskDetails">
            <span className="taskId"><p className="small">#{props.tasks.length - props.index}</p></span> 

            {props.obj.owner_obj && (
              <span className="initials"><span className={"teamInitial btn"}>{props.obj.owner_obj.initials}</span></span>
            )} 

            <p className="small">{format(new Date(zonedTimeToUtc(props.obj.start_date, Intl.DateTimeFormat().resolvedOptions().timeZone)), "MMM d, Y")}</p>
            
              
            {!props.obj.description &&  <span className="fieldCta taskFieldCta" onClick={() =>props.setEditField("task_description_" + props.obj.id)}>Add Descripton</span>} 
            
              
            
              <span className="labels">
            
                {props.obj.labels && (props.obj.labels.length > 0) ? props.obj.labels.map((item, j) => (
                  <span key={'label_' + j} onClick={() => props.handleLabelForm('task', props.obj.labels, props.obj.id)} className={"label btn"} style={{backgroundColor: (props.dark ? item.color_dark : item.color)}}>
                  {item.name}
                  {item.icon && <SVG key={'label_' + j} src={item.icon} className="labelIcon" />}
                
                  
                  </span>
                )) : <span className="fieldCta" onClick={() =>  props.handleLabelForm('task', props.obj.labels, props.obj.id)}><Tag className="icon" /></span> } 
              
                </span>
               
            </span>

            
                
            <span className="taskDescription taskDesc">
                {(props.editField === "task_description_" + props.obj.id) ? 
                <div>
                <span className="text">

                    <CKEditor
                    editor={ ClassicEditor }
                    id={"task_description_" + props.obj.id} 
                    data={props.obj.description}
                    config={{
                    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
                    link: { addTargetToExternalLinks: true }
                    }}
                    onReady={() => {
                    tempTextObj.current = null;
                    userTyped.current = false;
                    }}
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        // console.log( { event, editor, data } );
                        tempTextObj.current = data;
                        userTyped.current = true;
                    } }
                    /> 

                    <span className="smCancel btn textarea">
                    <Checkmark onClick={(event) => onCommitText(event)} />
                    <Cancel onClick={() =>props.setEditField(null)} />
                    </span>
                </span>
                </div> :
                (props.obj.description && <div className={"addText text" + (props.obj.description?"":" empty")} onClick={() =>props.setEditField("task_description_" + props.obj.id)} dangerouslySetInnerHTML={ {__html: props.obj.description} } />)
                }
                </span>
                </span>
                {props.obj.subs && <ul>
                {props.obj.subs.map((sub, h) => (
                  
                    <li key={'subTask_' + h} id={"taskRow_sub_" + sub.id}>

                    <SubTask 
                    index={h}
                    parent_index={props.index}
                    id={sub.id}
                    sub={sub} 
                    subs={props.obj.subs}
                    tasks={props.tasks}
                    setTasks={props.setTasks}
                    editText={props.editText}
                    editField={props.editField} 
                    setEditField={props.setEditField}
                    onListTab={props.onListTab}
                    onCheck={props.onCheck}
                    handleTaskKeyPress={props.handleTaskKeyPress}
                    onTaskText={props.onTaskText}
                    onRightClick={props.onModuleContext}
                    showLabelForm={props.showLabelForm}
                    handleLabelForm={props.handleLabelForm}
                    token={props.token}
                    teamUsers={props.teamUsers}
                    />

                </li>))}
            </ul>}
            </div>
            
            <div className={"dragLine"} id={"dragLine_" + props.obj.id}></div>
            </div>   
    );
}

export default Task;

// LABEL SMALL ICONS
// <span className="fieldValue taskHeading" onClick={(e) => props.editText(e, "task_name_" + props.obj.id)}><h4>{props.obj.name}</h4>  {props.obj.labels && props.obj.labels.map((item, j) => (
//   <span key={'label_' + j} onClick={() => props.handleLabelForm('task', props.obj.labels, props.obj.id)} className={"label btn"} style={{backgroundColor: (props.dark ? item.color_dark : item.color)}}>

//   {item.icon && <SVG key={'label_' + j} src={item.icon} className="labelIcon" />}

  
//   </span>
// ))} </span>