// import path from 'path'
// import * as url from 'url'

const isDevelopment = false;
const productionStatic = "static/"
export function getStatic(val) {
  if (isDevelopment) {
    return 'http://localhost:8000' + val
  } else {
    return val
  }
  // return path.resolve(productionStatic, val)
}